import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Medico } from '../../../moddelo/medico';
import { ActivatedRoute } from '@angular/router';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/servicios/auth.service';
@Component({
  selector: 'app-administrador-medico-perfil-puntos',
  templateUrl: './administrador-medico-perfil-puntos.component.html',
  styleUrls: ['./administrador-medico-perfil-puntos.component.css']
})
export class AdministradorMedicoPerfilPuntosComponent implements OnInit {
  public medico: Medico = null;
  public puntos: PuntosPorProducto[] = [];
  public medicoFrm: FormGroup = new FormGroup({
                                              id: new FormControl(''),
                                              nombre: new FormControl(''),
                                              primerApellido: new FormControl(''),
                                              fk_puntos: new FormControl(''),
                                            });
  get idCtrl(){
    return this.medicoFrm.get("id");    
  }
  get nombreCtrl(){
    return this.medicoFrm.get("nombre");
  }
  get primerApellidoCtrl(){
    return this.medicoFrm.get("primerApellido");
  }
  get fk_puntosCtrl(){
    return this.medicoFrm.get("fk_puntos");
  }

  get usuario(){
    return this.aut.getUsuario;
  }
  get offSelect() {
    return (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
  }
  get offBoton(){
    return  this.medicoFrm.valid && (this.usuario.fk_nivel==0 || this.usuario.fk_nivel==3);
  }
  constructor(
              private location: Location, 
              private activeRouter: ActivatedRoute,
              private adminSrv: AdministradorService,
              private catalogoSrv: CatalogosService,
              private snackBar: MatSnackBar,
              private aut: AuthService) { }

  ngOnInit() {
    let id = this.activeRouter.snapshot.paramMap.get("id");
    this.medico = this.adminSrv.medicos.find(medico => medico.id == Number(id));

    this.idCtrl.setValue(this.medico.id);
    this.nombreCtrl.setValue(this.medico.nombre);
    this.primerApellidoCtrl.setValue(this.medico.primerApellido);
    this.fk_puntosCtrl.setValue(this.medico.fk_puntos);
    this.fk_puntosCtrl.setValidators(Validators.required);

    this.catalogoSrv.getPuntosPorProductos().subscribe(data => {this.puntos = data;}, err => { console.log(err)});

  }
  onSubmit(): void{
          let pp: PuntosPorProducto = this.puntos.find(p => p.id == this.fk_puntosCtrl.value);
          let m: Medico = new Medico();

          m.id=this.idCtrl.value;
          m.fk_puntos = pp.id;
          m.puntos = pp.puntos;

          this.adminSrv
          .setMedicoUpdate(m.id, m)
          .subscribe(data => {
                              if(data){
                                this.medico.fk_puntos = data.fk_puntos;
                                this.medico.puntos = data.puntos;
                                this.snackBar
                                      .open(
                                       'Se actualizo con exito', 
                                        'Medico', 
                                        {
                                          duration: 3000,
                                          verticalPosition:'bottom',
                                          horizontalPosition:'center'
                                        });
                              }
                            }, err => { console.log(err); });
  }
  goBack(): void {
    this.location.back();
  }

}
