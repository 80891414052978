import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Puesto } from 'src/app/moddelo/puesto';
import { Residencia } from 'src/app/moddelo/residencia';
import { Hospital } from '../moddelo/hospital';
import {Banco} from '../moddelo/banco';
import { Institucion } from 'src/app/moddelo/institucion';
import { Diagnostico } from 'src/app/moddelo/diagnostico';
import {Folio} from '../moddelo/folio';
import { Estado } from 'src/app/moddelo/estado';
import {OtroHospital} from '../moddelo/otro-hospital';
import {OtraInstitucion} from '../moddelo/otra-institucion';
import { OtroDiagnostico } from 'src/app/moddelo/otro-diagnostico';
import {OtraEspecialidad} from '../moddelo/otra-especialidad';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { TipoDocumento } from 'src/app/moddelo/tipo-documento';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  constructor(private http: HttpClient) { }

  getInstituciones(){
    return this.http.get<Institucion[]>('/apibioc/public/api/instituciones', httpOptions);
  } 
  getHospitales(){
    return this.http.get<Hospital[]>('/apibioc/public/api/hospitales', httpOptions);
  }
  getDiagnosticos(){
    return this.http.get<Diagnostico[]>('/apibioc/public/api/diagnosticos', httpOptions);
  }
  getEspecialidades(){
    return this.http.get<Especialidad[]>('/apibioc/public/api/especialidades', httpOptions);
  }
  getAntibioticos(){
    return this.http.get<Antibiotico[]>(`/apibioc/public/api/antibioticos`, httpOptions);
  }
  getPuntosPorProductos(){
    return this.http.get<PuntosPorProducto[]>('/apibioc/public/api/puntosporproductos', httpOptions);
  } 


  getEstados(){
    return this.http.get<Estado[]>(`/apibioc/public/api/estados`,httpOptions);
  }
  getPuestos(){
    return this.http.get<Puesto[]>('/apibioc/public/api/puestos', httpOptions);
  }
  getResidencias(){
    return this.http.get<Residencia[]>('/apibioc/public/api/residencias', httpOptions);
  } 
  getTiposDocumentos(){
    return this.http.get<TipoDocumento[]>('/apibioc/public/api/tiposdocumentos', httpOptions);
  }
  getDocumentosId(){
    return this.http.get<DocumentoIdentificacion[]>('/apibioc/public/api/documentosidentificacion', httpOptions);
  }


  setInstitucion(institucion: Institucion){
    return this.http.post<Institucion>('/apibioc/public/api/institucion', institucion);
  }
  getInstitucion(id: number){
    return this.http.get<Institucion>(`/apibioc/public/api/institucion/${id}`);
  }
  setInstitucionUpdate(id: number, institucion: Institucion){
    return this.http.put<Institucion>(`/apibioc/public/api/institucion/${id}`, institucion);
  }
  setInstitucionDelete(id: number, institucion: Institucion){
    return this.http.delete<Institucion>(`/apibioc/public/api/institucion/${id}`);
  }
  
  getInstitucionesOtros(){
    return this.http.get<CasoClinico[]>(`/apibioc/public/api/instituciones/otros`, httpOptions);
  }
  setInstitucionOtro(institucion: Institucion, casoclinico: CasoClinico){
    let dato = {institucion, casoclinico};
    return this.http.post<Institucion>('/apibioc/public/api/institucion/otro', dato);
  }

  
  setHospital(hospital: Hospital){
    return this.http.post<Hospital>('/apibioc/public/api/hospital', hospital);
  }
  getHospital(id: number){
    return this.http.get<Hospital>(`/apibioc/public/api/hospital/${id}`, httpOptions);
  }
  setHospitalUpdate(id: number, hospital: Hospital){
    return this.http.put<Hospital>(`/apibioc/public/api/hospital/${id}`, hospital);
  }
  setHospitalDelete(id: number){
    return this.http.delete<Hospital>(`/apibioc/public/api/hospital/${id}`);
  }

  getHospitalesOtros(){
    return this.http.get<CasoClinico[]>('/apibioc/public/api/hospitales/otros', httpOptions);
  }
  setHospitalOtro(hospital: Hospital, casoclinico: CasoClinico){
    let dato = { hospital, casoclinico };
    return this.http.post<Hospital>('/apibioc/public/api/hospital/otro', dato);
  }


  setDiagnostico(diagnostico: Diagnostico){
    return this.http.post<Diagnostico>('/apibioc/public/api/diagnostico', diagnostico);
  }
  getDiagnostico(id: number){
    return this.http.get<Diagnostico>(`/apibioc/public/api/diagnostico/${id}`, httpOptions);
  }
  setDiagnosticoUpdate(id: number, diagnostico: Diagnostico){
    return this.http.put<Diagnostico>(`/apibioc/public/api/diagnostico/${id}`, diagnostico);
  }
  setDiagnosticoDelete(id: number){
    return this.http.delete<Diagnostico>(`/apibioc/public/api/diagnostico/${id}`);
  }


  setEspecialidad(especialidad: Especialidad){
    return this.http.post<Especialidad>('/apibioc/public/api/especialidad', especialidad);
  }
  getEspecialidad(id: number){
    return this.http.get<Especialidad>(`/apibioc/public/api/especialidad/${id}`, httpOptions);
  }
  setEspecialidadUpdate(id: number, especialidad: Especialidad){
    return this.http.put<Especialidad>(`/apibioc/public/api/especialidad/${id}`, especialidad);
  }
  setEspecialidadDelete(id: number){
    return this.http.delete<Especialidad>(`/apibioc/public/api/especialidad/${id}`);
  }


  setAntibiotico(antibiotico: Antibiotico){
    return this.http.post<Antibiotico>('/apibioc/public/api/antibiotico', antibiotico)
  }
  getAntibiotico(id: number){
    return this.http.get<Antibiotico>(`/apibioc/public/api/antibiotico/${id}`, httpOptions);
  }
  setAntibioticoUpdate(id: number, antibiotico: Antibiotico){
    return this.http.put<Antibiotico>(`/apibioc/public/api/antibiotico/${id}`, antibiotico);
  }
  setAntibioticoDelete(id: number){
    return this.http.delete<Antibiotico>(`/apibioc/public/api/antibiotico/${id}`);
  }


  setPuntosPorProducto(puntosporProducto: PuntosPorProducto){
    return this.http.post<PuntosPorProducto>('/apibioc/public/api/puntosporproducto', puntosporProducto);
  }
  getPuntosPorProducto(id: number){
    return this.http.get<PuntosPorProducto>(`/apibioc/public/api/puntosporproducto/${id}`, httpOptions);
  }
  setPuntosPorProductoUpdate(id: number, puntosporProducto: PuntosPorProducto){
    return this.http.put<PuntosPorProducto>(`/apibioc/public/api/puntosporproducto/${id}`, puntosporProducto);
  }
  getPuntosPorProductoDelete(id: number){
    return this.http.delete<PuntosPorProducto[]>(`/apibioc/public/api/puntosporproducto/${id}`, httpOptions);
  }


  getOtroDiagnosticos(){
    return this.http.post<OtroDiagnostico[]>('/apibioc/public/api/otros/diagnosticos', httpOptions);
  }

  getDocumentosIdentificacion(){
    return this.http.get<DocumentoIdentificacion[]>('/apibioc/public/api/documentosidentificacion', httpOptions);
  }

}
