import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera'; 
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';

@Component({
  selector: 'app-administrador-enfermera-r-canje-add',
  templateUrl: './administrador-enfermera-r-canje-add.component.html',
  styleUrls: ['./administrador-enfermera-r-canje-add.component.css']
})
export class AdministradorEnfermeraRCanjeAddComponent implements OnInit {
  
  @Input('enfermera') public enfermera: Enfermera;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  private redimirFrm: FormGroup = new FormGroup({
    redimir: new FormControl(0),
    fechaInicio: new FormControl('', Validators.required), 
  });

  get fechaCtrl() { return this.redimirFrm.get('fechaInicio'); }
  get redimirCtrl() { return this.redimirFrm.get('redimir'); }

  get saldo() {
    let canjesAplicados = this.enfermera.redimirenfermera.filter(canje => canje.status == 3 || canje.status == 1 ).reduce((valor, canje) => canje.redimir + valor, 0);
    let puntos = this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0);
    return puntos - canjesAplicados;
  }
 
  constructor(
            private auth: AuthService,
            private adminSrv: AdministradorService, 
            private enfermerasSrv: EnfermerasService, 
            private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.validaciones();
  }
  onSubmit(frm: NgForm) {
    let { redimir, fechaInicio } = this.redimirFrm.value;
    let canje: RedimirEnfermera = <RedimirEnfermera> { redimir, fechaInicio };
    canje.status = 1;
    canje.fk_enfermera = this.enfermera.id;

    this.enfermerasSrv
    .setRedimirCreate(canje)
    .subscribe(data => 
    { 
          this.enfermera.redimirenfermera.push(data); 

          frm.reset();

          this.redimirCtrl.setValue(0);
          this.validaciones();

          this.snackBar.open(
                              "Solicitud registrada",
                              "Canje", 
                              {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                              });

    }, err => { console.log( err ); }  );

}
  validaciones(): void {
    this.redimirFrm.clearValidators();
    
    if(this.saldo > 0 ) //Validar proceso de saldo evitar que tenga numeros negativos
    {
      this.redimirCtrl.setValidators([Validators.min(100),Validators.required, Validators.max(this.saldo)]  );      
    }
      this.fechaCtrl.setValidators(Validators.required)
  }

  imprimir() {

    if(this.redimirCtrl.value <= this.saldo)
      console.log("soliitud",this.redimirCtrl.value ,"proceso aproblanble","saldo", this.saldo);
    else 
      console.log("soliitud", this.redimirCtrl.value, "proceso NO APROBABLE","saldo", this.saldo); 

  }

  cancelar(): void {
    this.cancelarCanje.emit();
}


}
