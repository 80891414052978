import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { EnfermerasRoutingModule } from './enfermeras-routing.module';
import { EnfermerasMainComponent } from './enfermeras-main/enfermeras-main.component';
import { EnfermeraCasosClinicosComponent } from './enfermera-casos-clinicos/enfermera-casos-clinicos.component';
import { EnfermeraProductosComponent } from './enfermera-productos/enfermera-productos.component';
import { EnfermeraPuntosComponent } from './enfermera-puntos/enfermera-puntos.component';
import { EnfermeraSaldoComponent } from './enfermera-saldo/enfermera-saldo.component';
import { EnfermeraCanjeComponent } from './enfermera-canje/enfermera-canje.component';
import { EnfermeraCanjeAddComponent } from './enfermera-canje-add/enfermera-canje-add.component';
import { EnfermeraMedicosComponent } from './enfermera-medicos/enfermera-medicos.component';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';
import { EnfermeraCanjeDetalleComponent } from './enfermera-canje-detalle/enfermera-canje-detalle.component';


@NgModule({
  declarations: [EnfermerasMainComponent, EnfermeraCasosClinicosComponent, EnfermeraProductosComponent, EnfermeraPuntosComponent, EnfermeraSaldoComponent, EnfermeraCanjeComponent, EnfermeraCanjeAddComponent, EnfermeraMedicosComponent, EnfermeraCanjeDetalleComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EnfermerasRoutingModule,
    MaterialModule,
    PaginacionModule
  ]
})
export class EnfermerasModule { }
