import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators'; 

import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Enfermera } from "src/app/moddelo/enfermera";
import { Medico } from "src/app/moddelo/medico";

interface AddMedico{
    code: number;
    message: string;
    data: Medico;
}

@Component({
    selector: 'dialogo-enfermera-medico-add',
    templateUrl: 'dialogo-enfermera-medico-add.html'
})
export class DialogoEnfermeraMedicoAdd {
    
    public medicos: Medico[] = [];
    public respuesta: AddMedico;
    private filteredMedicos: Observable<Medico[]>;
    public medicoFrm = new FormGroup({    
                                    medico: new FormControl('')   
                                  });
    get usuario(){
        return this.auth.getUsuario;
    }
    get offBoton(){
        return this.medicoFrm.valid && (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);  
    }    
    constructor(
        public dialogo: MatDialogRef<DialogoEnfermeraMedicoAdd>,
        @Inject(MAT_DIALOG_DATA) public data: Enfermera,
        private auth: AuthService, 
        private adminSrv: AdministradorService, 
        private enfermerasSrv: EnfermerasService,
        private medicosSrv: MedicosService,
        private snackBar: MatSnackBar
        ){
            this.filteredMedicos = this.medicoFrm.controls["medico"].valueChanges.pipe(startWith(''), map(value => this._filterMedico(value)));
   
            this.medicosSrv.getMedicos().subscribe(data => { this.medicos = data; }, err => { console.log(err); });
        }

    
    onSubmit(event: any){
        let medico: Medico = <Medico>this.medicoFrm.value.medico;  
         this.enfermerasSrv
         .setAsociarMedico(this.data.id, medico)
         .subscribe(data => {
                                this.respuesta = <AddMedico> data;

                                this.snackBar.open(this.respuesta.message, 'Medico', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                                if(this.respuesta.code == 1){ 
                                    this.data.medicos.push(medico);
                                    this.onClose();
                                }
         }, err => { 
                    if(err.status == 500){
                        let msj = '';
                        
                        if(err.error.code=="23000"){
                            msj='El medico ya esta asociado';
                        } else {
                            msj= err.error.message;
                        }

                        this.snackBar.open(msj , 'Medico', 
                        {
                            duration: 3000,
                            verticalPosition:'bottom',
                            horizontalPosition:'center'
                        });

                        
                      
                    }
                    console.log(err); 
          } );
       }
    
    _normalizeValue(nombre: string): string {
        let cadena = String(nombre).toLowerCase();
        return cadena ? cadena.replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : '';
    }
    _filterMedico(nombre: string): Medico[] {
        const valorFiltro = this._normalizeValue(nombre);
        return this.medicos.filter(medico => this._normalizeValue(medico.nombre).includes(valorFiltro) ||  this._normalizeValue(medico.primerApellido).includes(valorFiltro) || this._normalizeValue(medico.segundoApellido).includes(valorFiltro));
    }
    displayFnMedico(medico: Medico ): string {
        return  medico  ? medico.nombre.concat(' ').concat(medico.primerApellido).concat(' ').concat(medico.segundoApellido) : '';
    }
    onClose(): void {
        this.dialogo.close();
    }      
}