import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { DiagnosticosRoutingModule } from './diagnosticos-routing.module';
import { DiagnosticosComponent } from './diagnosticos/diagnosticos.component';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';


@NgModule({
  declarations: [DiagnosticosComponent, DiagnosticoComponent],
  imports: [
    CommonModule,
    DiagnosticosRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class DiagnosticosModule { }
