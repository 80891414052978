import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import moment, { MomentInput } from 'moment';

@Component({
  selector: 'app-casos-clinicos-ficha-vista',
  templateUrl: './casos-clinicos-ficha-vista.component.html',
  styleUrls: ['./casos-clinicos-ficha-vista.component.css']
})
export class CasosClinicosFichaVistaComponent implements OnInit {
  public valoracion: String[] = ["", "Malo", "Regular", "Bueno", "Excelente"];
  constructor(
              private location: Location, 
              private casosclinicosSrv: CasosClinicosService,
              private activateRoute: ActivatedRoute) { 

                let id = this.activateRoute.snapshot.paramMap.get("id");

                casosclinicosSrv
                .getCasoClinico(id)
                .subscribe(data => { this.casosclinicosSrv.casoClinico = data; }, err => { console.log(err); });

              }

  get casoClinico(){
    return  this.casosclinicosSrv.casoClinico;
  } 

  get seguimientos(){
    return  this.casosclinicosSrv.casoClinico.seguimientos;
  }
  get productos(){
    return  this.casosclinicosSrv.casoClinico.productos;
  }

  get fecha(){
    return  moment(this.casosclinicosSrv.casoClinico.fecha).format("L");
  }
  get fechaAplicacion(){
    let fechaApp = "";
    if(this.casosclinicosSrv.casoClinico.fechaAplicacionProducto)
        fechaApp = moment(this.casosclinicosSrv.casoClinico.fechaAplicacionProducto).format("L");

    return  fechaApp;
  }
  get sexo(){
    let sx = "";
    if(this.casosclinicosSrv.casoClinico.sexo == 'H')
       sx = "Hombre";
    if(this.casosclinicosSrv.casoClinico.sexo == 'M')
       sx = "Mujer";
    return sx;
  }           
  ngOnInit() {
    moment.locale('es');
    
  }
  valorar(i: number){
    return this.valoracion[i];
  }
  goBack(): void {
    this.location.back();
  }
  

}
