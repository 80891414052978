import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { Producto } from 'src/app/moddelo/producto';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
 
interface ActualizarPuntosEnfermera{
  code: number;
  message: string;
  data: Producto;
}

@Component({
  selector: 'app-administrador-enfermera-r-punto-update',
  templateUrl: './administrador-enfermera-r-punto-update.component.html',
  styleUrls: ['./administrador-enfermera-r-punto-update.component.css']
})
export class AdministradorEnfermeraRPuntoUpdateComponent implements OnInit {
  public enfermera: Enfermera;
  public producto: Producto;
  public respuesta: ActualizarPuntosEnfermera;

  public puntosProducto: PuntosPorProducto[] = [];  
  public productoFrm: FormGroup = new FormGroup({
                                                codigo: new FormControl(''),
                                                nomProducto: new FormControl(''),
                                                e_puntos: new FormControl('')
                                              });
  get codigoCtrl(){
    return this.productoFrm.get('codigo');
  }
  get nomProductoCtrl(){
    return this.productoFrm.get('nomProducto');
  }                                            
  get e_puntosCtrl(){
    return this.productoFrm.get('e_puntos');
  }
  get usuario(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return this.productoFrm.valid && (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
  } 

  constructor(private adminSrv: AdministradorService, 
              private activateRoute: ActivatedRoute, 
              private location: Location,
              private catalogoSrv: CatalogosService,
              private snackBar: MatSnackBar,
              private auth: AuthService) { }

  ngOnInit() {
    let id_enfermera = this.activateRoute.snapshot.paramMap.get('id1');
    let id_producto = this.activateRoute.snapshot.paramMap.get('id2');

    this.enfermera = this.adminSrv.enfermeras.find( enfermera => enfermera.id == Number(id_enfermera));
    this.producto = this.enfermera.productos.find(producto => producto.id == Number(id_producto));
    
    this.codigoCtrl.setValue(this.producto.codigo);
    this.nomProductoCtrl.setValue(this.producto.nomProducto);
    this.e_puntosCtrl.setValue(this.producto.e_puntos);
  
    this.catalogoSrv.getPuntosPorProductos().subscribe(data => this.puntosProducto = data, err => console.log(err));
  }
  onSubmit(){
   
    let id_producto = this.producto.id;
    let puntos = this.e_puntosCtrl.value;

    this.adminSrv
    .setActualizarPuntosEnfermera(id_producto, puntos)
    .subscribe( data => { 
                                this.respuesta = <ActualizarPuntosEnfermera> data;
                                if(this.respuesta.code == 1){
                                  this.producto.e_puntos = this.e_puntosCtrl.value;
                                  this.snackBar
                                  .open(
                                    this.respuesta.message, 
                                    'Enfermera', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                                }
                                else {
                                      this.snackBar
                                      .open(
                                        this.respuesta.message, 
                                        'Enfermera', 
                                        {
                                          duration: 3000,
                                          verticalPosition:'bottom',
                                          horizontalPosition:'center'
                                        });
                                  }
                               
                        }, err => { console.log(err); }); 
    
  }
  goBack(): void {
    this.location.back();
  }

}
