import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puntos-seguimiento',
  templateUrl: './puntos-seguimiento.component.html',
  styleUrls: ['./puntos-seguimiento.component.css']
})
export class PuntosSeguimientoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
