import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Institucion } from 'src/app/moddelo/institucion';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Hospital } from 'src/app/moddelo/hospital';
import { Estado } from 'src/app/moddelo/estado';

@Component({
  selector: 'app-institucion-hospitales',
  templateUrl: './institucion-hospitales.component.html',
  styleUrls: ['./institucion-hospitales.component.css']
})
export class InstitucionHospitalesComponent implements OnInit {
  public pageEvent: PageEvent; 
  public id: number = 0;
  public institucion: Institucion;
  public nomInstitucion: String = "";
  public hospitales: Hospital[] = [];
  public estados: Estado[] = [];

  public filtroFrm: FormGroup = new FormGroup({
    hospital: new FormControl('')
  });

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }

  constructor(  
    private location: Location, 
    private activatedR: ActivatedRoute, 
    private catalogosSrv: CatalogosService, 
    private adminSrv: AdministradorService) { }

  filterHospital(hospital: string): boolean{
      let condicion: boolean = true;
      let h: string="";
      if(this.hospitalCtrl.value != ""){
        h = this.hospitalCtrl.value;
       condicion = hospital.trim().toUpperCase().includes(h.trim().toUpperCase());
      }    
    return condicion;
  }  
  
  ngOnInit() {
    this.id = Number(this.activatedR.snapshot.paramMap.get('id'));
    this.catalogosSrv.getEstados().subscribe(data => {this.estados = data;}, err => {console.log(err)});

    if(this.id > 0){

      this.institucion = this.adminSrv.instituciones.find( i => i.id == this.id);
      this.hospitales = this.institucion.hospitales;
      this.nomInstitucion  = this.institucion.nomInstitucion;   
 
      
     }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;   
    this.hospitales = this.adminSrv.hospitales.filter(txthospital => this.filterCompuesto(txthospital, filterValue));
     
  }
  filterCompuesto(hospital: Hospital, filtro: string): boolean{
    let condicion: boolean = true;
    
    let txtHospital: string = "";
    let nombreHospital: string = "";
    let abrInstitucion: string = "";    
    let nombreEstado: string = "";

      if(filtro != "") {

        txtHospital = filtro.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
       
        nombreHospital = hospital.nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
        nombreEstado =  hospital.estado ? hospital.estado.nomEstado.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : "";

        condicion = nombreHospital.concat(nombreEstado).replace(/ +/g,'').includes(txtHospital.replace(/ +/g,'')); 
           
      }
    
    return condicion;
  }
  onSubmit()
  {
    this.hospitales = this.adminSrv.hospitales.filter(h => this.filterHospital(h.nomHospital)); 
  }

  goBack(): void{
    this.location.back();
  }

  pagina(x: number, pageEvent: PageEvent): boolean{
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }

  estado(id: number): String{
    let nombre: String = "";
      if(this.estados.length > 0)
      {
        nombre =  this.estados.find(ed => ed.id ==id).nomEstado;
      }
    return nombre;
  }

}
