import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
const ADMINISTRADOR = 2;
const MEDICO = 3;
const ENFERMERA = 5;
@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.css']
})
export class UsuarioPerfilComponent implements OnInit {
  public documentosId: DocumentoIdentificacion[] = [];

  get usuario(){
    return this.auth.getUsuario;
  }  
  get nombre(){
    return this.auth.getUsuario.nombre.concat(' ').concat(this.auth.getUsuario.primerApellido).concat(' ').concat(this.auth.getUsuario.segundoApellido);
  }
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  
  constructor(private auth: AuthService, 
              private router: Router,
              private catalogosSrv: CatalogosService,
              private location: Location) { }

  ngOnInit() {
        this.catalogosSrv.getDocumentosIdentificacion().subscribe(data => { this.documentosId = data;}, err => {console.log(err)});
  }

  DocIdentificacion(id: number): string{
    let msj: string = "";
    if(id && this.documentosId.length>0){
      msj = this.documentosId.find(doc => doc.id == id).nomDocumentoId;
    }
    return msj;
  }
  goBack(){
    this.location.back();
  }

}
