import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { Location } from '@angular/common';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';



interface ItbCasosClinicos{
  No: string;
  Fecha: string;
	Folio: string;
	Paciente: string;
	Diagnostico: string;
	Hospital: string;
  Institucion: string;
	Estado: string;
	Medico: string;
  Enfermera: string;
  Productos: number;
}
@Component({
  selector: 'app-administrador-casos-clinicos-informes',
  templateUrl: './administrador-casos-clinicos-informes.component.html',
  styleUrls: ['./administrador-casos-clinicos-informes.component.css']
})
export class AdministradorCasosClinicosInformesComponent implements OnInit {
  
  public consultaFrm: FormGroup;
  public casosclinicos: CasoClinico [] = [];
  public casosclinicosFilter: CasoClinico [] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(
    private formBuilder: FormBuilder, 
    private location: Location, 
    private casosClinicosSrv: CasosClinicosService ) { }
 

  get fechaInicialCtrl(){
    return this.consultaFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.consultaFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.consultaFrm.get('serie');
  }

  get pacienteCtrl(){
    return this.consultaFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.consultaFrm.get('hospital');
  }

  get institucionCtrl(){
    return this.consultaFrm.get('institucion');
  }
  get estadoCtrl(){
    return this.consultaFrm.get('estado');
  }
  get enfermeraCtrl() {
    return this.consultaFrm.get('enfermera');
  }

  get medicoCtrl(){
    return this.consultaFrm.get('medico');
  }
  get diagnosticoCtrl(){
    return this.consultaFrm.get('diagnostico');
  }

  ngOnInit() {
     
    this.casosClinicosSrv.getCasosClinicos().subscribe((data: CasoClinico[]) => {
                                                                                  this.casosclinicos = data.sort((a, b) => {
                                                                                    let fechaA: number = new Date(a.fecha).getTime();
                                                                                    let fechaB: number = new Date(b.fecha).getTime();
                                                                                    return fechaB - fechaA;
                                                                                  });
                                                                                  this.casosclinicosFilter = this.casosclinicos;
                                                                                }, 
                                                                                err => { console.log(err); });

    this.consultaFrm = this.formBuilder.group({
                                                fechaInicial:[''],
                                                fechaFinal:[''],
                                                serie:[''],
                                                paciente:[''],
                                                hospital: [''],
                                                medico: [''],
                                                enfermera:[''],                                               
                                                institucion:[''],
                                                diagnostico:[''],
                                                estado: ['']
                                              });
  }
  onSubmit(): void {
    /*
    console.log(this.fechaInicialCtrl.value, " ", this.fechaFinalCtrl.value)
    console.log(new Date(this.fechaInicialCtrl.value).getTime(), " ", new Date(this.fechaFinalCtrl.value).getTime())

    console.log(this.casosclinicos[0].fechaAplicacionProducto + " 00:00:00", " ",this.casosclinicos[0].fecha + " 00:00:00")
    console.log(new Date(this.casosclinicos[0].fechaAplicacionProducto  + " 00:00:00"), " ", new Date(this.casosclinicos[0].fecha + " 00:00:00"))
    console.log(new Date(this.casosclinicos[0].fechaAplicacionProducto + " 00:00:00").getTime(), " ", new Date(this.casosclinicos[0].fecha + " 00:00:00").getTime())
    */
    this.casosclinicosFilter =  this.casosclinicos.filter(cc => 
                                this.filterPaciente(cc.paciente) &&
                                this.filterHospital(cc.hospital) &&
                                this.filterDiagnostico(cc.diagnostico) &&
                                this.filterMedico(cc) &&
                                this.filterEnfermera(cc) &&
                                this.filterEstado(cc) &&
                                this.filterFolio(cc.serie) &&
                                this.filterFecha(cc.fecha.toString())
                                );
     this.page_number = 1;                         
  }
  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
  filterMedico(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: string = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(casoclinico.fk_medico){
          nomMedico = casoclinico.medico ? (casoclinico.medico.nombre + ( casoclinico.medico.primerApellido ? ' ' + casoclinico.medico.primerApellido : '' ) + (casoclinico.medico.segundoApellido ? ' ' + casoclinico.medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
          console.log(" nomEnf:", this._normalizeValue(nomMedico), "Nom:", nom, " Bolean:", this._normalizeValue(nomMedico).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterEnfermera(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: string = ""; 
    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(casoclinico.fk_medico){
          nomEnfermera = casoclinico.enfermera ? (casoclinico.enfermera.nombre + ( casoclinico.enfermera.primerApellido ? ' ' + casoclinico.enfermera.primerApellido : '' ) + (casoclinico.enfermera.segundoApellido ? ' ' + casoclinico.enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterDiagnostico(nomDiagnostico: string): boolean{
    let condicion: boolean = true;
    let diagnostico: string;
    let d: string;
    let nombre: string;

    if( this.diagnosticoCtrl.value ){
      d = this.diagnosticoCtrl.value;
      diagnostico = d.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(nomDiagnostico){
        nombre = nomDiagnostico.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        condicion = nombre.replace(/ +/g,'').includes(diagnostico.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterEstado(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEstado: string = ""; 
    if(this.estadoCtrl.value != ""){
        nom = this._normalizeValue(this.estadoCtrl.value);
        if(casoclinico.fk_estado){
          nomEstado = casoclinico.estado ? casoclinico.estado.nomEstado : '';
          condicion = ( this._normalizeValue(nomEstado).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
         
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');        
      if(cveInstitucion){
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  /*
  _filter(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresEnfermeras.filter(enfermera => this._normalizeValue(enfermera).includes(valorFiltro));

  }
  */
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  //funcion sin definir
  refresh(){

    let envio = this.consultaFrm.value;

    console.log('FORMULARIO: ', envio);
    this.casosClinicosSrv.getCasosClinicosQuery(envio).subscribe((data: CasoClinico[]) => { 
      this.casosclinicos = data;
      
      console.log('REQUEST => ', data); 
    }, err => { console.log(err); });
  }
  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  sortCasosClinicos(sort: Sort): void {
    const datos: CasoClinico[] = this.casosclinicosFilter.slice();
    if(!sort.active || sort.direction === ''){
      this.casosclinicosFilter = datos;
      return;
    }
    this.casosclinicosFilter =  datos.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'fecha':
        let a1: number = new Date(a.fecha).getTime();
        let b1: number = new Date(b.fecha).getTime();
        return this.compare(a1, b1, isAsc);
      case 'serie':
        return this.compare(a.serie, b.serie, isAsc);
      case 'paciente':
        return this.compare(a.paciente, b.paciente, isAsc);
      case 'hospital':
        return this.compare(a.hospital, b.hospital, isAsc);
      case 'cveInstitucion':
        return this.compare(a.cveInstitucion, b.cveInstitucion, isAsc);
      case 'estado':
        return this.compare(a.fk_estado, b.fk_estado, isAsc);
      case 'medico':
        let medicoA: string = (a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : '');
        let medicoB: string = (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): '');
        return this.compare(medicoA, medicoB, isAsc);
     case 'productos':
        let productosA: number = a.productos ? a.productos.length : 0;
        let productosB: number = b.productos ? b.productos.length : 0;
        return this.compare(productosA, productosB, isAsc);
      case 'diagnostico':
          let diagnosticoA: string = a.diagnostico;
          let diagnosticoB: string = b.diagnostico;
          return this.compare(diagnosticoA, diagnosticoB, isAsc);  
      default:
        return 0;
    }
  });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.casosclinicosFilter.forEach((cc, i) => {
        
        result.push( {
                      No: (i+1).toString(),   
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                      Folio: cc.serie,
                      Paciente: cc.paciente, 
                      Diagnostico: cc.diagnostico,
                      Hospital: cc.hospital,
                      Institucion: cc.cveInstitucion,
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',                      
                      Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',
                      Productos: cc.productos ? cc.productos.length : 0 
                    });
    });

  return result;
  }
  exportTablaExcel(): void {
    let tCasosClinicos: ItbCasosClinicos[] = this.casosclinicosFilter.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                              Folio: cc.serie,
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Hospital: cc.hospital,
                              Institucion: cc.cveInstitucion,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '', 
                              Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido: '', 
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tCasosClinicos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Casos Clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_InfCasosClinicos.xlsx`);
  }
  exportPDF():  void {
    const doc = new jsPDF('landscape');  
     
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Estado', dataKey: 'Estado' },
         { header: 'Medico', dataKey: 'Medico' },       
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_ccg.pdf`);
  }
  goBack(): void {
    this.location.back();
  }
}
