import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/servicios/auth.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';

const ADMINISTRADOR = 2;
const MEDICO = 3;
const ENFERMERA = 5;

@Component({
  selector: 'app-usuario-update',
  templateUrl: './usuario-update.component.html',
  styleUrls: ['./usuario-update.component.css']
})
export class UsuarioUpdateComponent implements OnInit {
  private usuarioFrm: FormGroup = new FormGroup({
    nombre: new FormControl('', Validators.required),
    primerApellido: new FormControl('', Validators.required),
    segundoApellido: new FormControl(''),
    telefonoMovil: new FormControl(''),
 });
 get nombreCtrl(){
   return this.usuarioFrm.get('nombre');
 }
 get primerApellidoCtrl(){
   return this.usuarioFrm.get('primerApellido');
 }
 get segundoApellidoCtrl(){
   return this.usuarioFrm.get('segundoApellido');
 }
 get telefonoMovilCtrl(){
   return this.usuarioFrm.get('telefonoMovil');
 }

 get usuario(){
   return this.auth.getUsuario;
 }
 constructor(
  private auth: AuthService, 
  private location: Location,
  private usuariosSrv: UsuariosService) { }

 ngOnInit() {
  let { nombre, primerApellido, segundoApellido, telefonoMovil} = this.usuario;
  this.usuarioFrm.setValue( { nombre, primerApellido, segundoApellido, telefonoMovil} );
}

goBack(){
  this.location.back();
}

onSubmit(){
  alert('actualizar');
  this.usuariosSrv
  .updateUsuario(this.usuario.id, this.usuarioFrm.value)
  .subscribe( data => { 
            
            this.usuario.nombre = data.nombre;
            this.usuario.primerApellido = data.primerApellido;
            this.usuario.segundoApellido = data.segundoApellido;
            this.usuario.telefonoMovil = data.telefonoMovil;
            console.log(typeof data);
            
  }, err =>{ console.log(err);});
}


}
