import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Enfermera } from 'src/app/moddelo/Enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';

@Component({
  selector: 'app-enfermera-puntos',
  templateUrl: './enfermera-puntos.component.html',
  styleUrls: ['./enfermera-puntos.component.css']
})
export class EnfermeraPuntosComponent implements OnInit {
  public enfermera: Enfermera;
  public productos: Producto[] = [];
  public p: Producto[] = [];
  public filtroFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private aut: AuthService, private enfemerasSrv: EnfermerasService, private formBuilder: FormBuilder) {
    this.enfermera = this.aut.getUsuario.enfermera;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get codigoCtrl() {
    return this.filtroFrm.get('codigo');
  }

  get productoCtrl() {
    return this.filtroFrm.get('producto');
  }
 
  get folioCtrl() {
    return this.filtroFrm.get('folio');
  }

  ngOnInit() {
    this.enfemerasSrv.card = 4;
    
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            codigo:[''],
                                            producto:['']                                            
                                            });

    this.p =  this.enfemerasSrv.enfermera.productos.sort((a, b) => { let a_fecha = new Date(a.fechaAplicacion); 
      let b_fecha = new Date(b.fechaAplicacion); 
      return b_fecha.getTime() - a_fecha.getTime()
    });
    
    this.productos = this.p;
    
    /*
    this.enfemerasSrv
    .getPuntos(this.enfermera.id)
    .subscribe( data  => {this.productos = data;}, err => {console.log(err)});
    */
  }
  onSubmit() {  
    this.productos = this.p.filter(producto => 
      this.filterCodigo(producto.codigo) && 
      this.filterProducto(producto.nomProducto) && 
      this.filterFolio(producto.casoclinico) && 
      this.filterFecha(producto.fechaAplicacion));
    this.page_number = 1;
  }

  filterFecha(fechaCirugia: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterCodigo(codigo: string): boolean {
    let condicion: boolean = true;
    let code: string = "";

    if(this.codigoCtrl.value != "") {
      code = this.codigoCtrl.value;
      condicion = codigo.toUpperCase().includes(code.toUpperCase());
    }    
    return condicion;
  }

  filterProducto(nomProductos: string): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    
    if(this.productoCtrl.value != ""){
        nom = this.productoCtrl.value;
        if(nomProductos)
        condicion = nomProductos.toUpperCase().includes(nom.toUpperCase());
    }    
    return condicion;
  }
 
  filterFolio(casoclinico: CasoClinico): boolean {
    let condicion: boolean = true;
    let f: String = "";
    let folio: String = "";

    if(this.folioCtrl.value != ""){
        f = this._normalizeValue(this.folioCtrl.value);
        if(f){
          folio = casoclinico.serie;
          condicion = folio.toLowerCase().includes(f.toLowerCase());
        }
    }        
    return condicion;
  }

  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  getCasoClinico(id: number): CasoClinico
  {
    return this.enfemerasSrv.enfermera.casosclinicos.find(cc =>cc.id == id);
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
}
