import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { ProductosService } from 'src/app/servicios/productos.service';
import { Producto } from 'src/app/moddelo/producto';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { finalize } from 'rxjs/operators';


interface Respuesta{
  code: number;
  message: string;
  productos: Producto[];
}
@Component({
  selector: 'app-administrador-productos-lote',
  templateUrl: './administrador-productos-lote.component.html',
  styleUrls: ['./administrador-productos-lote.component.css']
})
export class AdministradorProductosLoteComponent implements OnInit {
  public labeltext: string = "Seleccione un archivo";
  private fileUploadProgress: string = null;
  private fileData: File;
  public loading: boolean = false;

  get usuario(){
    return this.auth.getUsuario;
  }
  constructor(  
                private location: Location, 
                private productosSrv: ProductosService,
                private adminSrv: AdministradorService,
                private snackBar: MatSnackBar,
                private auth: AuthService, 
              ) { }

  ngOnInit() {
  }

  fileSelect(inputfile: any){
    this.fileData = <File> inputfile.target.files[0];
    this.labeltext = this.fileData.name;
  }

  onSubmit(){
    const formData = new FormData();
    
    if( this.fileData ) {
      formData.append('file', this.fileData);
      this.loading = true;
      this.productosSrv.setSubirArchivo(formData).pipe( 
                        finalize( () => this.loading = false )
                      ).subscribe( events => {
                                            let r: Respuesta;
                                            if(events.type === HttpEventType.UploadProgress){
                  
                                                this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + '%';
                                                console.log(this.fileUploadProgress);
                                                
                                              } else if( events.type === HttpEventType.Response){
                                                  r = <Respuesta> events.body;
                                                    
                                                    if(r.code === 0){
                                                      
                                                      this.snackBar.open(r.message, "Productos", {
                                                        duration: 3000,
                                                        verticalPosition:'bottom',
                                                        horizontalPosition:'center'
                                                      });

                                                    //  this.adminSrv.productos.push(...r.productos);

                                                    } else {
                                                        alert(r.message);
                                                    }
                                                          
                                                
                                              } else if(events.type === HttpEventType.ResponseHeader){
                                                  console.log( events.ok ); 
                                              }
                                              
                                              }, 
                                              err => { 
                                                if(err.error.noError){
                                                  
                                                  if(err.error.noError == 1062) {
                                                    
                                                    this.snackBar.open(err.error.message, "Productos", {
                                                      duration: 3000,
                                                      verticalPosition:'bottom',
                                                      horizontalPosition:'center'
                                                    });
                                                   
                                                  }                                                   
                                                  else
                                                  {
                                                    this.snackBar.open(err.error.message, "Productos", {
                                                      duration: 3000,
                                                      verticalPosition:'bottom',
                                                      horizontalPosition:'center'
                                                    });
                                                    console.log(err.error);
                                                  }
                                                     
                                                }else{
                                                  this.snackBar.open(err.error.message, "Productos", {
                                                    duration: 3000,
                                                    verticalPosition:'bottom',
                                                    horizontalPosition:'center'
                                                  });
                                                  //console.log(err.error.message);
                                                }
                                                
                                              });
    } else {
      alert('no hay archivo para subir');
    }
 
  } 

  goBack(): void {
    this.location.back();
  }

}
