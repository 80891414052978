import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';

import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';
import { DocumentoUsuario } from 'src/app/moddelo/documento-usuario';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { AuthService } from 'src/app/servicios/auth.service';

import { Usuario } from 'src/app/moddelo/usuario';
import { Medico } from 'src/app/moddelo/medico';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Residencia } from 'src/app/moddelo/residencia';
import { Puesto } from 'src/app/moddelo/puesto';
import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';
import { Router } from '@angular/router';

interface Respuesta{
  code: number;
  message: String;
  data: Usuario;
}

@Component({
  selector: 'app-administrador-medico-perfil',
  templateUrl: './administrador-medico-perfil.component.html',
  styleUrls: ['./administrador-medico-perfil.component.css']
})
export class AdministradorMedicoPerfilComponent implements OnInit {

  @Input('medico') medico: Medico;
  
  private usuario: Usuario = null;
  private especialidades: Especialidad[] = [];
  private residencias: Residencia[] = [];
  private puestos: Puesto[] = [];
  public documentosId: DocumentoIdentificacion[] = [];
  public usuarioMedicoFrm: FormGroup = new FormGroup({
                                          alias: new FormControl(''),
                                          nombre: new FormControl('', Validators.required),
                                          primerApellido: new FormControl('', Validators.required),
                                          segundoApellido: new FormControl(''),
                                          rfc: new FormControl(''),
                                          telefonoMovil: new FormControl(''),
                                          fk_especialidad: new FormControl(''),
                                          nomEspecialidad: new FormControl(''),
                                          fk_puesto: new FormControl(''),
                                          fk_residencia: new FormControl('')                                         
                                        });
  get aliasCtrl() { return this.usuarioMedicoFrm.get('alias'); }
  get nombreCtrl() { return this.usuarioMedicoFrm.get('nombre'); }
  get primerApellidoCtrl() { return this.usuarioMedicoFrm.get('primerApellido'); }
  get telefonoCtrl() { return this.usuarioMedicoFrm.get('telefonoMovil'); }
  get fk_puestoCtrl() { return this.usuarioMedicoFrm.get('fk_puesto'); }
  get fk_especialidadCtrl() { return this.usuarioMedicoFrm.get('fk_especialidad'); }
  get nomEspecialidadCtrl() { return this.usuarioMedicoFrm.get('nomEspecialidad'); }
  get fk_residenciaCtrl() { return this.usuarioMedicoFrm.get('fk_residencia'); }                                      


  get Authorizacion(){
    return this.auth.getUsuario;
  }

  get offBoton(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 2 || this.Authorizacion.fk_nivel == 0);
  }
  get passwHidden(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 0);
  }

  constructor( 
              private catalogosSrv: CatalogosService,
              private usuariosSrv: UsuariosService,           
              private snackBar: MatSnackBar,
              private auth: AuthService,
              private dialogo: MatDialog,
              private router: Router) { }

  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  get getUsuarioActivo() {
    let r = false;
    if(this.usuario){
      r = this.usuario.activo == 1;
    }
    return r;
  }
  get isProductivo() {
    let respuesta: Boolean = false;
    let medico: Medico = this.medico;
    
    respuesta = ( medico.redimirmedico.length == 0 && medico.casosclinicos.length == 0 && medico.enfermeras.length == 0 && medico.productos.length==0 );
   
    return respuesta;
  }
  ngOnInit() {
      this.usuario = this.medico.usuario;
      let alias = this.usuario.alias;
      let { nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia } = this.medico;
    
      this.usuarioMedicoFrm.setValue({ alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia });
           
      this.catalogosSrv.getEspecialidades().subscribe(data => {this.especialidades = data;}, err => { console.log(err); });
      this.catalogosSrv.getPuestos().subscribe(data => {this.puestos = data;}, err => { console.log(err) });
      this.catalogosSrv.getResidencias().subscribe(data => { this.residencias = data;}, err => { console.log(err) });

      this.fk_puestoCtrl.valueChanges.subscribe(e => { this.fk_residenciaCtrl.setValue('');  });

      this.fk_especialidadCtrl.valueChanges.subscribe(e => { 
          let especialidad = this.especialidades.find(esp => esp.id == e);
          if(especialidad) {
          this.nomEspecialidadCtrl.setValue(especialidad.nomEspecialidad);
          }else{
          this.nomEspecialidadCtrl.setValue('');
          }
      }, err => { console.log(err) });
  }
 
  onSubmit() {

    let { alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia} = this.usuarioMedicoFrm.value;

    let usuarioU: Usuario = <Usuario>{ alias, nombre, primerApellido, segundoApellido, telefonoMovil }; 
    let medicoU: Medico = <Medico>{ nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia };
    let id = this.usuario.id;
    
    this.usuariosSrv
    .setUsuarioMedicoUpdate( id, usuarioU, medicoU)
    .subscribe(data => { 
      
      this.snackBar.open(`Registrado actualizado`, "Medico",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });     
      
      this.usuario.alias = usuarioU.alias;
      this.usuario.nombre = usuarioU.nombre;
      this.usuario.primerApellido = usuarioU.primerApellido;
      this.usuario.segundoApellido = usuarioU.segundoApellido;
      this.usuario.telefonoMovil = usuarioU.telefonoMovil;
      /*
      this.usuario.medico.nombre = medicoU.nombre;
      this.usuario.medico.primerApellido = medicoU.primerApellido;
      this.usuario.medico.segundoApellido = medicoU.segundoApellido;
      
      this.usuario.medico.rfc = medicoU.rfc;
      this.usuario.medico.telefonoMovil = medicoU.telefonoMovil;
      this.usuario.medico.fk_especialidad = medicoU.fk_especialidad;
      this.usuario.medico.nomEspecialidad = medicoU.nomEspecialidad; 
      this.usuario.medico.fk_puesto = medicoU.fk_puesto; 
      this.usuario.medico.fk_residencia = medicoU.fk_residencia;
      */
      this.medico.nombre = medicoU.nombre;
      this.medico.primerApellido = medicoU.primerApellido;
      this.medico.segundoApellido = medicoU.segundoApellido;
      
      this.medico.rfc = medicoU.rfc;
      this.medico.telefonoMovil = medicoU.telefonoMovil;
      this.medico.fk_especialidad = medicoU.fk_especialidad;
      this.medico.nomEspecialidad = medicoU.nomEspecialidad; 
      this.medico.fk_puesto = medicoU.fk_puesto; 
      this.medico.fk_residencia = medicoU.fk_residencia;

      this.medico.usuario.alias = usuarioU.alias;
      this.medico.usuario.nombre = usuarioU.nombre;
      this.medico.usuario.primerApellido = usuarioU.primerApellido;
      this.medico.usuario.segundoApellido = usuarioU.segundoApellido;
      this.medico.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
     
    }, 
    err => { console.log(err); });
  
  }
  desactivar() {
    let id:number = this.usuario.id;
    if(confirm("seguro de desabilitar este usuario")){
      this.usuariosSrv.desactivarUSuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} desactivado`); } }, err => { console.log(err); });
    }

  }
  activar() {
    let id:number = this.usuario.id;
    if(confirm("seguro de habilitar este usuario")){
      this.usuariosSrv.activarUsuario(id).subscribe(data => { if(data){ alert(`Usuario ${data.usuario} activado`); } }, err => { console.log(err); });
    }
    
  }
  delete(): void{
    if(confirm("seguro de eliminar este usuario")){
      this.usuariosSrv.deleteUsuarioOperativo(this.usuario.id).subscribe(data => { 
        let usuario: Usuario;
        let respuesta: Respuesta;
        
        respuesta = <Respuesta> data;
        usuario = respuesta.data;
        
        alert(respuesta.message);

        if( respuesta.code == 1){
          this.router.navigate(['/administrador/medicos']); 
        }
       
      }, 
      err => { console.log(err) });
    }
  }
  changePassw(): void {
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: this.usuario});
  }
  
  
  
}
