import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Usuario} from '../moddelo/usuario';
import { Enfermera } from '../moddelo/enfermera';
import { Medico } from '../moddelo/medico';

import { DocumentoUsuario } from '../moddelo/documento-usuario';
import { Supervisor } from '../moddelo/supervisor';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  constructor(private http: HttpClient) {     
  }
  setUsuarioPasswordUpdate(id: number, usuario: Usuario){
    return this.http.put<Usuario>(`/apibioc/public/api/usuario/passw/${id}`, usuario);
  }
  setUsuarioMedicoNuevo(usuario: Usuario, medico: Medico){
    return this.http.post<Usuario>('/apibioc/public/api/usuario/medico', { usuario, medico});
  }
  setUsuarioMedicoUpdate(id: number, usuario: Usuario, medico: Medico){
    return this.http.put<Usuario>(`/apibioc/public/api/usuario/medico/${id}`, { usuario, medico});
  }
  setUsuarioEnfermeraNuevo(usuario: Usuario, enfermera: Enfermera){
    return this.http.post<Usuario>('/apibioc/public/api/usuario/enfermera', { usuario, enfermera});
  }
  setUsuarioEnfermeraUpdate(id: number, usuario: Usuario, enfermera: Enfermera){
    return this.http.put<Usuario>(`/apibioc/public/api/usuario/enfermera/${id}`, { usuario, enfermera});
  }
  setUsuarioSupervisorNuevo(usuario: Usuario, supervisor: Supervisor){
    return this.http.post<Usuario>('/apibioc/public/api/usuario/supervisor', { usuario, supervisor});
  }
  setUsuarioSupervisorUpdate(id: number, usuario: Usuario, supervisor: Supervisor){
    return this.http.put<Usuario>(`/apibioc/public/api/usuario/supervisor/${id}`, { usuario, supervisor});
  }


  getUsuarioDocumentos(id: number){
    return this.http.get<DocumentoUsuario>(`/apibioc/public/api/usuario/documentos/${id}`);
  }
  setSubir(formData: FormData){
    return this.http.post('/apibioc/public/api/usuario-upfile', formData, { reportProgress: true, observe: 'events' });
  }
  setDeleteDocumento(id: number){
    return this.http.delete(`/apibioc/public/api/usuario/documento/${id}`);
  }


  getUsuarios(){
    return this.http.get<Usuario[]>('/apibioc/public/api/usuarios', httpOptions);
  }
  setUsuario(usuario: Usuario){
    return this.http.post<Usuario>('/apibioc/public/api/usuario', usuario);
  }
  getUsuario(id: number){
    return this.http.get<Usuario>(`/apibioc/public/api/usuario/${id}`);
  }
  updateUsuario(id: number, usuario: Usuario){
    return this.http.put<Usuario>(`/apibioc/public/api/usuario/${id}`, usuario);
  } 
  deleteUsuario(id: number){
    return this.http.delete<Usuario>(`/apibioc/public/api/usuario/${id}`, httpOptions);
  }
  desactivarUSuario(id: number){
    return this.http.get<Usuario>(`/apibioc/public/api/usuario/desactivar/${id}`, httpOptions);
  }
  activarUsuario(id: number){
    return this.http.get<Usuario>(`/apibioc/public/api/usuario/activar/${id}`, httpOptions);
  }
 
  deleteUsuarioOperativo(id: number){
    return this.http.delete<any>(`/apibioc/public/api/usuario/operativo/${id}`, httpOptions);
  }
}
