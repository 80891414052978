import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Producto } from 'src/app/moddelo/producto';
import { Medico } from 'src/app/moddelo/medico';

@Component({
    selector: 'dialogo-asignar-enfermera-casoclinico',
    templateUrl: 'dialogo-asignar-enfermera-casoclinico.html'
})
export class DialogoAsignarEnfermeraCasoclinico{
    
   // public medico: Medico;    
    public enfermeras: Enfermera[] = [];
    public productos: Producto[] = [];
    public casoClinicoFrm = new FormGroup({ 
                                            id: new FormControl('', Validators.required),
                                            folio: new FormControl('', Validators.required),
                                            hospital: new FormControl(''),
                                            fk_enfermera: new FormControl('', Validators.required)                                  
                                            });

    get idCtrl(){
    return this.casoClinicoFrm.get('id');
    }
    get folioCtrl(){
    return this.casoClinicoFrm.get('folio');
    }
    get hospitalCtrl(){
    return this.casoClinicoFrm.get('hospital');
    }
    get fk_enfermeraCtrl(){
    return this.casoClinicoFrm.get('fk_enfermera');
    } 
    constructor(
                    public dialogo: MatDialogRef<DialogoAsignarEnfermeraCasoclinico>, 
                    @Inject(MAT_DIALOG_DATA) public data: {casoclinico: CasoClinico, medico: Medico},
                    private adminSrv: AdministradorService,
                    private snackBar: MatSnackBar 
                ){
                    //this.medico = this.adminSrv.medicos.find(medico => medico.id == data.fk_medico);                
                    this.enfermeras = this.data.medico.enfermeras;
                    this.productos = this.data.casoclinico.productos;

                    this.idCtrl.setValue(this.data.casoclinico.id);
                    this.folioCtrl.setValue(this.data.casoclinico.folio);
                    this.hospitalCtrl.setValue(this.data.casoclinico.hospital);

                    if(this.data.casoclinico.fk_enfermera)
                        this.fk_enfermeraCtrl.setValue(this.data.casoclinico.fk_enfermera);
                }

    onClose(): void {
        this.dialogo.close();
    } 
    onSubmit(): void{

        let { id, folio, fk_enfermera } = this.casoClinicoFrm.value;
        let casoClinico: CasoClinico = <CasoClinico> {id, folio, fk_enfermera};
        let enfermera = this.enfermeras.find(e => e.id == this.fk_enfermeraCtrl.value);
       // console.log("Id caso Clinico", id);
        this.adminSrv.setUpCasoClinicoEnfermeraProPuntos(casoClinico.id, enfermera).subscribe(dtoCasoClinico => { 

                    if(dtoCasoClinico.fk_enfermera == enfermera.id){
                      
                        this.data.casoclinico.fk_enfermera = enfermera.id;
                        this.data.casoclinico.enfermera = enfermera;
                        
                        
                                
                        this.snackBar.open('Actualizada', "Caso Clinico", {
                                        duration: 3000,
                                        verticalPosition:'bottom',
                                        horizontalPosition:'center'
                        });

                        this.data.casoclinico.productos.forEach((p, i) => {
                            
                            let  prod = this.data.medico.productos.find(producto => producto.id == p.id && producto.fk_casoClinico == p.fk_casoClinico);
                                 prod.fk_enfermera = enfermera.id;
                                 prod.enfermera = enfermera;
                         });
                         
                        this.onClose();
                    }

    }, err => { console.log(err); });

        //this.data.casoclinico.fk_enfermera = enfermera.id;
        //this.data.casoclinico.enfermera = enfermera;    
    }   
}