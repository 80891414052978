import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Medico } from 'src/app/moddelo/medico';
import { Producto } from 'src/app/moddelo/producto';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { AuthService } from 'src/app/servicios/auth.service';
 
interface ActualizarPuntosMedico{
  code: number;
  message: string;
  data: Producto;
}

@Component({
  selector: 'app-administrador-medico-r-punto-update',
  templateUrl: './administrador-medico-r-punto-update.component.html',
  styleUrls: ['./administrador-medico-r-punto-update.component.css']
})
export class AdministradorMedicoRPuntoUpdateComponent implements OnInit {
  public medico: Medico;
  public producto: Producto;
  public respuesta: ActualizarPuntosMedico;

  public puntosProducto: PuntosPorProducto[] = [];  
  public productoFrm: FormGroup = new FormGroup({
                                                codigo: new FormControl(''),
                                                nomProducto: new FormControl(''),
                                                puntos: new FormControl('')
                                              });
  get codigoCtrl(){
    return this.productoFrm.get('codigo');
  }
  get nomProductoCtrl(){
    return this.productoFrm.get('nomProducto');
  }                                            
  get puntosCtrl(){
    return this.productoFrm.get('puntos');
  }
  get usuario(){
    return this.auth.getUsuario;    
  }
  get offBoton(){
    return this.productoFrm.valid && ( this.usuario.fk_nivel==0  || this.usuario.fk_nivel==3 );
  }
  get offSelect(){
    return ( this.usuario.fk_nivel == 0 ||  this.usuario.fk_nivel == 3);
  }
  constructor(private adminSrv: AdministradorService, 
              private activateRoute: ActivatedRoute, 
              private location: Location,
              private catalogoSrv: CatalogosService,
              private snackBar: MatSnackBar,
              private auth: AuthService) { }

  ngOnInit() {
    let id_medico = this.activateRoute.snapshot.paramMap.get('id1');
    let id_producto = this.activateRoute.snapshot.paramMap.get('id2');

    this.medico = this.adminSrv.medicos.find( medico => medico.id == Number(id_medico));

    this.producto = this.medico.productos.find( acumulado => acumulado.id == Number(id_producto));
   
    
    this.codigoCtrl.setValue(this.producto.codigo);
    this.nomProductoCtrl.setValue(this.producto.nomProducto);
    this.puntosCtrl.setValue(this.producto.m_puntos);
  
    this.catalogoSrv.getPuntosPorProductos().subscribe(data => this.puntosProducto = data, err => console.log(err));
  }
  onSubmit(){

    let id_producto = this.producto.id;
    let puntos = this.puntosCtrl.value;

    this.adminSrv
    .setActualizarPuntosMedico(id_producto, puntos)
    .subscribe( data => { this.respuesta = <ActualizarPuntosMedico> data;
                                if(this.respuesta.code == 1){
                                  this.producto.m_puntos = this.puntosCtrl.value;
                                  this.snackBar
                                  .open(
                                    this.respuesta.message, 
                                    'Medico', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                                }
                                else {
                                      this.snackBar
                                      .open(
                                        this.respuesta.message, 
                                        'Medico', 
                                        {
                                          duration: 3000,
                                          verticalPosition:'bottom',
                                          horizontalPosition:'center'
                                        });
                                  }
                               
                        }, err => { console.log(err); }); 
    
  }
  goBack(): void {
    this.location.back();
  }

}
