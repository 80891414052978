import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DocumentoIdentificacion } from 'src/app/moddelo/documento-identificacion';
import { DocumentoUsuario } from 'src/app/moddelo/documento-usuario';
import { Usuario } from 'src/app/moddelo/usuario';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';

interface Response{
  code: number; 
  message: string;
  data: DocumentoUsuario;
}

@Component({
  selector: 'app-usuario-documentos-update',
  templateUrl: './usuario-documentos-update.component.html',
  styleUrls: ['./usuario-documentos-update.component.css']
})
export class UsuarioDocumentosUpdateComponent implements OnInit {
  public documentosId: DocumentoIdentificacion[] = [];
  constructor(
             
              private catalogosSrv: CatalogosService,
              private usuariosSrv: UsuariosService,
              private snackBar: MatSnackBar) { }
  
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
 
  @Input() usuario: Usuario;

  ngOnInit() {
    this.catalogosSrv.getDocumentosIdentificacion().subscribe(data => { this.documentosId = data; }, err => { console.log(err); });
  }
  DocIdentificacion(id: number): string {
    let msj: string = "";
    if(id && this.documentosId.length>0){
      msj = this.documentosId.find(doc => doc.id == id).nomDocumentoId;
    }
    return msj;
  }
  borrar(id: number) {    
    let conf: boolean = confirm("estas seguro de eliminar este registro");
    if(conf){
      this.usuariosSrv
      .setDeleteDocumento(id)
      .subscribe( res => {
        let r: Response = <Response>res;
        if(r.code){
          let i:number = this.usuario.documentos.findIndex(doc => doc.id == id);
          this.usuario.documentos.splice(i, 1);       
        }
        this.snackBar.open(r.message, 'Documento', {
          duration: 3000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });
        
      }, err => { console.log(err); } );

    }
  }
}
