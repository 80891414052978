import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Supervisor } from 'src/app/moddelo/supervisor';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { Medico } from 'src/app/moddelo/medico';

interface ItbCasosClinicos{
  No: string;
  Fecha: string;
	Folio: string;
	Paciente: string;
	Diagnostico: string;
	Hospital: string;
  Institucion: string;
	Estado: string;
  Medico: string;
  Enfermera: string;
  Productos: number;
}

@Component({
  selector: 'app-administrador-supervisor-r-casos-clinicos',
  templateUrl: './administrador-supervisor-r-casos-clinicos.component.html',
  styleUrls: ['./administrador-supervisor-r-casos-clinicos.component.css']
})
export class AdministradorSupervisorRCasosClinicosComponent implements OnInit, AfterViewInit {
  @Input('casosclinicos') casosclinicos: CasoClinico[];
  @Input('supervisor') supervisor: Supervisor;
  public filtroFrm: FormGroup;
  public filtroCasosClinicos: CasoClinico [] = [];

  public page_size: number = 5;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [0, 5, 10, 20, 50, 100];
 

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }

  get institucionCtrl(){
    return this.filtroFrm.get('institucion');
  }

  get enfermeraCtrl() {
    return this.filtroFrm.get('enfermera');
  }

  get medicoCtrl() {
    return this.filtroFrm.get('medico');
  }
  constructor(private formBuilder: FormBuilder) { 

    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial: [''],
                                            fechaFinal: [''],
                                            folio: [''],
                                            paciente: [''],
                                            hospital: [''],
                                            enfermera: [''],
                                            medico: [''], 
                                            institucion: ['']
                                          });
                                        
  }
  ngAfterViewInit(): void {
    this.filtroCasosClinicos = this.casosclinicos;
  }
  ngOnInit() {
      
  }
  onSubmit() {
    this.filtroCasosClinicos =  this.casosclinicos.filter( cc => 
                                                                this.filterPaciente(cc.paciente) && 
                                                                this.filterHospital(cc.hospital) && 
                                                                this.filterInstitucion(cc.cveInstitucion) && 
                                                                this.filterFolio(cc.folio) && 
                                                                this.filterEnfermera(cc.fk_enfermera, cc.enfermera) && 
                                                                this.filterMedico(cc.fk_medico, cc.medico) && 
                                                                this.filterFecha(cc.fecha.toString())
                                                          );

    this.page_number=1;  
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }

  filterEnfermera(fk_enfermera: number, enfermera: Enfermera): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(fk_enfermera){
          nomEnfermera = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterMedico(fk_medico: number, medico: Medico): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: String = "";

    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico){
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
        }
    }
    
    return condicion;
  }
  
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  sortCasosClinicos(sort: Sort): void {
    const datos: CasoClinico[] = this.filtroCasosClinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.filtroCasosClinicos = datos;
      return;
    }
    this.filtroCasosClinicos =  datos.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'fecha':
        let a1: number = new Date(a.fecha).getTime();
        let b1: number = new Date(b.fecha).getTime();
        return this.compare(a1, b1, isAsc);
      case 'serie':
        return this.compare(a.serie, b.serie, isAsc);
      case 'paciente':
        return this.compare(a.paciente, b.paciente, isAsc);
      case 'hospital':
        return this.compare(a.hospital, b.hospital, isAsc);
      case 'cveInstitucion':
        return this.compare(a.cveInstitucion, b.cveInstitucion, isAsc);
      case 'estado':
        return this.compare(a.fk_estado, b.fk_estado, isAsc);
      case 'medico':
        let medicoA: string = (a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : '');
        let medicoB: string = (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): '');
        return this.compare(medicoA, medicoB, isAsc);
      case 'enfermera':
        let enfermeraA: string = (a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '');
        let enfermeraB: string = (b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido : ''): '');
        return this.compare(enfermeraA, enfermeraB, isAsc);  
      case 'productos':
        let productosA: number = a.productos ? a.productos.length : 0;
        let productosB: number = b.productos ? b.productos.length : 0;
        return this.compare(productosA, productosB, isAsc);
      case 'diagnostico':
          let diagnosticoA: string = a.diagnostico;
          let diagnosticoB: string = b.diagnostico;
          return this.compare(diagnosticoA, diagnosticoB, isAsc);  
      default:
        return 0;
    }
  });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  generateData(): any[] {
    let result: ItbCasosClinicos[] = [];
    let i: number = 0;

    this.filtroCasosClinicos.forEach((cc, i) => {
        
        result.push( {
                      No: (i+1).toString(),   
                      Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                      Folio: cc.folio,
                      Paciente: cc.paciente, 
                      Diagnostico: cc.diagnostico,
                      Hospital: cc.hospital,
                      Institucion: cc.cveInstitucion,
                      Estado: cc.fk_estado ? cc.estado.nomEstado : '',
                      Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',                       
                      Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido : '',
                      Productos: cc.productos ? cc.productos.length : 0 
                    });
    });

  return result;
  }
  exportTablaExcel(): void{
    let nombre = [{campo: "SUPERVISOR", nomSupervisor: this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.filtroCasosClinicos.map((cc, i, casos) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                              Folio: cc.serie,
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Hospital: cc.hospital,
                              Institucion: cc.cveInstitucion,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
                              Enfermera: cc.fk_enfermera ? cc.enfermera.nombre + ' ' + cc.enfermera.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombre, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'supervisor_casos_clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}supervisorcasosclinicos.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombreMedico: string = this.supervisor.nombre + ' ' + this.supervisor.primerApellido + (this.supervisor.primerApellido ? ' ' + this.supervisor.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Estado', dataKey: 'Estado' },  
         { header: 'Medico', dataKey: 'Medico' },         
         { header: 'Enfermera', dataKey: 'Enfermera' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_supervisor_cc.pdf`);
  }
}
