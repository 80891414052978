import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';
import { Medico } from 'src/app/moddelo/medico';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Residencia } from 'src/app/moddelo/residencia';
import { Puesto } from 'src/app/moddelo/puesto';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';

@Component({
  selector: 'app-usuario-medico-update',
  templateUrl: './usuario-medico-update.component.html',
  styleUrls: ['./usuario-medico-update.component.css']
})
export class UsuarioMedicoUpdateComponent implements OnInit {
  private usuario: Usuario = null;
  private especialidades: Especialidad[] = [];
  private residencias: Residencia[] = [];
  private puestos: Puesto[] = [];
  public usuarioMedicoFrm: FormGroup = new FormGroup({
                                          alias: new FormControl(''),
                                          nombre: new FormControl('', Validators.required),
                                          primerApellido: new FormControl('', Validators.required),
                                          segundoApellido: new FormControl(''),
                                          rfc: new FormControl(''),
                                          telefonoMovil: new FormControl(''),
                                          fk_especialidad: new FormControl(''),
                                          nomEspecialidad: new FormControl(''),
                                          fk_puesto: new FormControl(''),
                                          fk_residencia: new FormControl('')                                         
                                        });


  get aliasCtrl() { return this.usuarioMedicoFrm.get('alias'); }
  get nombreCtrl() { return this.usuarioMedicoFrm.get('nombre'); }
  get primerApellidoCtrl() { return this.usuarioMedicoFrm.get('primerApellido'); }
  get telefonoCtrl() { return this.usuarioMedicoFrm.get('telefonoMovil'); }
  get fk_puestoCtrl() { return this.usuarioMedicoFrm.get('fk_puesto'); }
  get fk_especialidadCtrl() { return this.usuarioMedicoFrm.get('fk_especialidad'); }
  get nomEspecialidadCtrl() { return this.usuarioMedicoFrm.get('nomEspecialidad'); }
  get fk_residenciaCtrl() { return this.usuarioMedicoFrm.get('fk_residencia'); }


  get Authorizacion(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 2 || this.Authorizacion.fk_nivel == 0);
  }

  constructor (
                private location: Location, 
                private catalogosSrv: CatalogosService,
                private usuariosSrv: UsuariosService,
                private adminSrv: AdministradorService,
                private activated: ActivatedRoute,
                private snackBar: MatSnackBar,
                private auth: AuthService
  ) {}

  ngOnInit() {
      let id_usuario = Number(this.activated.snapshot.paramMap.get('id'));
      
      this.usuariosSrv
      .getUsuario(id_usuario)
      .subscribe(data => {
        this.usuario = data;  
        let alias = this.usuario.alias;
        let { nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia } = this.usuario.medico;
      
        this.usuarioMedicoFrm.setValue({ alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia });

      }, err => { console.log(err); });
      
      this.catalogosSrv.getEspecialidades().subscribe(data => {this.especialidades = data;}, err => { console.log(err); });
      this.catalogosSrv.getPuestos().subscribe(data => {this.puestos = data;}, err => { console.log(err) });
      this.catalogosSrv.getResidencias().subscribe(data => { this.residencias = data;}, err => { console.log(err) });

      this.fk_puestoCtrl.valueChanges.subscribe(e => { this.fk_residenciaCtrl.setValue('');  });

      this.fk_especialidadCtrl.valueChanges.subscribe(e => { 
          let especialidad = this.especialidades.find(esp => esp.id == e);
          if(especialidad) {
          this.nomEspecialidadCtrl.setValue(especialidad.nomEspecialidad);
          }else{
          this.nomEspecialidadCtrl.setValue('');
          }
      }, err => { console.log(err) });
  }

  onSubmit() {

    let { alias, nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia} = this.usuarioMedicoFrm.value;

    let usuarioU: Usuario = <Usuario>{ alias, nombre, primerApellido, segundoApellido, telefonoMovil }; 
    let medicoU: Medico = <Medico>{ nombre, primerApellido, segundoApellido, rfc, telefonoMovil, fk_especialidad, nomEspecialidad, fk_puesto, fk_residencia };
    let id = this.usuario.id;
    
    this.usuariosSrv
    .setUsuarioMedicoUpdate( id, usuarioU, medicoU)
    .subscribe(data => { 
      
      this.snackBar.open(`Registrado actualizado`, "Medico",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });     
      
      this.usuario.alias = usuarioU.alias;
      this.usuario.nombre = usuarioU.nombre;
      this.usuario.primerApellido = usuarioU.primerApellido;
      this.usuario.segundoApellido = usuarioU.segundoApellido;
      this.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.usuario.medico.nombre = medicoU.nombre;
      this.usuario.medico.primerApellido = medicoU.primerApellido;
      this.usuario.medico.segundoApellido = medicoU.segundoApellido;
      
      this.usuario.medico.rfc = medicoU.rfc;
      this.usuario.medico.telefonoMovil = medicoU.telefonoMovil;
      this.usuario.medico.fk_especialidad = medicoU.fk_especialidad;
      this.usuario.medico.nomEspecialidad = medicoU.nomEspecialidad; 
      this.usuario.medico.fk_puesto = medicoU.fk_puesto; 
      this.usuario.medico.fk_residencia = medicoU.fk_residencia;

      let medico: Medico = this.adminSrv.medicos.find(medico => medico.fk_usuario == this.usuario.id);
      medico.nombre = medicoU.nombre;
      medico.primerApellido = medicoU.primerApellido;
      medico.segundoApellido = medicoU.segundoApellido;
      
      medico.rfc = medicoU.rfc;
      medico.telefonoMovil = medicoU.telefonoMovil;
      medico.fk_especialidad = medicoU.fk_especialidad;
      medico.nomEspecialidad = medicoU.nomEspecialidad; 
      medico.fk_puesto = medicoU.fk_puesto; 
      medico.fk_residencia = medicoU.fk_residencia;

      medico.usuario.alias = usuarioU.alias;
      medico.usuario.nombre = usuarioU.nombre;
      medico.usuario.primerApellido = usuarioU.primerApellido;
      medico.usuario.segundoApellido = usuarioU.segundoApellido;
      medico.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.goBack(); 
    }, 
    err => { console.log(err); });
  
  }

  goBack() {
    this.location.back();
  }

}
