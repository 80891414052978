import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CasoClinico } from '../moddelo/caso-clinico';
import { Fecha } from '../moddelo/fecha';
import { Producto } from '../moddelo/producto';
import { DocumentoCasoClinico } from '../moddelo/documento-caso-clinico';
import { SeguimientoPostoperatorio } from '../moddelo/seguimiento-postoperatorio';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CasosClinicosService {  
  
  public casoClinico: CasoClinico; 

  public seguimientosPostoperatorios: SeguimientoPostoperatorio[] = [];
    
  constructor(private http: HttpClient) { }  
  
  setSubir(formData: FormData)
  {
    return this.http.post('/apibioc/public/api/casoclinico-upfile', formData, { reportProgress: true, observe: 'events' });
  }
  setBajar(id: number)
  {
    return this.http.delete(`/apibioc/public/api/casoclinico/documento/${id}`, httpOptions);
  }
  getFolio(id: number)
  {
    return this.http.get(`/apibioc/public/api/casoclinico/folio/${id}`, httpOptions);
  }

  getFechas(id: any)
  {
    return this.http.get<Fecha>(`/apibioc/public/api/casoclinico/fechas/${id}`, httpOptions);
  } 

  setCasoClinico(casoClinico: CasoClinico)
  {
    return this.http.post<CasoClinico>('/apibioc/public/api/casoclinico', casoClinico);
  }

  setCasoClinicoUpdate(id: String, casoClinico: CasoClinico)
  {
    return this.http.put<CasoClinico>(`/apibioc/public/api/casoclinico/${id}`, casoClinico);
  }

  getCasoClinico(id: any)
  {
    return this.http.get<CasoClinico>(`/apibioc/public/api/casoclinico/${id}`, httpOptions);
  }

  getCasosClinicos()
  {
    return this.http.get<CasoClinico[]>('/apibioc/public/api/casosclinicos', httpOptions);
  }
  
  getCasoClinicoDelete(id: Number) 
  {
    return this.http.delete<CasoClinico>(`/apibioc/public/api/casoclinico/${id}`);
  }
  
  setSeguimientoPostOperatorio(seguimiento: SeguimientoPostoperatorio)
  {
    return this.http.post<SeguimientoPostoperatorio>('/apibioc/public/api/seguimiento', seguimiento);
  }

  getSeguimientoPostOperatorio(id: any)
  {
    return this.http.get<SeguimientoPostoperatorio>(`/apibioc/public/api/seguimiento/${id}`);
  }
    
  setSeguimientoPostOperatorioUpdate(id: number, seguimiento: SeguimientoPostoperatorio)
  {
    return this.http.put(`/apibioc/public/api/seguimiento/${id}`, seguimiento);
  }
  
  getCasosClinicosQuery(query: any)
  {
    return this.http.post('/apibioc/public/api/casoclinico/consulta', query);
  }

}
