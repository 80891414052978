import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Usuario } from 'src/app/moddelo/usuario';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { UsuariosService } from 'src/app/servicios/usuarios.service';


@Component({
  selector: 'app-usuario-enfermera-create',
  templateUrl: './usuario-enfermera-create.component.html',
  styleUrls: ['./usuario-enfermera-create.component.css']
})
export class UsuarioEnfermeraCreateComponent implements OnInit {
  public usuario: Usuario = new Usuario();
  public enfermera: Enfermera = new Enfermera();
  public usuarioEnfermeraFrm = new FormGroup({
                                usuario: new FormControl('', Validators.required),
                                password: new FormControl('', Validators.required),
                                alias: new FormControl('', Validators.required),
                                nombre: new FormControl('', Validators.required),
                                primerApellido: new FormControl('', Validators.required),
                                segundoApellido: new FormControl(''),
                                telefonoMovil: new FormControl('', Validators.required),
                                fk_rol: new FormControl(5),
                                fk_puntos: new FormControl(1, Validators.required),
                                puntos: new FormControl(500)
                          });

  get usuarioCtrl(){ return this.usuarioEnfermeraFrm.get('usuario');}
  get passwordCtrl(){ return this.usuarioEnfermeraFrm.get('password');}
  get aliasCtrl(){ return this.usuarioEnfermeraFrm.get('alias');}
  get nombreCtrl(){ return this.usuarioEnfermeraFrm.get('nombre');}
  get primerApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('primerApellido');}
  get telefonoCtrl(){ return this.usuarioEnfermeraFrm.get('telefonoMovil');}
  get fk_puntosCtrl(){ return this.usuarioEnfermeraFrm.get('fk_puntos'); }

  get segundoApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('segundoApellido');}
  get fk_rolCtrl(){ return this.usuarioEnfermeraFrm.get('fk_rol');}
  get puntosCtrl(){ return this.usuarioEnfermeraFrm.get('puntos');}  
  
  constructor(
              private location: Location, 
              private usuariosSrv: UsuariosService, 
              private snackBar: MatSnackBar
              ) { }

  ngOnInit() {
  }

  onSubmit(){    
    let { usuario, password, alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_puntos, puntos, fk_rol } = this.usuarioEnfermeraFrm.value;
    let usuarioN: Usuario = <Usuario> {usuario, password, alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_rol };
    let enfermeraN: Enfermera = <Enfermera> { nombre, primerApellido, segundoApellido, telefonoMovil, fk_puntos, puntos};
    enfermeraN.correo = usuario;

    this.usuariosSrv
    .setUsuarioEnfermeraNuevo(usuarioN, enfermeraN)
    .subscribe(data => {
                        alert("Registrado Enfermera!!!"); 
                        
                        this.goBack() 
    }, 
    err => {  
      
      if(err) {
        alert(err.error.message);
      }
       
    });

  }
  goBack(): void {
    this.location.back();
  }

}
