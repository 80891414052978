import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators'; 
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { SupervisoresService } from 'src/app/servicios/supervisores.service';

interface AddEnfermera{
  code: number;
  message: string;
  data: Enfermera;
}
@Component({
  selector: 'app-administrador-supervisor-r-enfermera-add',
  templateUrl: './administrador-supervisor-r-enfermera-add.component.html',
  styleUrls: ['./administrador-supervisor-r-enfermera-add.component.css']
})
export class AdministradorSupervisorREnfermeraAddComponent implements OnInit {
  public enfermeras: Enfermera[] = [];
  public supervisor: Supervisor;
  public respuesta: AddEnfermera;
  private filteredEnfermeras: Observable<Enfermera[]>;
  public enfermeraFrm: FormGroup = new FormGroup({    
                                         enfermera: new FormControl('')   
                                      });
  get usuario(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return this.enfermeraFrm.valid && ( this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);  
  }
  constructor( private location: Location, 
    private route: ActivatedRoute, 
    private auth: AuthService, 
    private adminSrv: AdministradorService,
    private supervisoresSrv: SupervisoresService, 
    private enfermerasSrv: EnfermerasService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.supervisor = this.adminSrv.supervisores.find( supervisor => supervisor.id == Number(id));

    this.filteredEnfermeras = this.enfermeraFrm.controls["enfermera"].valueChanges.pipe(startWith(''), map(value => this._filterEnfermera(value)));
   
    this.enfermerasSrv.getEnfermeras().subscribe(data => { this.enfermeras = data; }, err => { console.log(err)});  
  }
  onSubmit(event: any){
    let enfermera: Enfermera = <Enfermera>this.enfermeraFrm.value.enfermera;  
    this.supervisoresSrv
     .setAsociarEnfemera(this.supervisor.id, enfermera)
     .subscribe(data => {
                           this.respuesta = <AddEnfermera> data;
                           if(this.respuesta.code == 1){          
                             this.supervisor.enfermeras.push(enfermera);
                             this.adminSrv.enfermeras.find( elemento => elemento.id == enfermera.id).supervisor = this.supervisor;
                             this.snackBar
                             .open(
                               this.respuesta.message, 
                               'Enfermera', 
                               {
                                 duration: 3000,
                                 verticalPosition:'bottom',
                                 horizontalPosition:'center'
                               });
                             console.log(this.respuesta.message);
                             this.goBack(); 
                           }else{
                             this.snackBar
                             .open(
                               this.respuesta.message, 
                               'Enfermera', 
                               {
                                 duration: 3000,
                                 verticalPosition:'bottom',
                                 horizontalPosition:'center'
                               });
                             console.log(this.respuesta.message);
                           }
     }, err => { 
                   if(err.status == 500){
                     if(err.error.includes('Duplicate')){            
                         this.snackBar
                         .open(
                           'La enfermera ya esta asociada', 
                           'Medico', 
                           {
                             duration: 3000,
                             verticalPosition:'bottom',
                             horizontalPosition:'center'
                           });
                     }                    
                   }
                   console.log(err) 
               } );  
   }
  _normalizeValue(nombre: string): string 
  {
    let cadena = String(nombre).toLowerCase();
    return cadena ? cadena.replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : '';
  }
  _filterEnfermera(nombre: string): Enfermera[] 
  {
    const valorFiltro = this._normalizeValue(nombre);
    return this.enfermeras.filter(enfermera => this._normalizeValue(enfermera.nombre).includes(valorFiltro) ||  this._normalizeValue(enfermera.primerApellido).includes(valorFiltro) || this._normalizeValue(enfermera.segundoApellido).includes(valorFiltro));
  }
  displayFnEnfermera(enfermera: Enfermera): string 
  {
    return  enfermera  ? enfermera.nombre.concat(' ').concat(enfermera.primerApellido).concat(' ').concat(enfermera.segundoApellido) : '';
  }
  goBack(): void 
  {
    this.location.back();
  }

}
