import { CasoClinico } from "./caso-clinico";
import { Enfermera } from "./enfermera";
import { Producto } from "./producto";
import { Puesto } from "./puesto";
import { RedimirMedico } from "./redimir-medico";
import { Residencia } from "./residencia";
import { Usuario } from "./usuario";

export class Medico {
        id : number;
        nombre: string;
        primerApellido: string;
        segundoApellido: string;
        rfc: string;
        correo: string;
        telefonoMovil: string;
        fk_especialidad: number;
        nomEspecialidad: string;
        fk_puesto: number;
        fk_residencia: number;
        fk_usuario: number;
        created_at: Date;
        updated_at: Date;
        puntos: number;
        fk_puntos: number;
        puntos_seg: number; 
        fk_puntos_seg: number;
        nombre_completo: string;
        puesto: Puesto;
        residencia: Residencia;
        enfermeras: Enfermera[];
        casosclinicos: CasoClinico[];
        redimirmedico: RedimirMedico[];
        productos: Producto[];
        usuario: Usuario;             
}
/*
Eliminar Archivos Modelos: 
acumulado
canje
canjeenfermera
canjemedico
*/