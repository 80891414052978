import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';

import S from '../../../datos/status';

interface Response{
  code: number; 
  message: string;
  data: RedimirEnfermera;
}
@Component({
  selector: 'app-administrador-enfermera-r-canje-delete',
  templateUrl: './administrador-enfermera-r-canje-delete.component.html',
  styleUrls: ['./administrador-enfermera-r-canje-delete.component.css']
})
export class AdministradorEnfermeraRCanjeDeleteComponent implements OnInit {
  @Input('enfermera') public enfermera: Enfermera;
  @Input('canje') public canje: RedimirEnfermera;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();
  
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  get usuario(){
    return this.auth.getUsuario;
  }
  get offButton(){
    return (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0);
  }
  constructor(
    private adminSrv: AdministradorService,
    private auth: AuthService,
    private enfermerasSrv: EnfermerasService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  
  }
  estatus(i: number): string{
    return S.status[i];
  }
  cancelar(){
    this.cancelarCanje.emit();
  }
  borrar(){
    let conf: boolean = confirm("estas seguro de borrar los datos");

    if(conf){
      this.enfermerasSrv
      .setRedimirDelete(this.enfermera.id, this.canje.id)
      .subscribe(
        res => {
          let r: Response = <Response>res;
          if(r.code){
           // let i: number = this.enfermera.canjesenfermera.find(canje => canje.id ==  this.canje.id);
            this.canje.status = 1;
            this.canje.fechaFinal = null;
            this.canje.observacion = null;
            this.canje.descripcion = null;
            
            this.canje.ruta = null;
            this.canje.media = null;
            this.canje.extension = null; 
            this.canje.filename = null; 
            this.canje.size = null;  
            
            this.cancelar();      
          }
          this.snackBar.open(r.message, 'Documento', {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });
          
        }, err => { console.log(err); }
      );

    }
    
  }
}
