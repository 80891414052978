import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import moment, { MomentInput } from 'moment';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Diagnostico } from 'src/app/moddelo/diagnostico';
import { Estado } from 'src/app/moddelo/estado';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { Fecha } from 'src/app/moddelo/fecha';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { Medico } from 'src/app/moddelo/medico';
import { Documento } from 'src/app/moddelo/documento';

interface identificadores{
  folio: string;
  serie: string;
}

@Component({
  selector: 'app-caso-clinico-ficha-nuevo',
  templateUrl: './caso-clinico-ficha-nuevo.component.html',
  styleUrls: ['./caso-clinico-ficha-nuevo.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class CasoClinicoFichaNuevoComponent implements OnInit {
  private idm: String = "0";
  private idcc: String = "0";
  public valoracion: String[] = ["", "Malo", "Regular", "Bueno", "Excelente"];
  public isEditable: boolean = true;
  

  public fichaFrm: FormGroup;
  public cirugiaFrm: FormGroup;
    
  public estados: Estado[] = [];
  public instituciones: Institucion[] = [];
  public hospitales: Hospital[] = [];
  public diagnosticos: Diagnostico[] = [];
  public antibioticos: Antibiotico [] = [];
  
  public nombresHospitales: string[] = [];
  public filtrosHospitales: Observable<string[]>;
  public fecha: Fecha = null;
  public maxFecha: Date;
  public minFecha: Date;

  constructor(
    private formBuilder: FormBuilder, 
    private activateRoute: ActivatedRoute, 
    private location: Location, 
    private casosclinicosSrv: CasosClinicosService, 
    private catalogosSrv: CatalogosService,
    private medicoSrv: MedicosService,
    private adminSrv: AdministradorService,
    private matSnakBar: MatSnackBar) { }
  
    get folioCtrl(){
      return this.fichaFrm.get('folio');
    }
    get serieCtrl(){
      return this.fichaFrm.get('serie');
    }
    get fechaCtrl(){
      return this.fichaFrm.get('fecha');
    }
    get sexoCtrl(){
      return this.fichaFrm.get('sexo');
    }
    get edadCtrl(){
      return this.fichaFrm.get('edad');
    } 
    get fk_estadoCtrl(){
      return this.fichaFrm.get('fk_estado');
    }
    get fk_institucionCtrl(){
      return this.fichaFrm.get('fk_institucion');
    }
    get otracveInstitucionCtrl(){
      return this.fichaFrm.get('otracveInstitucion');
    }
    get fk_hospitalCtrl(){
      return this.fichaFrm.get('fk_hospital');
    }
    get otrohospitalCtrl(){
      return this.fichaFrm.get('otrohospital');
    }
    get fk_diagnosticoCtrl(){
      return this.fichaFrm.get('fk_diagnostico');
    }
    get otrodiagnosticoCtrl(){
      return this.fichaFrm.get('otrodiagnostico');
    }  
  
    get fechaAplicacionProductoCtrl(){
      return this.cirugiaFrm.get('fechaAplicacionProducto'); 
    }  
    get fk_antibiotico1Ctrl(){
      return this.cirugiaFrm.get('fk_antibiotico1');
    }
    get cantidad1Ctrl(){
      return this.cirugiaFrm.get('cantidad1');
    }
    get fk_antibiotico2Ctrl(){
      return this.cirugiaFrm.get('fk_antibiotico2');
    }
    get cantidad2Ctrl(){
      return this.cirugiaFrm.get('cantidad2');
    }
    get fk_antibiotico3Ctrl(){
      return this.cirugiaFrm.get('fk_antibiotico3');
    }
    get cantidad3Ctrl(){
      return this.cirugiaFrm.get('cantidad3');
    }
    get medico(){
      return this.medicoSrv.medico;
    }
    get casoClinico(){
      return this.casosclinicosSrv.casoClinico;
    }
    get productos(){
      return this.casosclinicosSrv.casoClinico.productos;
    }
    get isCompletedCirugia(){
      let pro:  number = (this.productos ? this.productos.length : 0) 
      return (pro > 0 ? true : false ) && this.casoClinico.fechaAplicacionProducto != null;
    }
    
    get isCompletedBasico(){
      return (this.idcc != '0' ? true : false)
   }
  ngOnInit() {
    this.idm = this.activateRoute.snapshot.paramMap.get('idm');
    this.idcc = this.activateRoute.snapshot.paramMap.get('idcc');
    
    this.casosclinicosSrv.casoClinico = new CasoClinico();

    this.medicoSrv.medico = this.adminSrv.medicos.find( medico => medico.id == Number(this.idm));
    
    this.fichaFrm = this.formBuilder.group({
                                            folio:[''],
                                            serie:[''],
                                            paciente:[''],
                                            sexo:['', Validators.required],
                                            edad:['', Validators.required],
                                            fecha:['', Validators.required],
                                            fk_estado:['', Validators.required],
                                            fk_institucion:['', Validators.required],
                                            otracveInstitucion:[''],
                                            fk_hospital:['', Validators.required],
                                            otrohospital:[''],
                                            fk_diagnostico:['', Validators.required],
                                            otrodiagnostico:[''],
                                            antecedente:[''] 
                                        });
    
    this.cirugiaFrm = this.formBuilder.group({
                                              fk_antibiotico1:[''],                                              
                                              cantidad1:[''],
                                              fk_antibiotico2:[''],                                              
                                              cantidad2:[''],
                                              fk_antibiotico3:[''],                                              
                                              cantidad3:[''],
                                              tratamiento:[''],
                                              fechaAplicacionProducto:['', Validators.required],
                                              observacion:['']
                                          });

    if( this.idcc == '0' ) {

      this.casosclinicosSrv
      .getFolio(this.medico.id)
      .subscribe(data => {
        this.serieCtrl.setValue((<identificadores>data).serie);
        this.folioCtrl.setValue((<identificadores>data).folio);
      }, err => { console.log(err) });
      
    }else{      
      this.casosclinicosSrv
      .getCasoClinico(this.idcc)
      .subscribe( data => 
              { 
                const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, hospital, fk_institucion, fk_diagnostico, antecedente }  = data; 
                this.casosclinicosSrv.casoClinico = data; 
                
                
                this.fichaFrm.reset({ folio, serie,  paciente, sexo, edad, fecha, fk_estado, fk_institucion, fk_diagnostico, antecedente } );
                this.fichaFrm.get('fk_hospital').setValue(hospital);
                
               

                const { fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion } = data; 
                this.cirugiaFrm.reset({ fechaAplicacionProducto, tratamiento, fk_antibiotico1, cantidad1, fk_antibiotico2, cantidad2, fk_antibiotico3, cantidad3, observacion });
                
              
                
              }, err => { console.log(err) });  
      
    }
    this.casosclinicosSrv
    .getFechas(this.idcc)
    .subscribe(data => { 
                          this.fecha = data; 
                          this.maxFecha = this.fecha.fechaMax;
                          this.minFecha = this.fecha.fechaMin;
                         // let dia: number = (1000 * 60 * 60 * 24);                         
                        }, err => { console.log(err) });
    this.catalogosSrv
    .getEstados()
    .subscribe(data => {
                          this.estados = data;
                        }, err => { console.log(err) });
    this.catalogosSrv
    .getInstituciones()
    .subscribe(data => {
                          this.instituciones = data;
                          this.instituciones.push({id: 0, nomInstitucion: "OTRA", cvInstitucion: "OTRA", hospitales: []});
                        }, err => { console.log(err) });
                    
    this.catalogosSrv
    .getHospitales()
    .subscribe(data => {
                          this.hospitales = data;
                        }, err => { console.log(err) });

    this.catalogosSrv
    .getDiagnosticos()
    .subscribe(data => {
                          this.diagnosticos = data;
                          this.diagnosticos.push({id: 0, nomDiagnostico: "OTRO"});
                        }, err => { console.log(err) });
    
    this.catalogosSrv
    .getAntibioticos()
    .subscribe(data => { 
                        this.antibioticos = data; 
                      }, err => { console.log(err); });    
                        
    this.filtrosHospitales = this.fk_hospitalCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value))); 
  }
  
  onSubmitFicha(){

    if(this.idcc == '0'){
      this.guardarFicha();
    }else{
      this.actualizarFicha();
    }

  }
  onSubmitCirugia(){
    this.actualizarCirugia();
  }
  setFormatFicha(propiedad: boolean=false): CasoClinico {

    const { folio, serie,  paciente, sexo, edad, fecha, fk_estado, antecedente } = this.fichaFrm.value;
    let casoClinico: CasoClinico = <CasoClinico> { folio, serie, paciente, sexo, edad, fecha, fk_estado, antecedente };
    let diagnostico: Diagnostico = this.diagnosticoformal(this.fk_diagnosticoCtrl.value, this.otrodiagnosticoCtrl.value);
    let institucion: Institucion = this.institucionformal(this.fk_institucionCtrl.value, this.otracveInstitucionCtrl.value);
    let hospital: Hospital = this.hospitalformal(this.fk_hospitalCtrl.value, this.otrohospitalCtrl.value);
    
    if(propiedad){
      casoClinico.fk_medico = this.medico.id;
      casoClinico.fk_enfermera = this.medico.enfermeras.length == 1 ? this.medico.enfermeras[0].id : null;
    }
    

    casoClinico.fk_diagnostico = diagnostico.id;
    casoClinico.diagnostico = diagnostico.nomDiagnostico;

    casoClinico.fk_institucion = institucion.id;
    casoClinico.cveInstitucion = institucion.cvInstitucion;

    casoClinico.fk_hospital = hospital.id;
    casoClinico.hospital = hospital.nomHospital;

    //casoClinico.fechaRegistro = new Date();    
       
    return casoClinico;
  } 
  getFormatFichaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    const { id, folio, serie,  paciente, sexo, edad, fecha, fk_estado, antecedente,fk_medico, fk_diagnostico, diagnostico, fk_institucion, cveInstitucion, fk_hospital, hospital, fk_enfermera } = data;
    
    casoClinico.id = id;
    casoClinico.folio = folio;
    casoClinico.serie = serie;
    casoClinico.paciente = paciente;
    casoClinico.sexo = sexo;
    casoClinico.edad = edad;
    casoClinico.fecha = fecha;
    casoClinico.fk_estado = fk_estado;
    casoClinico.antecedente = antecedente;
    casoClinico.fk_medico = fk_medico;
    casoClinico.fk_diagnostico = fk_diagnostico;
    casoClinico.diagnostico = diagnostico;
    casoClinico.fk_institucion = fk_institucion;
    casoClinico.cveInstitucion = cveInstitucion;
    casoClinico.fk_hospital = fk_hospital;
    casoClinico.hospital = hospital;
    casoClinico.fk_enfermera = fk_enfermera;
    
    if(data.enfermera)
      casoClinico.enfermera = data.enfermera;  
  
  return casoClinico;
  }
  setFormatCirugia(): CasoClinico {
    const { tratamiento, fechaAplicacionProducto, observacion } =  this.cirugiaFrm.value;
      let casoClinico: CasoClinico = <CasoClinico>  { tratamiento, fechaAplicacionProducto, observacion }
      
      if(this.fk_antibiotico1Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico1Ctrl.value);
        casoClinico.fk_antibiotico1 = antibiotico.id;
        casoClinico.nomAntibiotico1 = antibiotico.nomAntibiotico;
        casoClinico.cantidad1 = this.cantidad1Ctrl.value;
      }
      if(this.fk_antibiotico2Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico2Ctrl.value);
        casoClinico.fk_antibiotico2 = antibiotico.id;
        casoClinico.nomAntibiotico2 = antibiotico.nomAntibiotico;
        casoClinico.cantidad2 = this.cantidad2Ctrl.value;
      }
      if(this.fk_antibiotico3Ctrl.value){
        let antibiotico: Antibiotico =  this.antibiotico(this.fk_antibiotico3Ctrl.value);
        casoClinico.fk_antibiotico3 = antibiotico.id;
        casoClinico.nomAntibiotico3 = antibiotico.nomAntibiotico;
        casoClinico.cantidad3 = this.cantidad3Ctrl.value;
      }

      return casoClinico;
  }
  getFormatCirugiaCCSRV(casoClinico: CasoClinico, data: CasoClinico): CasoClinico {
    const { tratamiento, fechaAplicacionProducto, observacion, fk_antibiotico1, nomAntibiotico1, cantidad1, fk_antibiotico2, nomAntibiotico2, cantidad2, fk_antibiotico3, nomAntibiotico3, cantidad3 } = data;
    
    casoClinico.tratamiento=tratamiento; 
		casoClinico.fechaAplicacionProducto=fechaAplicacionProducto; 
		casoClinico.observacion = observacion; 
		casoClinico.fk_antibiotico1 = fk_antibiotico1; 
		casoClinico.nomAntibiotico1 = nomAntibiotico1;			 
		casoClinico.cantidad1=cantidad1;
			 
		casoClinico.fk_antibiotico2 = fk_antibiotico2; 
		casoClinico.nomAntibiotico2 = nomAntibiotico2;			 
		casoClinico.cantidad2=cantidad2;
			 
		casoClinico.fk_antibiotico3 = fk_antibiotico3; 
		casoClinico.nomAntibiotico3 = nomAntibiotico3;			 
		casoClinico.cantidad3=cantidad3;

  

    return casoClinico;
  }
  guardarFicha(): void {

     this.casosclinicosSrv
    .setCasoClinico(this.setFormatFicha(true))
    .subscribe(data => { 
      let cc =this.getFormatFichaCCSRV(this.casoClinico, data);
      this.idcc = data.id.toString();
      
      let fechaRegistrada: Date = new Date(cc.fecha);
      this.maxFecha = new Date(fechaRegistrada.getTime() + ((1000 * 60 * 60 * 24) * 3));
      this.minFecha = fechaRegistrada;
      
      this.medico.casosclinicos.push(cc);

          
      this.matSnakBar.open(`Registrado con el No. ${data.serie}`, "Caso Clinico",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });

    }, err => { console.log(err) });

  }
  actualizarFicha(): void {
        
    
    this.casosclinicosSrv
    .setCasoClinicoUpdate(this.idcc, this.setFormatFicha())
    .subscribe(data => {
                            if(data){
                             
                              
                              let i: number = this.medico.casosclinicos.findIndex(cc => cc.id ==  Number(this.idcc));
                              this.medico.casosclinicos[i] = this.getFormatFichaCCSRV(this.casoClinico, data);
                            
                             
                              
                              this.matSnakBar.open(`Actualizado CC No. ${data.serie}`, "Caso Clinico", {
                                                                                                          duration: 2000,
                                                                                                          verticalPosition:'bottom',
                                                                                                          horizontalPosition:'center'
                                                                                                        });

                            }      

    }, err => { console.log(err) });

  }
  actualizarCirugia() {
    

      this.casosclinicosSrv
      .setCasoClinicoUpdate(this.idcc, this.setFormatCirugia())
      .subscribe(data => {

          if(data){
            let i: number = this.medico.casosclinicos.findIndex( cc => cc.id === Number(this.idcc) );
            
            this.getFormatCirugiaCCSRV(this.casoClinico, data);
            this.getFormatCirugiaCCSRV(this.medico.casosclinicos[i], data);            
            

            if(this.fecha.fechaAplicacionProducto == null){
              this.fecha.fechaAplicacionProducto = data.fechaAplicacionProducto;
              this.fecha.fechaPrimerSeguimiento = this.primerSeguimiento(data.fechaAplicacionProducto);
              this.fecha.dias = this.enDias(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
              this.fecha.activo = this.activo(this.fecha.fechaHoy, this.fecha.fechaPrimerSeguimiento);
            }
                       

            

            this.matSnakBar.open(`Actualizado`, "Caso Clinico", {
              duration: 2000,
              verticalPosition:'bottom',
              horizontalPosition:'center'
            });

          }

      }, err => { console.log(err) });
  }
  activo(fechaHoy: Date, primerSeguimiento: Date): boolean {
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);
    return seguimiento.getTime() > hoy.getTime();
  }
  enDias(fechaHoy: Date, primerSeguimiento: Date) {
    const hoy = new Date(fechaHoy);
    const seguimiento = new Date(primerSeguimiento);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = seguimiento.getTime() - hoy.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }  
  primerSeguimiento(aplicacionProducto: Date) {
    let fechaAplicacionProducto  = new Date(aplicacionProducto);
    return new Date(fechaAplicacionProducto.getTime() + (35 * ( (1000 * 60) * 60 * 24)) );
  }
  diagnosticoformal(id: number, otroDiagnostico: string = ""): Diagnostico {
      let diagnostico: Diagnostico;    
      diagnostico = this.diagnosticos.find( diagnostico => diagnostico.id == id);
     
      if(id == 0){
          diagnostico.nomDiagnostico = otroDiagnostico;
      }
      return  diagnostico;
  }
  institucionformal(id: number, otraInstitucion: string = ""): Institucion {
      let institucion: Institucion;
      institucion = this.instituciones.find(institucion => institucion.id == id);
      if(id == 0){
        institucion.cvInstitucion = otraInstitucion;
      }
      return institucion;
  }
  hospitalformal(nomHospital: string, otroHospital: string = ""): Hospital {
      let hospital: Hospital;

      hospital = this.hospitales.find(hospital => hospital.nomHospital.trim().toLowerCase() === nomHospital.trim().toLowerCase());
      
      if(!hospital){        
          
          hospital = new Hospital();
          hospital.id = 0;

          if(nomHospital.trim().toLowerCase() === "otro"){
            hospital.nomHospital = otroHospital;
          }else{
            hospital.nomHospital = "";
          }
                  
      }
      
      return hospital;
  }
  antibiotico(id: number): Antibiotico {
    let antibiotico: Antibiotico;    
    antibiotico = this.antibioticos.find( antibiotico => antibiotico.id == id);    
   return  antibiotico;
  }
  _filter(nombre: string): string[] {
    const valorFiltro = this._normalizeValue(nombre);      
    return this.nombresHospitales.filter(hospital => this._normalizeValue(hospital).includes(valorFiltro));

  }
  _normalizeValue(nomdelhospital: string): string {
   let nombre = String(nomdelhospital).toLowerCase();
    return nombre ? nombre.replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u') : '';
  }
  displayFn(hospital: Hospital): string {
    return hospital && hospital.nomHospital ? hospital.nomHospital : '';
  }
  onChangeSelectEdo(id_estado: string) {
    let id_institucion = this.fk_institucionCtrl.value;
    
    this.nombresHospitales = [];
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');   
       
    this.nombresHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)).map((h) => h.nomHospital); 
    
    if(id_institucion && id_estado){
      this.nombresHospitales.push("OTRO");  
    }
   
  }
  onChangeSelectInst(id_institucion: string) {
    let id_estado = this.fk_estadoCtrl.value;
   
    this.nombresHospitales = [];
    this.otracveInstitucionCtrl.setValue('');
    this.fk_hospitalCtrl.setValue('');
    this.otrohospitalCtrl.setValue('');
   
    this.nombresHospitales = this.hospitales.filter((hospital) => hospital.fk_institucion == (id_institucion ?  Number(id_institucion) : 0) && hospital.fk_estado == (id_estado ? Number(id_estado): 0)).map((h) => h.nomHospital); 
    
    if(id_institucion && id_estado){
      this.nombresHospitales.push("OTRO");  
    }  
   
  }
  goBack(): void {
    this.location.back();
  }

}
