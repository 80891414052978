import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Institucion } from 'src/app/moddelo/institucion';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AdministradorService } from 'src/app/servicios/administrador.service';

@Component({
  selector: 'app-institucion',
  templateUrl: './institucion.component.html',
  styleUrls: ['./institucion.component.css']
})
export class InstitucionComponent implements OnInit {
  public id: number = 0;
  public institucion: Institucion;

  public institucionFrm: FormGroup = new FormGroup({
    nomInstitucion: new FormControl('', Validators.required),
    cvInstitucion: new FormControl('', Validators.required) 
  });
  get nomInstitucionCtrl(){ return this.institucionFrm.get('nomInstitucion');}
  get cvInstitucionCtrl(){ return this.institucionFrm.get('cvInstitucion');} 
  
  constructor(
    private location: Location, 
    private activatedR: ActivatedRoute, 
    private catalogosSrv: CatalogosService, 
    private adminSrv: AdministradorService,
    private snackBar: MatSnackBar ) { }

  ngOnInit() {
    this.id = Number(this.activatedR.snapshot.paramMap.get('id'));

    if(this.id > 0){
     this.institucion = this.adminSrv.instituciones.find( i => i.id == this.id);
     let {nomInstitucion, cvInstitucion} = this.institucion;
     this.institucionFrm.setValue({nomInstitucion, cvInstitucion});    

     
    }
    
  }
  onSubmit(){

    if(this.id > 0){
          this.actualizar();
    }else{
          this.guadar();
    }

  }
  guadar(): void{
    this.institucion = new Institucion();
    this.institucion.nomInstitucion = this.institucionFrm.get('nomInstitucion').value;
    this.institucion.cvInstitucion = this.institucionFrm.get('cvInstitucion').value; 
    this.catalogosSrv
    .setInstitucion(this.institucion)
    .subscribe(data => {
                          if(data){
                              this.id=data.id;
                              this.institucion.id = data.id;
                              this.adminSrv.instituciones.push(this.institucion);
                              this.snackBar.open('Institucion registrada', 'Institucion', {
                              duration: 2000,
                              verticalPosition: 'bottom',
                              horizontalPosition: 'center'
                              });

                          } 
                           
                        
                      }, err => { console.log(err)});
  }
  actualizar(): void{
    let {nomInstitucion, cvInstitucion} = this.institucionFrm.value;
    let id = this.id;
    this.catalogosSrv
    .setInstitucionUpdate(this.id, <Institucion>{id, nomInstitucion, cvInstitucion})
    .subscribe(data => {
                          if(data){
                          
                          this.institucion.nomInstitucion = data.nomInstitucion;
                          this.institucion.cvInstitucion = data.cvInstitucion;
                          
                          this.snackBar.open('Institucion actualizada', 'Institucion', {
                            duration: 2000,
                            verticalPosition: 'bottom',
                            horizontalPosition: 'center'
                          });

                       }

    }, err => { console.log(err); });
  }
  goBack(): void{
    this.location.back();
  }

}
