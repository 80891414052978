import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, PageEvent, Sort } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Enfermera } from 'src/app/moddelo/Enfermera';
import { Medico } from 'src/app/moddelo/Medico';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { DialogoAsignarEnfermeraProductoPuntos } from '../../dialogos/dialogo-asignar-enfermera-producto-puntos/dialogo-asignar-enfermera-producto-puntos';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

@Component({
  selector: 'app-administrador-enfermera-r-puntos',
  templateUrl: './administrador-enfermera-r-puntos.component.html',
  styleUrls: ['./administrador-enfermera-r-puntos.component.css']
})
export class AdministradorEnfermeraRPuntosComponent implements OnInit {
  @Input('enfermera') enfermera: Enfermera;
  public productos: Producto[] = [];
  public productosLocal: Producto[] = [];
  public nombresMedicos: String[] = [];

  public filtroFrm: FormGroup;
  public filtroMedicos: Observable<String[]>;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  get usuario(){
    return this.aut.getUsuario;
  }
  
  get offLink(){
    return ( this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
  }
  
  constructor(private aut: AuthService, 
              private adminSrv: AdministradorService, 
              private activateRoute: ActivatedRoute,
              private formBuilder: FormBuilder, 
              private dialogo: MatDialog) { }

  get fechaInicialCtrl(){
        return this.filtroFrm.get('fechaInicial');
  }      
  get fechaFinalCtrl(){
        return this.filtroFrm.get('fechaFinal');
  }            
  get codigoCtrl() {
        return this.filtroFrm.get('codigo');
  }
  get productoCtrl() {
        return this.filtroFrm.get('producto');
  }
  get medicoCtrl() {
        return this.filtroFrm.get('medico');
  }
  get hospitalCtrl() {
        return this.filtroFrm.get('hospital');
  }
  get folioCtrl() {
        return this.filtroFrm.get('folio');
  }   
  
  ngOnInit() {
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            codigo:[''],
                                            producto:[''],
                                            medico:[''],      
                                            hospital: ['']
                                            });

    
    this.productosLocal = this.enfermera.productos.sort((a, b) => {let fechaa = new Date(a.fechaAplicacion); let fechab = new Date(b.fechaAplicacion); return fechab.getTime() - fechaa.getTime()});
    this.productos = this.productosLocal;

    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) );  
  }
  
  onSubmit(){   
    this.productos = this.productosLocal.filter(producto => 
      this.filterCodigo(producto.codigo) && 
      this.filterProducto(producto.nomProducto) && 
      this.filterMedico(producto.fk_medico) && 
      this.filterHospital(producto.fk_casoClinico) && 
      this.filterFolio(producto.fk_casoClinico) && 
      this.filterFecha(producto.fechaAplicacion));
    this.page_number = 1;
  }

  getMedico(id: string): string {
    let medico: Medico;
    let nombre: string = "";
    if(id) {
      medico = this.adminSrv.medicos.find(e => e.id.toString() == id);
      nombre = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
    }  
    return nombre;
  }

  getCasoClinico(fk_casoclinico: number){
    let folio: String = "";
    let casoClinico: CasoClinico;
    if(fk_casoclinico){
      casoClinico = this.enfermera.casosclinicos.find(cc => cc.id == fk_casoclinico);
      if(casoClinico){
        folio = casoClinico.folio;
      }
    }

    return folio;

  }

  getCasoClinicoHospital(fk_casoclinico: number){
    let hospital: String = "";
    let casoClinico: CasoClinico;
    if(fk_casoclinico){
      casoClinico = this.enfermera.casosclinicos.find(cc => cc.id == fk_casoclinico);
      if(casoClinico){
        hospital = casoClinico.hospital + "  " + casoClinico.cveInstitucion;
      }
    }

    return hospital;
  }

  filterFecha(fechaCirugia: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterCodigo(codigo: string): boolean {
    let condicion: boolean = true;
    let code: string = "";

    if(this.codigoCtrl.value != ""){
      code = this.codigoCtrl.value;
     condicion = ( codigo.toUpperCase().includes(code.toUpperCase()) );
    }
    
    return condicion;
  }

  filterProducto(nomProductos: string): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    
    if(this.productoCtrl.value != ""){
        nom = this.productoCtrl.value;
        if(nomProductos)
        condicion = (nomProductos.toUpperCase().includes(nom.toUpperCase()) );
    }
    
    return condicion;
  }

  filterMedico(fk_medico: number): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: String = "";

    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico){
          nomEnfermera = this.getMedico(fk_medico.toString());
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );
        }
    }
    
    return condicion;
  }

  filterHospital(fk_casoclinico:  number){
    let condicion: boolean = true;
    let nom: string = "";
    let nomHospital: String = "";

    if(this.hospitalCtrl.value != ""){
        nom = this._normalizeValue(this.hospitalCtrl.value);
        if(fk_casoclinico){
          nomHospital = this.getCasoClinicoHospital(fk_casoclinico);
          condicion = ( this._normalizeValue(nomHospital).includes(nom) );
        }
    }
    
    return condicion;

  }

  filterFolio(fk_casoclinico: number): boolean{
    let condicion: boolean = true;
    let f: String = "";
    let folio: String = "";

    if(this.folioCtrl.value != ""){
        f = this._normalizeValue(this.folioCtrl.value);
        if(f){
          folio = this.getCasoClinico(fk_casoclinico);
          condicion = (folio.toLowerCase().includes(f.toLowerCase()) );
        }
    }
        
    return condicion;
  }
  _filter(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));

  }
  _normalizeValue(nom: String): string{  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  sortEnfermeras(sort: Sort) {
    const datos: Producto[] = this.productos.slice();
    if(!sort.active || sort.direction === ''){
      this.productos = datos;
      return;
    }
    this.productos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let fechaA: number = new Date(a.fechaAplicacion).getTime();
          let fechaB: number = new Date(b.fechaAplicacion).getTime();
          return this.compare(fechaA, fechaB, isAsc);
        case 'folio':
           let casoA: string = a.casoclinico ? a.casoclinico.serie : '';
           let casoB: string = b.casoclinico ? b.casoclinico.serie : '';
          return this.compare(casoA, casoB, isAsc);
        case 'producto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'medico':
           let nombreA: string = a.medico ? a.medico.nombre.concat(' ').concat(a.medico.primerApellido.concat(a.medico.segundoApellido ? " ".concat(a.medico.segundoApellido): "")) : '';
           let nombreB: string = a.medico ? b.medico.nombre.concat(' ').concat(b.medico.primerApellido.concat(b.medico.segundoApellido ? " ".concat(b.medico.segundoApellido): "")) : '';
          return this.compare(nombreA, nombreB, isAsc);
        case 'hospital':
            let hospitalA: string = a.casoclinico ? a.casoclinico.hospital : '';
            let hospitalB: string = b.casoclinico ? b.casoclinico.hospital : '';
            return this.compare(hospitalA, hospitalB, isAsc);  
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'puntos':
          return this.compare(a.e_puntos, b.e_puntos, isAsc);
        default:
          return 0;
      }
    });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  setAsignarPuntos(prod: Producto){
    this.dialogo.open( DialogoAsignarEnfermeraProductoPuntos, { width: '800px', data: {producto: prod, enfermera: this.enfermera} });
  }
  generateData(): any[] {
    let result: any[] = this.productos.map((p, i) => ({ 
                                                        No: (i+1), 
                                                        Fecha: moment(p.fechaAplicacion).format('L'), 
                                                        Folio: p.fk_casoClinico ? p.casoclinico.serie : '',
                                                        Producto: p.nomProducto,
                                                        Lote: p.lote,
                                                        Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '',
                                                        Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                                                        Codigo: p.codigo,
                                                        Puntos: p.e_puntos,
                                                        Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido: ''                      
                                                      }) );

  return result;
  }
  exportTablaExcel(): void {
    let nombreEnfermera = [{campo: "Enfermera", nomEnfermera: this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '')}];
    let tProductos: any[] = this.productos.map((p, i) => ( { 
                              No:(i+1),
                              Fecha: moment(p.fechaAplicacion).format('L'),
                              NoCX: p.fk_casoClinico ? p.casoclinico.folio : '',  
                              Producto: p.nomProducto,
                              Lote: p.lote,
                              Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '', 
                              Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: '',
                              Codigo: p.codigo,
                              Puntos: p.e_puntos,
                              Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido: ''})
                              );

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreEnfermera, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tProductos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Enfermera_Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}e_productos.xlsx`);
  }
  exportTablaPDF(): void{
    const doc = new jsPDF('landscape');  
    let nombreEnfermera: string = this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: ''); 
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;

     doc.setFontSize(18); 
     doc.text("PRODUCTOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreEnfermera, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();

     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { Batch: { halign: 'center' } },
       body: rows,
       columns: [
         { header: 'No.', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'No. Folio', dataKey: 'Folio' },        
         { header: 'Producto', dataKey: 'Producto' },
         { header: 'Lote', dataKey: 'Lote' }, 
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },        
         { header: 'Codigo', dataKey: 'Codigo' },
         { header: 'Puntos', dataKey: 'Puntos' },
         { header: 'Medico', dataKey: 'Medico' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
     
     
     doc.save(`${new Date().toISOString()}_enfermeraproductos.pdf`);
  }
}
