import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource, Sort } from '@angular/material';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { Medico } from 'src/app/moddelo/Medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { DialogoMedicoEnfermeraAdd } from '../../dialogos/dialogo-medico-enfermera-add/dialogo-medico-enfermera-add';
import { DialogoMedicoEnfermeraRemove } from '../../dialogos/dialogo-medico-enfermera-remove/dialgo-medico-enfermera-remove';


@Component({
  selector: 'app-administrador-medico-r-enfermeras',
  templateUrl: './administrador-medico-r-enfermeras.component.html',
  styleUrls: ['./administrador-medico-r-enfermeras.component.css']
})
export class AdministradorMedicoREnfermerasComponent implements OnInit, AfterViewInit {
  public columnsToDisplay: String[] = ['nombre', 'correo', 'telefonoMovil', 'acciones'];
  public enfermeras: Enfermera[] = [];
  
  @Input('medico') medico: Medico;
  
  public dataSourceEnfermeras: MatTableDataSource<Enfermera> = new MatTableDataSource<Enfermera>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  

  public filtroFrm: FormGroup = new FormGroup({
    nomEnfermera: new FormControl('')
  });

  get enfermeraCtrl(){
    return this.filtroFrm.get('nomEnfermera');
  }

  get usuario(){
    return this.aut.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3; 
  }
  constructor(
              private aut: AuthService, 
              private adminSrv: AdministradorService,
              private _live: LiveAnnouncer, 
              private dialogo: MatDialog 
              ) { }

  ngAfterViewInit(): void{
    this.dataSourceEnfermeras.paginator = this.paginator;
    this.dataSourceEnfermeras.sort = this.sort;    
  }
  ngOnInit() {     
   this.dataSourceEnfermeras.data = this.medico.enfermeras;

   this.dataSourceEnfermeras.filterPredicate = (data: Enfermera, filter: string): boolean => {
        const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
          return (currentTerm + (data as { [key: string]: any })[key] + '◬');
        }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        return dataStr.indexOf(transformedFilter) != -1;
    }
  }
  announceSortChange(sortState: Sort){
    if(sortState.direction){
      this._live.announce(`Sorted ${sortState.direction}ending`);
    }else{
      this._live.announce('Shorting cleared');
    }
  }

  onSubmit(){}

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEnfermeras.filter = this.normalize(filterValue.toLowerCase());

    if(this.dataSourceEnfermeras.paginator){
      this.dataSourceEnfermeras.paginator.firstPage();
    }
  }
  normalize(caracteres: string): string{
    let datos: string = caracteres.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
    return datos;
  }

  addEnfermera(): void { 
   let dialogRef = this.dialogo.open(DialogoMedicoEnfermeraAdd, { width: '800px', data: this.medico });

   dialogRef.afterClosed().subscribe(r => {
    this.dataSourceEnfermeras.data = this.medico.enfermeras;
    
   });  
   
  }

  removeEnfermera(enfermera: Enfermera): void {

   let dialogRef = this.dialogo.open(DialogoMedicoEnfermeraRemove, {width: '800px', data: { enfermera: enfermera, medico: this.medico }});
   dialogRef.afterClosed().subscribe(r => {
      this.dataSourceEnfermeras.data = this.medico.enfermeras;    
   }); 
  }
}
