import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Supervisor } from '../moddelo/supervisor';
import { Enfermera } from '../moddelo/enfermera';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};
@Injectable({
  providedIn: 'root'
})
export class SupervisoresService {
  public supervisor: Supervisor;
  public card: number = 0;

  constructor(private http: HttpClient) { }
  
  getSupervisores(){
    return this.http.get<Supervisor[]>('/apibioc/public/api/supervisores', httpOptions);
  }

  getSupervisor(id: number){
    return this.http.get<Supervisor>(`/apibioc/public/api/supervisor/${id}`);
  }

  getTotales(id: number){
    return this.http.get(`/apibioc/public/api/supervisor/totales/${id}`, httpOptions);
  }
  
  setAsociarEnfemera(id: number, enfermera: Enfermera){
    return this.http.put(`/apibioc/public/api/supervisor/asociar/enfermera/${id}`, enfermera);
  }
  
  setDesasociarEnfermera(id: number, enfermera: Enfermera){
     return this.http.put(`/apibioc/public/api/supervisor/desasociar/enfermera/${id}`, enfermera);
  }
}
