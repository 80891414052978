import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginar'
})
export class PaginarPipe implements PipeTransform {

  transform(elementos: any[], pagina_size: number, pagina_number: number ): any[] {
    
    if(!elementos.length) return [];
    if(pagina_size==0) return elementos;
    
    pagina_size = pagina_size || 5;
    pagina_number = pagina_number || 1;
    --pagina_number;

    return elementos.slice(pagina_number * pagina_size, (pagina_number + 1) * pagina_size);
  }

}
