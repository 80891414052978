import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Producto } from 'src/app/moddelo/producto';
import { Enfermera } from 'src/app/moddelo/enfermera';
@Component({
  selector: 'app-enfermera-productos',
  templateUrl: './enfermera-productos.component.html',
  styleUrls: ['./enfermera-productos.component.css']
})
export class EnfermeraProductosComponent implements OnInit {
 public productos: Producto[] = [];
 public enfermera: Enfermera;

 public page_size: number = 10;
 public page_number: number = 1;
 public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private aut: AuthService, private enfermerasSrv: EnfermerasService) {
    this.enfermera = this.aut.getUsuario.enfermera;
   }

  ngOnInit() {
    this.enfermerasSrv.card = 3;
    this.enfermerasSrv
    .getProductos(this.enfermera.id)
    .subscribe( data => {this.productos = data;}, err => { console.log(err); });
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
