import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { element } from 'protractor';
import { Batch } from 'src/app/moddelo/batch';
import { Producto } from 'src/app/moddelo/producto';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { ProductosService } from 'src/app/servicios/productos.service';
interface BatchToBatch{
  code: number;
  affected: number;
}
@Component({
  selector: 'app-administrador-productos-asignados-sin-cambio-factura',
  templateUrl: './administrador-productos-asignados-sin-cambio-factura.component.html',
  styleUrls: ['./administrador-productos-asignados-sin-cambio-factura.component.css']
})
export class AdministradorProductosAsignadosSinCambioFacturaComponent implements OnInit {

  public batchsAsignados: Batch[] = [];
  public batchsNuevos: Batch[] = [];
  public productos: Producto[] = [];
  public message: String = "";

  public facturaFrm: FormGroup = new FormGroup({
          batch: new FormControl('', Validators.required),
          nofactura: new FormControl(''),
          batchN: new FormControl('', Validators.required),
          nofacturaN: new FormControl('')
  });
  get batchCtrl(){
    return this.facturaFrm.get('batch');
  }
  get nofacturaCtrl(){
    return this.facturaFrm.get('nofactura');
  }
  get batchNuevoCtrl(){
    return this.facturaFrm.get('batchN');
  }
  get nofacturaNuevoCtrl(){
    return this.facturaFrm.get('nofacturaN');
  }
  constructor(private location: Location, private productosSrv: ProductosService, private adminSrv: AdministradorService ) { }

  ngOnInit() {
    
    

  }
  onSubmit(frm: NgForm) {
    
       
    this.productosSrv
    .getBatchToBatch(this.batchCtrl.value, this.batchNuevoCtrl.value )
    .subscribe( data => { 
      let resp: BatchToBatch = <BatchToBatch> data;

                  if(resp.code == 1)
                  {                    
                    this.processProductos(resp.affected);
                    frm.reset();
                  }
    }, err => { console.log(err)  } );
  

  }
  processProductos(affected: number) {

    let btc_old: Batch = this.batchsAsignados.find( b => b.id == Number(this.batchCtrl.value));
    let btc_new: Batch = this.batchsNuevos.find( b => b.id == Number(this.batchNuevoCtrl.value));      
    /*
    this.productos.forEach( p => {
                                    if(p.batch == btc_old.idbatch)
                                    {
                                      p.batch = btc_new.idbatch;
                                      p.nofactura = btc_new.nofactura;
                                      p.fecha = btc_new.fecha;
                                      p.fk_institucion = btc_new.fk_institucion;
                                    }
    } );
    */
   /*
    btc_new.noafectado = affected;
    this.batchsAsignados.push(btc_new);

    let idx: number = this.batchsNuevos.findIndex( b => b.idbatch == this.batchNuevoCtrl.value);
    this.batchsNuevos.splice(idx);
    */
  }
  goBack() {
    this.location.back();
  }

}
