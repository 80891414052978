import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';

import { Producto } from 'src/app/moddelo/producto';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { ProductosService } from 'src/app/servicios/productos.service';

@Component({
  selector: 'app-administrador-productos-asignados-no',
  templateUrl: './administrador-productos-asignados-no.component.html',
  styleUrls: ['./administrador-productos-asignados-no.component.css']
})
export class AdministradorProductosAsignadosNoComponent implements OnInit {
  public pageEvent: PageEvent;
  public productos: Producto[] = [];
  public productosNoUtilizados: Producto[] = [];
  
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
                    codigo: new FormControl(''),
                    batch: new FormControl(''),
                    nofactura: new FormControl('')
  });

  get codigoCtrl(){
    return this.filtroFrm.get('codigo');
  }
  get nofacturaCtrl(){
    return this.filtroFrm.get('nofactura');
  }
  get nobatchCtrl(){
    return this.filtroFrm.get('batch')
  }
  get usuario()
  {
    return this.auth.getUsuario;
  }
  
  constructor (
                private auth: AuthService,
                private adminSrv: AdministradorService, 
                private productoSrv: ProductosService,
                private location: Location ) { }

  ngOnInit() 
  {
   this.productos = this.adminSrv.productos.filter(p => p.fk_casoClinico == null && p.status == 1).sort( (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime() );
   this.productosNoUtilizados = this.productos;
  }
  
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  } 
  
  filterCodigo(codigo: string): boolean{
    let condicion: boolean = true;
    if(this.codigoCtrl.value != ""){
     condicion = !(codigo.indexOf(this.codigoCtrl.value) == -1);
    }    
  return condicion;
  }

  filterFactura(factura: string): boolean{
    let condicion: boolean = true;
    if(this.nofacturaCtrl.value != ""){
      condicion = factura.includes(this.nofacturaCtrl.value);
    }    
  return condicion;
  }

  filterBatch(nobatch: number): boolean{
    let condicion: boolean = true;
    if(this.nobatchCtrl.value != ""){
      condicion = (nobatch == this.nobatchCtrl.value);
    }    
  return condicion;
  }
  
  onSubmit()
  {
    this.productosNoUtilizados = this.productos.filter( p => this.filterCodigo(p.codigo) && this.filterFactura(p.venta.noVenta_noReferencia) &&  this.filterBatch(p.noBatch) && p.fk_casoClinico == null && p.status == 1).sort( (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime() );
    this.page_number=1; 
  }
  sortProductos(sort: Sort ): void {
    const datos: Producto[] =  this.productosNoUtilizados.slice();
    if(!sort.active || sort.direction === '') {
      this.productosNoUtilizados = datos;
      return;
    }
    this.productosNoUtilizados =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'caducidad':
          let fechaA: number = new Date(a.caducidad).getTime();
          let fechaB: number = new Date(b.caducidad).getTime();
          return this.compare(fechaA, fechaB, isAsc);
        case 'nomproducto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'batch':
          return this.compare(a.noBatch, b.noBatch, isAsc);
        case 'venta':
          let ventaA: string = a.fk_venta ? a.venta.noVenta_noReferencia : '';
          let ventaB: string = b.fk_venta ? b.venta.noVenta_noReferencia : '';
          return this.compare(ventaA, ventaB, isAsc);  
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'lote':         
          return this.compare(a.lote, b.lote, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 
  goBack()
  {
    this.location.back();
  }

}
