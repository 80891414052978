import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { EnfermeraCanjeAddComponent } from './enfermera-canje-add/enfermera-canje-add.component';
import { EnfermeraCanjeDetalleComponent } from './enfermera-canje-detalle/enfermera-canje-detalle.component';
import { EnfermeraCanjeComponent } from './enfermera-canje/enfermera-canje.component';
import { EnfermeraCasosClinicosComponent } from './enfermera-casos-clinicos/enfermera-casos-clinicos.component';
import { EnfermeraMedicosComponent } from './enfermera-medicos/enfermera-medicos.component';
import { EnfermeraProductosComponent } from './enfermera-productos/enfermera-productos.component';
import { EnfermeraPuntosComponent } from './enfermera-puntos/enfermera-puntos.component';
import { EnfermeraSaldoComponent } from './enfermera-saldo/enfermera-saldo.component';
import { EnfermerasMainComponent } from './enfermeras-main/enfermeras-main.component';


const routes: Routes = [
  { path:'enfermeras', component: EnfermerasMainComponent,   children: [
    {path:'casosclinicos/:id', component:EnfermeraCasosClinicosComponent},
    {path:'medicos/:id', component: EnfermeraMedicosComponent},
    {path:'productos/:id', component: EnfermeraProductosComponent},
    {path:'puntos/:id', component: EnfermeraPuntosComponent},
    {path:'canjes/:id', component: EnfermeraCanjeComponent},
    {path:'saldo/:id', component: EnfermeraSaldoComponent},
    {path:'canje/add/:id', component: EnfermeraCanjeAddComponent},
    {path:'canje/detalle/:id', component: EnfermeraCanjeDetalleComponent}
  ], canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnfermerasRoutingModule { }
