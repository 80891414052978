import { CasoClinico } from "./caso-clinico";
import { Estado } from "./estado";
import { Institucion } from "./institucion";

export class Hospital {
        id: number;
        nomHospital: string;
        telefono: string;
        direccion: string;
        fk_institucion: number;
        fk_estado: number;
        cvInstitucion: string;
        institucion: Institucion;
        estado: Estado;
        casosclinicos: CasoClinico[];
}
