import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { PuntosProductosComponent } from './puntos-productos/puntos-productos.component';
import { PuntosSeguimientosComponent } from './puntos-seguimientos/puntos-seguimientos.component';
import { PuntosProductoComponent } from './puntos-producto/puntos-producto.component';
import { PuntosSeguimientoComponent } from './puntos-seguimiento/puntos-seguimiento.component';
import { PuntosRoutingModule } from './puntos-routing.module';



@NgModule({
  declarations: [PuntosProductosComponent, PuntosSeguimientosComponent, PuntosProductoComponent, PuntosSeguimientoComponent],
  imports: [
    CommonModule,
    PuntosRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule

  ]
})
export class PuntosModule { }
