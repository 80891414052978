import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Medico } from 'src/app/moddelo/medico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { RedimirMedicoReferencia } from 'src/app/moddelo/redimir-medico-referencia';

interface ActualizarCanje{
  code: number;
  message: string;
  data: RedimirMedico;
}

@Component({
  selector: 'app-administrador-medico-referencia-pago-remplazo',
  templateUrl: './administrador-medico-referencia-pago-remplazo.component.html',
  styleUrls: ['./administrador-medico-referencia-pago-remplazo.component.css']
})
export class AdministradorMedicoReferenciaPagoRemplazoComponent implements OnInit {
  
  @Input('medico') public medico: Medico;
  @Input('canje') public canje: RedimirMedico;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();
  
  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;
  public fileData: File;
  public canjeFrm = new FormGroup({ 
                                    archivo: new FormControl('', Validators.required)                                  
                                  });

  constructor(
    private activateRoute: ActivatedRoute, 
    private auth: AuthService,
    private medicosSrv: MedicosService,
    private adminSrv: AdministradorService,  
    private location: Location,
    private matSnackBar: MatSnackBar
    ) { }

  ngOnInit() {
  }
  onSubmit() {
    const formData = new FormData();
    
    //formData.append('id', this.canje.id.toString());
    //formData.append('fk_medico', this.canje.fk_medico.toString());  
    
    formData.append('file', this.fileData); 
    formData.append('fk_redimir', this.canje.id.toString());   
    
        this.medicosSrv
        .setRedimirAddRef(formData)
        .subscribe( events => {
                                  let r: ActualizarCanje;
                                  if(events.type === HttpEventType.UploadProgress){
  
                                    this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                    this.progress = Math.round( events.loaded / events.total * 100);                                   
                                    
                                  } else if( events.type === HttpEventType.Response){
                                      let referencia: RedimirMedicoReferencia = <RedimirMedicoReferencia> events.body;
                                      
                                      console.log("Registrado!!")
                                      console.log(events.body)
                                      
                                      this.canje.referencias.push(referencia); 

                                      this.matSnackBar
                                      .open("Registrado", "Canje", { duration: 3000, verticalPosition:'bottom', horizontalPosition:'center' });
                                    
                                  } else if(events.type === HttpEventType.ResponseHeader){
                                      console.log( events.ok ); 
                                  }
                              }, err => { console.log(err);}); 
  
  }
  fileSelect(inputfile: any) {
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;
    this.fileUploadProgress = "0%"; 
    this.progress = 0; 
  } 
  cancelar(): void {
    this.cancelarCanje.emit();
  }

}
