import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Medico } from 'src/app/moddelo/medico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { RedimirMedicoReferencia } from 'src/app/moddelo/redimir-medico-referencia';
import descripcion from '../../../datos/status';


interface Response{
  code: number; 
  message: string;
  data:   RedimirMedicoReferencia;
}
@Component({
  selector: 'app-administrador-medico-r-canje-detalle',
  templateUrl: './administrador-medico-r-canje-detalle.component.html',
  styleUrls: ['./administrador-medico-r-canje-detalle.component.css']
})
export class AdministradorMedicoRCanjeDetalleComponent implements OnInit {
  
  public observacion: FormControl = new FormControl('');
  @Input('medico') public medico: Medico;
  @Input('canje') public canje: RedimirMedico;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  
  constructor(    
    private auth: AuthService,
    private medicosSrv: MedicosService,
    private adminSrv: AdministradorService, 
    private matSnackBar: MatSnackBar 
  ) { }
 
  ngOnInit() {
  this.observacion.setValue(this.canje.observacion);
  }

  getDescripcion(): string{
    return descripcion.status[this.canje.status];
  }
  borrar(id: number): void{
    let conf: boolean = confirm("estas seguro de eliminar este registro");

    if(conf){
      this.medicosSrv.setRedimirDeleteRef(id).subscribe(res => {

        let r: Response = <Response> res;
        
        if(r.code){
          let i: number = this.canje.referencias.findIndex(doc => doc.id == id);
          this.canje.referencias.splice(i, 1);       
        }

        this.matSnackBar.open(r.message, 'Documento', {
          duration: 3000,
          verticalPosition:'bottom',
          horizontalPosition:'center'
        });

      }, err => { console.log(err) });

    }
  
  }
  actualizar(): void{
    console.log(this.canje.id, this.observacion.value);
    this.medicosSrv.setRedimirObservacionUpdate(this.canje.id, this.observacion.value).subscribe(data => {

      this.matSnackBar.open('Se actualizo la observacion', "CANJE",{
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });

      this.canje.descripcion = this.observacion.value;
    }, err => { console.log(err)});
  }
  cerrar(): void {
    this.cancelarCanje.emit();
  }

}
