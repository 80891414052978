import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';

import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

interface ItbCasosClinicos{
          No: string;
          Fecha: string;
          Folio: string;
          Paciente: string;
          Diagnostico: string;
          Hospital: string;
          Institucion: string;
          Estado: string;
          Medico: string;
          Productos: number;
        }

@Component({
  selector: 'app-administrador-enfermera-r-casos-clinicos',
  templateUrl: './administrador-enfermera-r-casos-clinicos.component.html',
  styleUrls: ['./administrador-enfermera-r-casos-clinicos.component.css']
})
export class AdministradorEnfermeraRCasosClinicosComponent implements OnInit {

  @Input('enfermera') enfermera: Enfermera;
  @Output() vistaCasoClinico: EventEmitter<CasoClinico> = new EventEmitter();
  
  public casosClinicos: CasoClinico[] = [];
  public filtroFrm: FormGroup;
  public filtroMedicos: Observable<String[]>;
  public nombresMedicos: String[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(    
    private aut: AuthService,
    private formBuilder: FormBuilder,    
    private adminSrv: AdministradorService) { }
  
  get usuario(){
    return this.aut.getUsuario;
  }

  get offCasosClinicos(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }

  get institucionCtrl(){
    return this.filtroFrm.get('institucion');
  }  

  get medicoCtrl(){
    return this.filtroFrm.get('medico');
  }

  ngOnInit() {
   
    this.casosClinicos = this.enfermera.casosclinicos.sort( (a, b) => { let a_fecha = new Date(a.fecha); let b_fecha = new Date(b.fecha); return b_fecha.getTime() - a_fecha.getTime()} );
   
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            paciente:[''],
                                            hospital: [''],
                                            medico:[''],                                               
                                            institucion:['']
                                          });

    this.nombresMedicos = this.adminSrv.medicos.map((e) => e.nombre.concat(' ').concat(e.primerApellido.concat(' ').concat(e.segundoApellido ? e.segundoApellido : '')) ); 
    this.filtroMedicos = this.medicoCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) ); 

  }
  onSubmit() {
    this.casosClinicos = this.enfermera.casosclinicos.filter( cc => 
      this.filterPaciente(cc.paciente) && 
      this.filterHospital(cc.hospital) && 
      this.filterInstitucion(cc.cveInstitucion) && 
      this.filterFolio(cc.folio) && 
      this.filterMedico(cc.fk_medico) && 
      this.filterFecha(cc.fecha.toString()));
    this.page_number = 1;
  }
  filterFecha(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterMedico(fk_medico: number): boolean {
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: String = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(fk_medico){
          nomMedico = this.getMedico(fk_medico.toString());
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );
          console.log(" nomEnf:", this._normalizeValue(nomMedico), "Nom:", nom, " Bolean:", this._normalizeValue(nomMedico).includes(nom) );
        }
    }
    
    return condicion;
  }
  filterInstitucion(cveInstitucion: string): boolean{
    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let cve: string;

    if( this.institucionCtrl.value != 0 ){
        i = this.institucionCtrl.value;
        institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
        cve = cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(cve){
        condicion = cve.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));      
      }else{
        condicion = false;
      }      
    }    
    
    return condicion;
  }
  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }
  getMedico(id: string): string {
    let medico: Medico;
    let nombre: string = "";
    if(id) {
      medico = this.adminSrv.medicos.find(e => e.id.toString() == id);
      nombre = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
    }  
    return nombre;
  }
  sortEnfermeras(sort: Sort) {
    const datos: CasoClinico[] = this.enfermera.casosclinicos.slice();
    if(!sort.active || sort.direction === ''){
      this.casosClinicos = datos;
      return;
    }
    this.casosClinicos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fecha).getTime();
          let b1: number = new Date(b.fecha).getTime();
          return this.compare(a1, b1, isAsc);
        case 'folio':
          return this.compare(a.serie, b.serie, isAsc);
        case 'paciente':
          return this.compare(a.paciente, b.paciente, isAsc);
        case 'hospital':
          return this.compare(a.hospital, b.hospital, isAsc);
        case 'cveInstitucion':
          return this.compare(a.cveInstitucion, b.cveInstitucion, isAsc);
        case 'medico':
          return this.compare((a.fk_medico ? a.medico.nombre + ' ' +( a.medico.primerApellido ? a.medico.primerApellido: '') : ''), (b.fk_medico ? b.medico.nombre + ' ' +( b.medico.primerApellido ? b.medico.primerApellido : ''): ''), isAsc);
        case 'productos':
            let productosA: number = a.productos ? a.productos.length : 0;
            let productosB: number = b.productos ? b.productos.length : 0;
            return this.compare(productosA, productosB, isAsc);  
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  _filter(nombre: String): String[] {    
    const valorFiltro = this._normalizeValue(nombre);
    return this.nombresMedicos.filter(medico => this._normalizeValue(medico).includes(valorFiltro));

  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  }
  generateData(): any[] {
    let result: any[] = this.casosClinicos.map((cc, i) =>      
                 ( {
                    No: (i+1).toString(),   
                    Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                    Folio: cc.serie,
                    Paciente: cc.paciente, 
                    Diagnostico: cc.diagnostico,
                    Hospital: cc.hospital,
                    Institucion: cc.cveInstitucion,
                    Estado: cc.fk_estado ? cc.estado.nomEstado : '',                      
                    Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido : '',
                    Productos: cc.productos ? cc.productos.length : 0 
                  })
  );

    return result;
  }
  exportTablaExcel(): void {
    let nombreEnfermera = [{campo: "Enfermera", nomEnfermera: this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '')}];
    let tCasosClinicos: ItbCasosClinicos[] = this.casosClinicos.map((cc, i) => {
      return <ItbCasosClinicos> { 
                              No: (i+1).toString(),   
                              Fecha: cc.fecha ? moment(cc.fecha).format('L') : '', 
                              Folio: cc.folio,
                              Paciente: cc.paciente,
                              Diagnostico: cc.diagnostico,
                              Hospital: cc.hospital,
                              Institucion: cc.cveInstitucion,
                              Estado: cc.fk_estado ? cc.estado.nomEstado : '',  
                              Medico: cc.fk_medico ? cc.medico.nombre + ' ' + cc.medico.primerApellido: '',
                              Productos: cc.productos ? cc.productos.length : 0 
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, nombreEnfermera, { origin:"A2", skipHeader: true });
    XLSX.utils.sheet_add_json(ws, tCasosClinicos, { origin:"A4" });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'enfermera_casos_clinicos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}enfermeracasosclinicos.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
    let nombresEnfermera: string = this.enfermera.nombre + ' ' + this.enfermera.primerApellido + (this.enfermera.primerApellido ? ' ' + this.enfermera.primerApellido: '');  
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     doc.setFontSize(18); 
     doc.text("CASOS CLINICOS",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombresEnfermera, 14, 15);
     doc.setTextColor(100);
     
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha', dataKey: 'Fecha' },
         { header: 'Folio', dataKey: 'Folio' },
         { header: 'Paciente', dataKey: 'Paciente' },
         { header: 'Diagnostico', dataKey: 'Diagnostico' },
         { header: 'Hospital', dataKey: 'Hospital' },
         { header: 'Institucion', dataKey: 'Institucion' },
         { header: 'Estado', dataKey: 'Estado' },           
         { header: 'Medico', dataKey: 'Medico' },
         { header: 'Productos', dataKey: 'Productos' },
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_medico_cc.pdf`);
  }
  setVistaCasoClinico(casoclinico: CasoClinico): void{
    console.log(casoclinico)
    this.vistaCasoClinico.emit(casoclinico);
  }
  s
}
