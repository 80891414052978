import { CasoClinico } from "./caso-clinico";
import { Medico } from "./medico";
import { Producto } from "./producto";
import { RedimirEnfermera } from "./redimir-enfermera";
import { Supervisor } from "./supervisor";
import { Usuario } from "./usuario";

export class Enfermera {
        id : number;
        nombre: string;
        primerApellido: string;
        segundoApellido: string;
        correo: string;
        telefonoMovil: string;        
        fk_usuario: number;
        created_at: Date;
        updated_at: Date;
        puntos: number;
        fk_puntos: number;
        fk_supervisor: number;
        medicos: Medico[];
        productos: Producto[];
        casosclinicos: CasoClinico[];
        redimirenfermera: RedimirEnfermera[]
        usuario: Usuario;
        supervisor: Supervisor;
}