import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent, Sort } from '@angular/material';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { ProductosService } from 'src/app/servicios/productos.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';

import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { Institucion } from 'src/app/moddelo/institucion';
import { Hospital } from 'src/app/moddelo/hospital';
import { Acumulado } from 'src/app/moddelo/acumulado';

import { img } from 'src/app/datos/content-img';

@Component({
  selector: 'app-administrador-productos-asignados',
  templateUrl: './administrador-productos-asignados.component.html',
  styleUrls: ['./administrador-productos-asignados.component.css']
})
export class AdministradorProductosAsignadosComponent implements OnInit {
  public filtrosHospitales: Observable<Hospital[]>;
  
  public productosAsignados: Producto[] = [];
  public medicos: Medico[] = [];
  public enfermeras: Enfermera[] = [];

  public instituciones: Institucion[] = [];
  public hospitales: Hospital[] = [];
  public acumulados: Acumulado[] = [];
  
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public productos: Producto []=[];
  
  public filtroFrm: FormGroup = new FormGroup({
                                              medico: new FormControl(''),
                                              enfermera: new FormControl(''),
                                              hospital: new FormControl(''),
                                              institucion: new FormControl(''),
                                              codigo: new FormControl(''),
                                              fechaInicial: new FormControl(''),
                                              fechaFinal: new FormControl(''),
                                              noCx: new FormControl(''),
                                              folio: new FormControl(''),
                                              paciente: new FormControl(''),
                                              diagnostico: new FormControl('')
                                            });

  get enfermeraCtrl(){
    return this.filtroFrm.get('enfermera');
  }
  get medicoCtrl(){
    return this.filtroFrm.get('medico');
  }
  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }
  get institucionCtrl(){
    return this.filtroFrm.get('institucion');
  }
  get codigoCtrl(){
    return this.filtroFrm.get('codigo');
  }
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }
  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }
  get diagnosticoCtrl(){
    return this.filtroFrm.get('diagnostico');
  }  
  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }
  get noCxCtrl(){
    return this.filtroFrm.get('noCx');
  }
  constructor(               
              private location: Location,
              private adminSrv: AdministradorService,
              private catalogosSrv: CatalogosService,
              private productosSrv: ProductosService,
              private medicosSrv: MedicosService,
              private enfermerasSrv: EnfermerasService              
              ) { }

  ngOnInit() {
    /*  
      this.catalogosSrv.getHospitales().subscribe(data => {
        this.hospitales = data.sort((a, b) => a.nomHospital.localeCompare(b.nomHospital));
        let hospital: Hospital = new Hospital();
        hospital.id=0;
        this.hospitales.unshift(hospital);
      }, err => { console.log(err) });
      
      this.catalogosSrv.getInstituciones().subscribe(data => {
        this.instituciones = data.sort((a, b) => a.nomInstitucion.localeCompare(b.nomInstitucion));
        let institucion: Institucion = new Institucion()
        institucion.id = 0;
        this.instituciones.unshift(institucion);
      }, err => { console.log(err) } );
      
      this.medicosSrv.getMedicos().subscribe(datos => {
        this.medicos = datos.sort((a,b) =>  a.nombre.localeCompare(b.nombre));
        let medico: Medico = new Medico();
        medico.id = 0;
        this.medicos.unshift(medico);
      }, err => { console.log(err) });
    */
      this.productos = this.adminSrv.productos.filter(p => p.fk_casoClinico);
      this.productosAsignados = this.productos.sort((a, b) => { 
                                                                let a_fecha = new Date(a.fechaAplicacion); 
                                                                let b_fecha = new Date(b.fechaAplicacion); 
                                                                return b_fecha.getTime() - a_fecha.getTime();
                                                              });

     
         
      this.enfermerasSrv.getEnfermeras().subscribe( data => {
                                                              this.enfermeras = data.sort((a, b) => a.nombre.localeCompare(b.nombre));
                                                              let enfermera: Enfermera = new Enfermera();
                                                              enfermera.id = 0; 
                                                              this.enfermeras.unshift(enfermera);
                                                            }, err => { console.log(err) }); 
     
      //this.id_institucionCtrl.valueChanges.subscribe(data => { console.log(data); }, err => { console.log(err); });
      
      //this.filtrosHospitales = this.id_institucionCtrl.valueChanges.pipe( startWith(''), map(value => this._filter(value)) );   
               
  }

  filterFechaCirugia(fechaCirugia: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  filterEnfermera(enfermera: Enfermera): boolean{
    let condicion: boolean = true;
    let nom: string = "";
    let nomEnfermera: string = "";
 
    if(this.enfermeraCtrl.value != ""){
        nom = this._normalizeValue(this.enfermeraCtrl.value);
        if(enfermera){
          nomEnfermera = enfermera ? (enfermera.nombre + ( enfermera.primerApellido ? ' ' + enfermera.primerApellido : '' ) + (enfermera.segundoApellido ? ' ' + enfermera.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomEnfermera).includes(nom) );          
        }else {
          condicion = false;
        }
    }    
    return condicion;
  }
  
  filterMedico(medico: Medico): boolean{
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: string = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(medico) {
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );          
        }
    }    
    return condicion;
  }

  filterNoCx(noCx: string): boolean{
    let condicion: boolean = true;

      if(this.noCxCtrl.value != ""){
        condicion = !( noCx.indexOf(this.noCxCtrl.value) == -1 );
      } 

    return condicion; 
  }

  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
        condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  filterPaciente(casoclinico: CasoClinico): boolean{
    
    let condicion: boolean = true;
    let paciente: string;
    let p: string;
    let nombre: string;
    if( this.pacienteCtrl.value != 0 ){
      p = this.pacienteCtrl.value;
      paciente = p.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico) {
        nombre = casoclinico.paciente ? casoclinico.paciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;   
  }
  filterDiagnostico(casoclinico: CasoClinico): boolean{
    
    let condicion: boolean = true;
    let diagnostico: string;
    let d: string;
    let nomDiagnostico: string;
    if( this.diagnosticoCtrl.value != 0 ){
      d = this.diagnosticoCtrl.value;
      diagnostico = d.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico) {
        nomDiagnostico = casoclinico.diagnostico ? casoclinico.diagnostico.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nomDiagnostico.replace(/ +/g,'').includes(diagnostico.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;   
  }

  filterHospital(casoclinico: CasoClinico): boolean{
    
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.hospital ? casoclinico.hospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;   
  }

  filterInstitucion(casoclinico: CasoClinico): boolean {    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let nombre: string;
    if( this.institucionCtrl.value != 0 ) {
      i = this.institucionCtrl.value;
      institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.cveInstitucion ? casoclinico.cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }        
    return condicion;   
  }

  filterCodigo(codigo: string): boolean{
    let condicion: boolean = true;
    let code: string = "";
    if(this.codigoCtrl.value != ""){
      code = this.codigoCtrl.value;
     condicion = (codigo.toUpperCase() === code.toUpperCase());
    }
    
  return condicion;
  }
  
  onSubmit() { 

    this.productosAsignados = this.productos.filter(producto => 
      this.filterHospital(producto.casoclinico) && 
      this.filterInstitucion(producto.casoclinico) && 
      this.filterPaciente(producto.casoclinico) &&
      this.filterFolio(producto.casoclinico.serie)  &&
      this.filterNoCx(producto.casoclinico.folio) &&
      this.filterMedico(producto.medico) && 
      this.filterEnfermera(producto.enfermera) && 
      this.filterFechaCirugia(producto.fechaAplicacion.toString()) && 
      this.filterDiagnostico(producto.casoclinico) &&
      this.filterCodigo(producto.codigo)).sort((a, b) => new Date(a.fechaAplicacion).getTime() - new Date(b.fechaAplicacion).getTime());    
    this.page_number = 1;
  
  }
  
  generateData(): any[] {
    let result = [];
    let i: number = 0;

    this.productosAsignados.forEach((p, i) => {
        result.push(         
        { 
        no: (i+1).toString(),
        serie: p.casoclinico.serie, 
        folio: p.casoclinico.folio,
        fecha: moment(p.fechaAplicacion).format('L'),
        codigo: p.codigo,
        producto: p.nomProducto,
        hospital: p.casoclinico.hospital,
        institucion: p.casoclinico.cveInstitucion,
        paciente: p.casoclinico.paciente,
        diagnostico: p.casoclinico.diagnostico,
        nomMedico: `${p.medico.nombre} ${p.medico.primerApellido}`,
        nomEnfermera: p.fk_enfermera?`${p.enfermera.nombre} ${p.enfermera.primerApellido}` : 'Sin Asignar'
      });
    });

    return result;
  }

  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

  createHeaders() {
   let keys: string[] = [
                          "No",
                          "Folio",
                          "NoCx",
                          "Fecha",
                          "Codigo",
                          "Producto",
                          "Hospital",
                          "Institucion",
                          "Paciente",
                          "Diagnostico",
                          "Medico",
                          "Enfermera"
                        ];

    let result: any[]= [];

    result = <any[]> keys.map(key => (<any> {
                                              id: key,
                                              name: key,
                                              prompt: key,
                                              width: "70",
                                              align: "center",
                                              padding: "0"
                                            }));

    
    return result;
  }
  exportTablaPDF():  void {
    const doc = new jsPDF('landscape');  
     
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     
     
     doc.text(" PRODUCTOS UTILIZADOS ",  145, 10, { align: "center"});
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       columnStyles: { no: { halign: 'right' }, productos: { halign: 'right' },  casosclinicos: { halign: 'right'}, canjes:{ halign: 'right'}, enfermeras: {halign: 'right'},  puntos: {halign: 'right'}, saldo: {halign: 'right'} },
       body: rows,
       columns: [
         { header: 'No', dataKey: 'no' },
         { header: 'Folio', dataKey: 'serie' },
         { header: 'NoCx', dataKey: 'folio' },
         { header: 'Fecha', dataKey: 'fecha' },
         { header: 'codigo', dataKey: 'codigo' },
         { header: 'Producto', dataKey: 'producto' },
         { header: 'Hospital', dataKey: 'hospital' },
         { header: 'Institucion', dataKey: 'institucion' },
         { header: 'Paciente', dataKey: 'paciente' },
         { header: 'Diagnostico', dataKey: 'diagnostico' },  
         { header: 'Medico', dataKey: 'nomMedico' }, 
         { header: 'Enfermera', dataKey: 'nomEnfermera' }, 
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_productosg.pdf`);
  }

  exportTablaExcel(): void {

    let tProductos: any[] =  this.productosAsignados.map((p, i) => {
                                                                  return  { 
                                                                            no: (i+1).toString(),
                                                                            serie: p.casoclinico.serie, 
                                                                            folio: p.casoclinico.folio,
                                                                            fecha: moment(p.fechaAplicacion).format('L'),
                                                                            codigo: p.codigo,
                                                                            producto: p.nomProducto,
                                                                            hospital: p.casoclinico.hospital,
                                                                            institucion: p.casoclinico.cveInstitucion,
                                                                            paciente: p.casoclinico.paciente,
                                                                            diagnostico: p.casoclinico.diagnostico,
                                                                            nomMedico: `${p.medico.nombre} ${p.medico.primerApellido}`,
                                                                            puntosMedico: p.m_puntos,
                                                                            nomEnfermera: p.fk_enfermera?`${p.enfermera.nombre} ${p.enfermera.primerApellido}` : 'Sin Asignar',
                                                                            puntosEnfermera: p.fk_enfermera? p.e_puntos : 0
                                                                          };
                          });
  

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tProductos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_productosg.xlsx`)
  }
  
  _filter(id: number): Hospital[] {     
    return this.hospitales.filter(hospital => hospital.fk_institucion == id);
  }
  
  _normalizeValue(nomdelhospital: string): string {
   let nombre = String(nomdelhospital).toLowerCase();
    return nombre ? nombre.replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u') : '';
  }

  sortProductos(sort: Sort  ): void {
    const datos: Producto[] =  this.productosAsignados.slice();
    if(!sort.active || sort.direction === '') {
      this.productosAsignados = datos;
      return;
    }
    this.productosAsignados =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let fechaA: number = new Date(a.fechaAplicacion).getTime();
          let fechaB: number = new Date(b.fechaAplicacion).getTime();
          return this.compare(fechaA, fechaB, isAsc);
        case 'nomproducto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'medico':
          let medicoA: string = a.fk_medico ? a.medico.nombre + ( a.medico.primerApellido ? ' ' + a.medico.primerApellido: '') + ( a.medico.segundoApellido ? ' ' + a.medico.segundoApellido: '') : '';
          let medicoB: string = b.fk_medico ? b.medico.nombre + ( b.medico.primerApellido ? ' ' + b.medico.primerApellido: '') + ( b.medico.segundoApellido ? ' ' + b.medico.segundoApellido: '') : '';
          return this.compare(medicoA, medicoB, isAsc);
        case 'enfermera':
          let enfermeraA: string = a.fk_enfermera ? a.enfermera.nombre + ( a.enfermera.primerApellido ? ' ' + a.enfermera.primerApellido: '') + ( a.enfermera.segundoApellido ? ' ' + a.enfermera.segundoApellido: '') : '';
          let enfermeraB: string = b.fk_enfermera ? b.enfermera.nombre + ( b.enfermera.primerApellido ? ' ' + b.enfermera.primerApellido: '') + ( b.enfermera.segundoApellido ? ' ' + b.enfermera.segundoApellido: '') : '';
         return this.compare(enfermeraA, enfermeraB, isAsc);  
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'folio':
          let folioA: string = a.fk_casoClinico ? a.casoclinico.serie : '';
          let folioB: string = b.fk_casoClinico ? b.casoclinico.serie : '';
          return this.compare(folioA, folioB, isAsc);
        case 'nocx':
          let noCxA: string = a.fk_casoClinico ? a.casoclinico.folio : '';
          let noCxB: string = b.fk_casoClinico ? b.casoclinico.folio : '';
          return this.compare(noCxA, noCxB, isAsc);  
        case 'pmedico':
          let puntosMedicoA: number = a.m_puntos ? a.m_puntos : 0;
          let puntosMedicoB: number = b.m_puntos ? b.m_puntos : 0;
          return this.compare(puntosMedicoA, puntosMedicoB, isAsc);
        case 'penfermera':
          let puntosEnfermeraA: number = a.e_puntos ? a.e_puntos : 0;
          let puntosEnfermeraB: number = b.e_puntos ? b.e_puntos : 0;
          return this.compare(puntosEnfermeraA, puntosEnfermeraB, isAsc);  
        case 'hospital':
          let hospitalA: string = a.fk_casoClinico ? a.casoclinico.hospital : '';
          let hospitalB: string = b.fk_casoClinico ? b.casoclinico.hospital : '';
          return this.compare(hospitalA, hospitalB, isAsc);
        case 'paciente':
          let pacienteA: string = a.fk_casoClinico ? a.casoclinico.paciente : '';
          let pacienteB: string = b.fk_casoClinico ? b.casoclinico.paciente : '';
          return this.compare(pacienteA, pacienteB, isAsc);
        case 'diagnostico':
            let diagnosticoA: string = a.fk_casoClinico ? a.casoclinico.diagnostico : '';
            let diagnosticoB: string = b.fk_casoClinico ? b.casoclinico.diagnostico : '';
            return this.compare(diagnosticoA, diagnosticoB, isAsc);      
        case 'institucion':
          let institucionA: string = a.fk_casoClinico ? a.casoclinico.cveInstitucion: ''; 
          let institucionB: string = b.fk_casoClinico ? b.casoclinico.cveInstitucion : ''; 
          return this.compare(institucionA, institucionB, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  } 
  goBack(): void {
    this.location.back();
  }

}
