import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Acumulado } from 'src/app/moddelo/acumulado';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { ProductosService } from 'src/app/servicios/productos.service';
interface CasoProductos{
  code: number;
  message: string;
  data: Producto[];
}
@Component({
  selector: 'app-casos-clinicos-codigo',
  templateUrl: './casos-clinicos-codigo.component.html',
  styleUrls: ['./casos-clinicos-codigo.component.css']
})
export class CasosClinicosCodigoComponent implements OnInit {
  private catalogoProductos: Producto[] = [];
  public productosCache: Producto[] = [];

  public productoFrm: FormGroup = new FormGroup({
          codigo: new FormControl('', Validators.required)
  });

  get codigoCtrl(){
    return this.productoFrm.get('codigo');
  }
  
  get productos(){
    return this.casosClinicosSrv.casoClinico.productos;
  }  
  get casoClinico(){
    return this.casosClinicosSrv.casoClinico;
  }
  get medico(){
    return this.medicosSrv.medico;
  }
  constructor(  private casosClinicosSrv: CasosClinicosService, 
                private productosSrv: ProductosService, 
                private medicosSrv: MedicosService,
                private matSnakBar: MatSnackBar) { }

  ngOnInit() {
              this.productosSrv.getProductos().subscribe(data => { this.catalogoProductos = data; }, err => { console.log(err); });    
  }
  onSubmit(frm: NgForm) {
    let codigo: string = this.codigoCtrl.value;    
    let productodel: Producto = this.catalogoProductos.find( producto => producto.codigo.trim().toUpperCase() === codigo.trim().toUpperCase());
    if(productodel)
    {
          console.log(productodel);
          
          if(productodel.fk_casoClinico != null)
          {

              this.matSnakBar.open(`Codigo asignado anteriormente`, "Codigo", {
                duration: 2000,
                verticalPosition:'bottom',
                horizontalPosition:'center'
              });

          }
          else if(productodel.status === 0)
          {

              this.matSnakBar.open(`Codigo eliminado`, "Codigo", {
                duration: 2000,
                verticalPosition:'bottom',
                horizontalPosition:'center'
              });
              
          }
          else
          {
                       
              //id_producto: productodel.id, 
              productodel.fk_casoClinico = this.casoClinico.id;
              productodel.fk_medico = this.medico.id; 
              productodel.m_puntos = this.medico.puntos;
              productodel.fechaAplicacion = this.casoClinico.fechaAplicacionProducto;

              if(this.casoClinico.enfermera)
              {
                productodel.e_puntos = this.casoClinico.enfermera.puntos;
                productodel.fk_enfermera = this.casoClinico.enfermera.id;
              }                                     
             
              this.productosCache.push(productodel);
              
              this.codigoCtrl.clearValidators();
              frm.reset();
              this.codigoCtrl.setValidators( Validators.required );
             
          }
          
    }
    else 
    {      
      this.matSnakBar.open(`Codigo no registrado en el sistema`, "Codigo", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
      });
    }
  }

  onGuardar(): void {
  this.productosSrv
  .setProductosCaso(this.productosCache)
  .subscribe(data => {
                        let casoClinico: CasoClinico = data;
                        
                        this.medico.productos.push(...casoClinico.productos);
                        this.casoClinico.productos.push(...casoClinico.productos);

                       this.matSnakBar.open("Asignado(s) con exito", "Codigo", {
                          duration: 2000,
                          verticalPosition:'bottom',
                          horizontalPosition:'center'
                        });
                            
                      }, 
                      err => { console.log(err); } );
  
  }

  delete( i: number): void {
    this.productosCache.splice(i, 1);   
  }

}
