import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Producto } from '../moddelo/producto';
import { Acumulado } from '../moddelo/acumulado';
import { Batch } from '../moddelo/batch';
import { Venta } from '../moddelo/venta';
import { CasoClinico } from '../moddelo/caso-clinico';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Authorization': 'proyecto-cc'
  }),
};
@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }
  getProductos(){
    return this.http.get<Producto[]>('/apibioc/public/api/productos', httpOptions);
  }
  
  setProductoDelete(id: string) {
    return this.http.delete(`/apibioc/public/api/producto/${id}`, httpOptions);
  }

  setProductosCaso(productos: Producto[]) {
    return this.http.post<CasoClinico>('/apibioc/public/api/productos/caso', productos, httpOptions);
  }
  
  getProductosAsigandos() {
    return this.http.get<Acumulado[]>('/apibioc/public/api/productos/asignados', httpOptions);
  }
  
  setProductoEliminar(id: string){
    return this.http.delete(`/apibioc/public/api/producto/eliminar/${id}`, httpOptions);
  }

  setProductoCancelar(id: string, idm: string){
    return this.http.delete(`/apibioc/public/api/producto/cancelar/${id}/${idm}`, httpOptions);
  }
  setProductoCancelarCambio(id1: string, id2: string){
    return this.http.delete(`/apibioc/public/api/producto/cancelar/cambio/${id1}/${id2}`, httpOptions);
  }
  
  setSubirArchivo(formData: FormData) {
    return this.http.post('/apibioc/public/api/productos/uplote', formData, { reportProgress: true, observe: 'events' });
  }


  /* BATCH */
  setBatch(batch: Batch) {
    return this.http.post<Batch>('/apibioc/public/api/batch', batch, httpOptions);
  }

  getBatchs() {
    return this.http.get<Batch[]>('/apibioc/public/api/batchs', httpOptions);
  }

  getBatchAsignados() {
    return this.http.get<Batch[]>('/apibioc/public/api/batch/in/productos', httpOptions);
  }
  getBatchSinAsignados() {
    return this.http.get<Batch[]>('/apibioc/public/api/batch/notin/productos', httpOptions);
  }
  getBatchToBatch(ido: number, idn: number){
    return this.http.get(`/apibioc/public/api/batch/to/batch/${ido}/${idn}`);
  }
  /* VENTAS */
  getVentas() {
    return this.http.get<Venta[]>('/apibioc/public/api/ventas', httpOptions);
  }
  getVenta(id: string){
    return this.http.get<Venta>(`/apibioc/public/api/venta/${id}`, httpOptions);
  }
  setVentaDelete(id: string) {
    return this.http.delete<Venta>(`/apibioc/public/api/venta/${id}`, httpOptions);
  }
  setVentaCancelar(id: string) {
    return this.http.delete<Venta>(`/apibioc/public/api/venta/cancelar/${id}`, httpOptions);
  }

  
}
