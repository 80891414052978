import { Component, OnInit } from '@angular/core';
import { SupervisoresService } from 'src/app/servicios/supervisores.service';
import { AuthService} from 'src/app/servicios/auth.service';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Producto } from 'src/app/moddelo/producto';

interface Resumen {
  casosClinicos: CasoClinico[],
  productos: Producto[]
}
@Component({
  selector: 'app-supervisores-main',
  templateUrl: './supervisores-main.component.html',
  styleUrls: ['./supervisores-main.component.css']
})
export class SupervisoresMainComponent implements OnInit {
  public id: number = 0;
  public supervisor: Supervisor = new Supervisor();
  public resumen: Resumen = null;
  constructor(private aut: AuthService, public supervisorSrv: SupervisoresService) { }

  ngOnInit() {

    this.id = this.aut.getUsuario.supervisor.id;
    this.supervisorSrv.getSupervisor(this.id).subscribe(data => { this.supervisor = data; }, err => { console.log(err)});
    this.supervisorSrv.getTotales(this.id).subscribe(data => { this.resumen = <Resumen>data; }, err => { console.log(err); });
    
  }

}
