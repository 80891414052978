import { Component, OnInit } from '@angular/core';
import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Especialidad } from 'src/app/moddelo/especialidad';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material';
import { AuthService } from 'src/app/servicios/auth.service';

@Component({
  selector: 'app-especialidades',
  templateUrl: './especialidades.component.html',
  styleUrls: ['./especialidades.component.css']
})
export class EspecialidadesComponent implements OnInit {
  public especialidades: Especialidad[] = [];
  public pageEvent: PageEvent;

  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return (this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0);
  }

  constructor(
              private auth: AuthService,
              private location: Location, 
              private catalogosSrv: CatalogosService
              ) { }

  ngOnInit() {
    this.catalogosSrv
    .getEspecialidades()
    .subscribe(data => { this.especialidades = data; }, err => { console.log(err); } );
  }
  pagina(x: number, pageEvent: PageEvent): boolean{
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }
  goBack(): void{
    this.location.back();
  }

}
