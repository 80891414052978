import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { Medico } from 'src/app/moddelo/medico';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import descripcion from '../../../datos/status';


@Component({
  selector: 'app-medico-canje',
  templateUrl: './medico-canje.component.html',
  styleUrls: ['./medico-canje.component.css']
})
export class MedicoCanjeComponent implements OnInit {
  public canjes: RedimirMedico[] = [];
  public medico: Medico;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private medicoSrv: MedicosService, private aut: AuthService) { 
    this.medico = this.aut.getUsuario.medico;
  }
  get url(){    
    return window.location.origin.replace(':4200', '');
  }
  getDescripcion(id: number): string{
   return descripcion.status[id];
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  ngOnInit() {
    this.medicoSrv.card = 4;
    this.canjes = this.medicoSrv.medico.redimirmedico.sort((a, b) => { let a_fecha = new Date(a.fechaInicio); 
      let b_fecha = new Date(b.fechaInicio); 
      return b_fecha.getTime() - a_fecha.getTime()
    });

    //this.medicoSrv.getCanjes(this.medico.id).subscribe(data => {this.canjes = data;}, err => {console.log(err)});
  
  }

}
