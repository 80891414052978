import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CasoClinico} from '../moddelo/caso-clinico'
import { Medico } from '../moddelo/medico';
import { Producto } from '../moddelo/producto';
import { Saldo } from '../moddelo/saldo';
import { ProductoCaso } from '../moddelo/producto-caso';
import { Totales} from '../moddelo/totales';


import { MedicoPerfil } from '../moddelo/medicoperfil';
import { Enfermerapuntos } from '../moddelo/enfermeraspuntos';
import { Login } from '../moddelo/login';
import { TotalesMedico } from '../moddelo/totales-medico';
import { RedimirMedico } from '../moddelo/redimir-medico';

import { MedicoEnfermera} from '../moddelo/medico-enfermera';
import { MedicoToEnfermera } from '../moddelo/medico-to-enfermera';
import { Enfermera } from '../moddelo/enfermera';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};
@Injectable({
  providedIn: 'root'
})
export class MedicosService {
  public medico: Medico;
  public card: number = 0;
  constructor(private http: HttpClient) { }
  
  getMedicos(){
    return this.http.get<Medico[]>('/apibioc/public/api/medicos', httpOptions);
  }

  getMedico(id: string){
    return this.http.get<Medico>(`/apibioc/public/api/medico/${id}`, httpOptions);
  }
  
  getTotales(id: number){
    return this.http.get<Totales>(`/apibioc/public/api/medico/totales/${id}`, httpOptions);
  }
  getCasosClinicos(id: number){
    return this.http.get<CasoClinico[]>(`/apibioc/public/api/medico/casosclinicos/${id}`, httpOptions);
  }
  getProductos(id: number){
    return this.http.get<Producto[]>( `/apibioc/public/api/medico/productos/${id}`, httpOptions);
  }
  getPuntos(id: number){
    return this.http.get<ProductoCaso[]>(`/apibioc/public/api/medico/puntos/${id}`, httpOptions);
  } 
  getSaldos(id: number){
    return this.http.get<Saldo[]>(`/apibioc/public/api/medico/saldos/${id}`, httpOptions);
  }
  setAsociarEnfemera(id: number, enfermera: Enfermera){
    return this.http.put(`/apibioc/public/api/medico/asociar/enfermera/${id}`, enfermera);
  }
  setDesasociarEnfermera(id: number, enfermera: Enfermera){
    return this.http.put(`/apibioc/public/api/medico/desasociar/enfermera/${id}`, enfermera);
  }
  setRedimirObservacionUpdate(id: number, observacion: string){
    return this.http.put(`/apibioc/public/api/medico/redimir/observacion/${id}`, { observacion: observacion});
  }
  setRedimirCreate(canje: RedimirMedico){
    return this.http.post<RedimirMedico>('/apibioc/public/api/medico/redimir/create', canje);
  }
  setRedimirUpdate(redimir: FormData){
    return this.http.post(`/apibioc/public/api/medico/redimir/update`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirUpdateRef(redimir: FormData){
    return this.http.post(`/apibioc/public/api/medico/redimir/update/ref`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirAddRef(redimir: FormData){
    return this.http.post(`/apibioc/public/api/medico/redimir/add/ref`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirDeleteRef(id: number){
    return this.http.delete(`/apibioc/public/api/medico/redimir/delete/ref/${id}`);
  }
  setRedimirUpdateRechazado(canje: RedimirMedico){
    return this.http.post<RedimirMedico>('/apibioc/public/api/medico/redimir/rechazado', canje);
  }
  setRedimirDelete(idM:number, idC:number){
    return this.http.delete(`/apibioc/public/api/medico/redimir/eliminar/${idM}/${idC}`);
  } 
  
}
