import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { UsuarioEleccionComponent } from './usuario-eleccion/usuario-eleccion.component';
import { UsuarioEnfermeraCreateComponent } from './usuario-enfermera-create/usuario-enfermera-create.component';
import { UsuarioEnfermeraUpdateComponent } from './usuario-enfermera-update/usuario-enfermera-update.component';
import { UsuarioLoginSignupComponent } from './usuario-login-signup/usuario-login-signup.component';
import { UsuarioMedicoCreateComponent } from './usuario-medico-create/usuario-medico-create.component';
import { UsuarioMedicoUpdateComponent } from './usuario-medico-update/usuario-medico-update.component';
import { UsuarioPerfilComponent } from './usuario-perfil/usuario-perfil.component';
import { UsuarioRecoverPswComponent } from './usuario-recover-psw/usuario-recover-psw.component';
import { UsuarioUpdateComponent } from './usuario-update/usuario-update.component';


const routes: Routes = [
  { path:'usuario/perfil', component: UsuarioPerfilComponent, canActivate: [AuthGuard]},
  { path:'login', component: UsuarioLoginSignupComponent},
  { path:'recuperar', component: UsuarioRecoverPswComponent},
  { path:'usuario/medico', component:UsuarioMedicoCreateComponent},
  { path:'usuario/medico/update', component:UsuarioMedicoUpdateComponent, canActivate: [AuthGuard]},
  { path:'eleccion', component: UsuarioEleccionComponent},
  { path:'usuario/enfermera', component: UsuarioEnfermeraCreateComponent},
  { path:'usuario/enfermera/update', component: UsuarioEnfermeraUpdateComponent, canActivate: [AuthGuard]},
  { path:'usuario/update', component: UsuarioUpdateComponent, canActivate: [AuthGuard]},
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuariosRoutingModule { }
