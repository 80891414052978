import { RedimirMedicoReferencia } from "./redimir-medico-referencia";

export class RedimirMedico 
{
    id: number;	
	fk_medico: number;
	redimir: number; 
	created_at: Date;
	updated_at: Date;
	concepto: string; 
	descripcion: string;
	status: number; 
	fechaInicio: Date;
	fechaFinal: Date;
	observacion: string;
	
	ruta: string; 			
	media: string; 			
	extension: string; 		
	filename: string; 		 
	size: number;

	referencias: RedimirMedicoReferencia[];
}
