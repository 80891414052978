import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Antibiotico } from 'src/app/moddelo/antibiotico';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-antibiotico',
  templateUrl: './antibiotico.component.html',
  styleUrls: ['./antibiotico.component.css']
})
export class AntibioticoComponent implements OnInit {
  public id: number = 0;
  public antibiotico: Antibiotico = new Antibiotico();

  public antibioticoFrm: FormGroup = new FormGroup({
          nomAntibiotico: new FormControl('', Validators.required)  
        });

  get nomAntibioticoCtrl(){
    return this.antibioticoFrm.get('nomAntibiotico');
  }
  constructor(
              private snackBar: MatSnackBar, 
              private location: Location, 
              private activated: ActivatedRoute, 
              private catalogosSrv: CatalogosService  
            ) { }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id'));

    if(this.id > 0){
      this.catalogosSrv
      .getAntibiotico(this.id)
      .subscribe(data => { 
        this.antibiotico = data;
        this.nomAntibioticoCtrl.setValue(this.antibiotico.nomAntibiotico); 
      }, err => { console.log(err); } );
    }
  }
  onSubmit(){
    if(this.id>0){
      this.actualizar();
    }else{
      this.guardar();
    }
  }
  actualizar(): void{
    this.antibiotico.nomAntibiotico = this.nomAntibioticoCtrl.value;
    this.antibiotico.id = this.id;
    this.catalogosSrv
    .setAntibioticoUpdate(this.id, this.antibiotico)
    .subscribe(data => { 

      this.snackBar.open('Antibiotico actualizado', 'antibiotico', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });
    }, err => { console.log(err) });
  }
  guardar(): void{
    this.antibiotico.nomAntibiotico = this.nomAntibioticoCtrl.value;
    this.antibiotico.id = this.id;

    this.catalogosSrv
    .setAntibiotico(this.antibiotico)
    .subscribe(data => {
      this.id = data.id;
      this.antibiotico.nomAntibiotico = data.nomAntibiotico;
      this.antibiotico.id = data.id;

      this.snackBar.open('Antibiotico registrado', 'antibiotico', {
        duration: 2000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
        });

    }, err => { console.log(err)});
  }
  goBack(): void{
    this.location.back();
  }
}
