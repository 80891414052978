import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';

interface RemoveEnfermera{
  code: number;
  message: string;
  data: Enfermera;
}

@Component({
  selector: 'app-administrador-medico-r-enfermera-remove',
  templateUrl: './administrador-medico-r-enfermera-remove.component.html',
  styleUrls: ['./administrador-medico-r-enfermera-remove.component.css']
})
export class AdministradorMedicoREnfermeraRemoveComponent implements OnInit {
  public medico: Medico = null;
  public enfermera: Enfermera = null;
  public respuesta: RemoveEnfermera;

  get usuario(){
    return this.auth.getUsuario;
  }
  constructor(
              private location: Location,
              private activeRoute: ActivatedRoute, 
              private adminSrv: AdministradorService,
              private medicosSrv: MedicosService,              
              private snackBar: MatSnackBar,
              private auth: AuthService
              ) { }

  ngOnInit() {
    let id_medico: number = Number(this.activeRoute.snapshot.paramMap.get('id1'));
    let id_enfermera: number = Number(this.activeRoute.snapshot.paramMap.get('id2'));
    this.medico = this.adminSrv.medicos.find(medico => medico.id == id_medico);
    this.enfermera = this.medico.enfermeras.find(enfermera => enfermera.id == id_enfermera);

  }
  remove(): void{
    if(confirm("¿Estas seguro?")){

     this.medicosSrv
     .setDesasociarEnfermera(this.medico.id, this.enfermera)
     .subscribe(data => {
                          let i: number = 0;
                          let e: Enfermera[] = null;
                          this.respuesta = <RemoveEnfermera> data;
                          if(this.respuesta.code){
                            i = this.medico.enfermeras.findIndex(e => e.id == this.enfermera.id); 
                            e = this.medico.enfermeras.splice(i, 1);
                            this.snackBar.open(this.respuesta.message, 
                              'Enfermera', 
                              {
                                duration: 3000,
                                verticalPosition:'bottom',
                                horizontalPosition:'center'
                              });
                              console.log("Removido: ", e[0].nombre, ' ' , e[0].primerApellido );
                              this.goBack(); 
                          }else{
                                  this.snackBar.open(this.respuesta.message, 
                                    'Medico', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                          }
     }, err => { console.log(err); });
    }
  }
  goBack(): void{
   return this.location.back();
  }
}
