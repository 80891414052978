import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';

@Component({
  selector: 'app-usuario-enfermera-update',
  templateUrl: './usuario-enfermera-update.component.html',
  styleUrls: ['./usuario-enfermera-update.component.css']
})
export class UsuarioEnfermeraUpdateComponent implements OnInit {
  private usuario: Usuario = null;

  public usuarioEnfermeraFrm = new FormGroup({                               
                                                alias: new FormControl('', Validators.required),
                                                nombre: new FormControl('', Validators.required),
                                                primerApellido: new FormControl('', Validators.required),
                                                segundoApellido: new FormControl(''),
                                                telefonoMovil: new FormControl('', Validators.required)                                
                                              });

  get aliasCtrl(){ return this.usuarioEnfermeraFrm.get('alias');}
  get nombreCtrl(){ return this.usuarioEnfermeraFrm.get('nombre');}
  get primerApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('primerApellido');}
  get segundoApellidoCtrl(){ return this.usuarioEnfermeraFrm.get('segundoApellido');}
  get telefonoCtrl(){ return this.usuarioEnfermeraFrm.get('telefonoMovil');}
  
  get Authorizacion(){
    return this.auth.getUsuario;
  }
  get offBoton(){
    return (this.Authorizacion.fk_nivel == 3 || this.Authorizacion.fk_nivel == 2 || this.Authorizacion.fk_nivel == 0);
  }
  constructor(
              private location: Location, 
              private usuariosSrv: UsuariosService,
              private snackBar: MatSnackBar,
              private activated: ActivatedRoute,
              private adminSrv: AdministradorService,
              private auth: AuthService
  ) { }

  ngOnInit() {
    let id_usuario = Number(this.activated.snapshot.paramMap.get('id'));

    this.usuariosSrv
    .getUsuario(id_usuario)
    .subscribe(data => {
      this.usuario = data; 
      
      let alias = this.usuario.alias;
      let { nombre, primerApellido, segundoApellido, telefonoMovil } = this.usuario.enfermera;
      
      this.usuarioEnfermeraFrm.setValue({ alias, nombre, primerApellido, segundoApellido, telefonoMovil });
      

    }, err => { console.log(err); });


  }
  onSubmit() {
    let { alias, nombre, primerApellido, segundoApellido, telefonoMovil } = this.usuarioEnfermeraFrm.value;
    let usuarioU: Usuario = <Usuario> { alias, nombre, primerApellido, segundoApellido, telefonoMovil };
    let enfermeraU: Enfermera = <Enfermera> { nombre, primerApellido, segundoApellido, telefonoMovil };
    let id = this.usuario.id;
    
    this.usuariosSrv
    .setUsuarioEnfermeraUpdate( id, usuarioU, enfermeraU)
    .subscribe(data => {
        
      this.snackBar.open(`Registrado actualizado`, "Enfermera", {
        duration: 2000,
        verticalPosition:'bottom',
        horizontalPosition:'center'
       });     
      
      this.usuario.alias = usuarioU.alias;
      this.usuario.nombre = usuarioU.nombre;
      this.usuario.primerApellido = usuarioU.primerApellido;
      this.usuario.segundoApellido = usuarioU.segundoApellido;
      this.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.usuario.enfermera.nombre = enfermeraU.nombre;
      this.usuario.enfermera.primerApellido = enfermeraU.primerApellido;
      this.usuario.enfermera.segundoApellido = enfermeraU.segundoApellido;
      this.usuario.enfermera.telefonoMovil = enfermeraU.telefonoMovil;

      let enfermera: Enfermera = this.adminSrv.enfermeras.find(enfermera => enfermera.fk_usuario == this.usuario.id);
      enfermera.nombre = enfermeraU.nombre;
      enfermera.primerApellido = enfermeraU.primerApellido;
      enfermera.segundoApellido = enfermeraU.segundoApellido;
           
      enfermera.telefonoMovil = enfermeraU.telefonoMovil;
      
      enfermera.usuario.alias = usuarioU.alias;
      enfermera.usuario.nombre = usuarioU.nombre;
      enfermera.usuario.primerApellido = usuarioU.primerApellido;
      enfermera.usuario.segundoApellido = usuarioU.segundoApellido;
      enfermera.usuario.telefonoMovil = usuarioU.telefonoMovil;
      
      this.goBack();

    }, err =>{ console.log(err); });
   
  }
  goBack(){
    this.location.back();
  }
}
