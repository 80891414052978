import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Enfermera } from 'src/app/moddelo/enfermera';
import { Totales } from 'src/app/moddelo/totales';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { ProductoCaso } from 'src/app/moddelo/producto-caso';
import { Producto } from 'src/app/moddelo/producto';
import { Saldo } from 'src/app/moddelo/saldo';

import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { Medico } from '../moddelo/medico';

;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};
 
@Injectable({
  providedIn: 'root'
})
export class EnfermerasService {
  public enfermera: Enfermera;
  public card: number = 0;
  constructor(private http: HttpClient) { }
  
  getEnfermeras(){
    return this.http.get<Enfermera[]>('/apibioc/public/api/enfermeras', httpOptions);
  }
  getEnfermera(id: string){
    return this.http.get<Enfermera>(`/apibioc/public/api/enfermera/${id}`, httpOptions);
  }
  getTotales(id: number){
    return this.http.get<Totales>( `/apibioc/public/api/enfermera/totales/${id}`, httpOptions);
  }
  getCasosClinicos(id: number){
    return this.http.get<CasoClinico[]>(`/apibioc/public/api/enfermera/casosclinicos/${id}`, httpOptions);
  }
  getProductos(id: number){
    return this.http.get<Producto[]>( `/apibioc/public/api/enfermera/productos/${id}`, httpOptions);
  }
  getPuntos(id: number){
    return this.http.get<ProductoCaso[]>( `/apibioc/public/api/enfermera/puntos/${id}`, httpOptions);
  }
  
  getSaldos(id: number){
    return this.http.get<Saldo[]>(`/apibioc/public/api/enfermera/saldos/${id}`, httpOptions);
  }
 
  
  setAsociarMedico(id: number, medico: Medico){
    return this.http.put(`/apibioc/public/api/enfermera/asociar/medico/${id}`, medico);
  }
  setDesasociarMedico(id: number, medico: Medico){
    return this.http.put(`/apibioc/public/api/enfermera/desasociar/medico/${id}`, medico);
  }
  setRedimirCreate(canje: RedimirEnfermera){
    return this.http.post<RedimirEnfermera>('/apibioc/public/api/enfermera/redimir/create', canje);
  }
  setRedimirUpdate(redimir: FormData){
    return this.http.post(`/apibioc/public/api/enfermera/redimir/update`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirObservacionUpdate(id: number, observacion: string){
    return this.http.put(`/apibioc/public/api/enfermera/redimir/observacion/${id}`, { observacion: observacion});
  }
  setRedimirUpdateRef(redimir: FormData){
    return this.http.post(`/apibioc/public/api/enfermera/redimir/update/ref`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirAddRef(redimir: FormData){
    return this.http.post(`/apibioc/public/api/enfermera/redimir/add/ref`, redimir, {reportProgress: true, observe: 'events'});
  }
  setRedimirDeleteRef(id: number){
    return this.http.delete(`/apibioc/public/api/enfermera/redimir/delete/ref/${id}`);
  }
  setRedimirUpdateRechazado(canje: RedimirEnfermera){
    return this.http.post<RedimirEnfermera>('/apibioc/public/api/enfermera/redimir/rechazado', canje);
  }
  setRedimirDelete(idE:number, idC:number){
    return this.http.delete(`/apibioc/public/api/enfermera/redimir/eliminar/${idE}/${idC}`);
  }

  

}
