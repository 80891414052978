import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';
import { RedimirEnfermeraReferencia } from 'src/app/moddelo/redimir-enfermera-referencia';

interface ActualizarCanje{
  code: number;
  message: string;
  data: RedimirEnfermera;
}

@Component({
  selector: 'app-administrador-enfermera-referencia-pago-remplazo',
  templateUrl: './administrador-enfermera-referencia-pago-remplazo.component.html',
  styleUrls: ['./administrador-enfermera-referencia-pago-remplazo.component.css']
})
export class AdministradorEnfermeraReferenciaPagoRemplazoComponent implements OnInit {

  @Input('enfermera') public enfermera: Enfermera;
  @Input('canje') public canje: RedimirEnfermera;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;
  public fileData: File;
  
  public canjeFrm = new FormGroup({ 
                                    archivo: new FormControl('', Validators.required)                                  
                                  });
  constructor(
    private auth: AuthService,
    private enfermerasSrv: EnfermerasService,
    private adminSrv: AdministradorService, 
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {    
  }
  onSubmit() {
    const formData = new FormData();
      
    formData.append('file', this.fileData); 
    formData.append('fk_redimir', this.canje.id.toString());
    
    this.enfermerasSrv
    .setRedimirAddRef(formData)
    .subscribe( events => {
                                  let r: ActualizarCanje;
                                  if(events.type === HttpEventType.UploadProgress){
  
                                    this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                    this.progress = Math.round( events.loaded / events.total * 100);                                   
                                    
                                  } else if( events.type === HttpEventType.Response) {

                                    let referencia: RedimirEnfermeraReferencia = <RedimirEnfermeraReferencia> events.body;
                                      
                                    console.log("Registrado!!");
                                    console.log(events.body);
                                    
                                    this.canje.referencias.push(referencia); 

                                    this.matSnackBar
                                    .open("Registrado", "Canje", { duration: 3000, verticalPosition:'bottom', horizontalPosition:'center' });

                                    
                                  } else if(events.type === HttpEventType.ResponseHeader){
                                      console.log( events.ok ); 
                                  }
                                }, err => {console.log(err);}); 
  
  }
  fileSelect(inputfile: any) {
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;
  } 
  cancelar(): void {
    this.cancelarCanje.emit();
  }

}
