import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material';
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';
import { Medico } from 'src/app/moddelo/medico';

import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment  from 'moment/moment';

import S from '../../../datos/status';
import { Usuario } from 'src/app/moddelo/usuario';

@Component({
  selector: 'app-administrador-medico-r-canjes',
  templateUrl: './administrador-medico-r-canjes.component.html',
  styleUrls: ['./administrador-medico-r-canjes.component.css']
})
export class AdministradorMedicoRCanjesComponent implements OnInit { 
  public canjes: RedimirMedico[] = [];
  
  @Input('medico') medico: Medico;
  @Output('solicitudCanje') solicitudCanje: EventEmitter<RedimirMedico> = new EventEmitter<RedimirMedico>();
  @Output('deleteCanje') deleteCanje: EventEmitter<RedimirMedico> = new EventEmitter<RedimirMedico>();
  @Output('nuevoCanje') nuevoCanje: EventEmitter<any> = new EventEmitter<any>();
  @Output('referenciaCanje') referenciaCanje: EventEmitter<RedimirMedico> = new EventEmitter<RedimirMedico>();
  @Output('detalleCanje') detalleCanje: EventEmitter<RedimirMedico> = new EventEmitter<RedimirMedico>();

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  get url(): string{    
    return window.location.origin.replace(':4200', '');
  }
  
  get saldo(): number{
    let puntos: number = this.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
    let canjesAplicados: number = this.canjes.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
    return  puntos - canjesAplicados;
  }
  get usuario(): Usuario{
    return this.auth.getUsuario;
  }
  get offCanjes(): boolean {
    return (this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3);
  }
  get borrar(): boolean {
    return (this.usuario.fk_nivel == 3 || this.usuario.fk_nivel == 0);
  }
  constructor(
    private auth: AuthService, 
    private adminSrv: AdministradorService,
  ) { }

  ngOnInit() {
      
    
    this.canjes = this.medico.redimirmedico.sort((a, b) => { 
                                                      let a_fecha = new Date(a.fechaInicio); 
                                                      let b_fecha = new Date(b.fechaInicio); 
                                                      return b_fecha.getTime() - a_fecha.getTime()
                                                    });
    
  }  
  
  estatus(i: number): String{
    return S.status[i];
  }
  
  getProcesar(s: number): Boolean{
    return s == 1;
  }

  getAceptado(s: number): Boolean{
    return s == 3;
  }

  getAtender(s: number): Boolean{
    return (this.getProcesar(s) && (this.usuario.fk_nivel==3 || this.usuario.fk_nivel==2 || this.usuario.fk_nivel==0));
  }
  setSolicitudCanje(canje: RedimirMedico): void {
    this.solicitudCanje.emit(canje);
  }
  setDeleteCanje(canje: RedimirMedico): void {
    this.deleteCanje.emit(canje);
  }
  setNuevoCanje(): void {
    this.nuevoCanje.emit();
  }
  setReferenciaCanje(canje: RedimirMedico): void {
    this.referenciaCanje.emit(canje);
  }
  getDetalleCanje(canje: RedimirMedico): void{
    this.detalleCanje.emit(canje);
  }
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  } 
  generateData(): any[] {
    let result: any[] = [];
 

    this.canjes.forEach((c, i) => {
        
        result.push( {
          No: (i+1).toString(),   
          FechaSolicitud: c.fechaInicio ? moment(c.fechaInicio).format('L') : '', 
          Cantidad: c.redimir,
          FechaAplicacion: c.fechaFinal ? moment(c.fechaFinal).format('L') : '', 
          Status: this.estatus(c.status),
          Observacion: c.observacion
                    });
    });
    return result;
  }
  exportTablaExcel(): void {
    let nombreMedico = [{campo: "Medico", nomMedico: this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: '')}];
    let tredimir: any[] = this.canjes.map((c, i) => {
      return  { 
                              No: (i+1).toString(),   
                              FechaSolicitud: c.fechaInicio ? moment(c.fechaInicio).format('L') : '', 
                              Cantidad: c.redimir,
                              FechaAplicacion: c.fechaFinal ? moment(c.fechaFinal).format('L') : '', 
                              Status: this.estatus(c.status),
                              Observacion: c.observacion
                              };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(nombreMedico, {skipHeader: true});
    
        /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_json(ws, tredimir, { origin:"A4" });
    XLSX.utils.book_append_sheet(wb, ws, 'Canjes1');
  
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_canjes.xlsx`);
  }
  exportTablaPDF(): void {
    const doc = new jsPDF('landscape');  
     let nombreMedico: string = this.medico.nombre + ' ' + this.medico.primerApellido + (this.medico.primerApellido ? ' ' + this.medico.primerApellido: '');
     let PdfWidth = doc.internal.pageSize.width;
     let PdfHeight = doc.internal.pageSize.height;
     doc.setFontSize(18);
     doc.text("Registro de canje del medico",  145, 10, { align: "center"});
     doc.setFontSize(12);
     doc.text(nombreMedico, 14, 15);
     doc.setFontSize(12);
     doc.text('Saldo: ' + this.saldo, 250, 15);
     doc.setTextColor(100);
     
    
     let rows = this.generateData();
     
     var totalPagesExp = '{total_pages_count_string}';
     autoTable(doc, ({
       startY: 10 + 10,
       body: rows,
       columns: [
         { header: 'No', dataKey: 'No' },
         { header: 'Fecha Solicitud', dataKey: 'FechaSolicitud' },
         { header: 'Cantidad', dataKey: 'Cantidad' },
         { header: 'Fecha Aplicacion', dataKey: 'FechaAplicacion' },
         { header: 'Status', dataKey: 'Status' },
         { header: 'Observacion', dataKey: 'Observacion' }
       ],
       didDrawPage: function (data) {
         // Footer
         var str = 'Pagina ' + doc.getNumberOfPages()
         // Total page number plugin only available in jspdf v1.0+
         if (typeof doc.putTotalPages === 'function') {
           str = str + ' de ' + totalPagesExp
         }
         doc.setFontSize(10)
   
         // jsPDF 1.4+ uses getWidth, <1.4 uses .width
         var pageSize = doc.internal.pageSize
         var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
         doc.text(str, data.settings.margin.left, pageHeight - 10)
       }
     }))
     if (typeof doc.putTotalPages === 'function') {
       doc.putTotalPages(totalPagesExp)
     }
    
     
     doc.save(`${new Date().toISOString()}_ccg.pdf`);
  }
}
