import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/servicios/auth.service';
@Component({
  selector: 'app-usuario-recover-psw',
  templateUrl: './usuario-recover-psw.component.html',
  styleUrls: ['./usuario-recover-psw.component.css']
})
export class UsuarioRecoverPswComponent implements OnInit {

  public recuperar = new FormGroup({
    correo: new FormControl('', [Validators.required, Validators.email]),
  });
  constructor(private location: Location, private aut: AuthService ) { }

  ngOnInit() {
  }
  onSubmit(){
    this.aut
    .setRecover(this.recuperar.value.correo)
    .subscribe( data => {                         

                       if(data.code == 1){
                         alert(data.message);
                         this.goBack();
                       }else{
                         alert(data.message);
                       }
                      }, err => {console.log(err)})
  }
  goBack(){
    this.location.back();
  }
}
