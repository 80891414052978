import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Usuario } from 'src/app/moddelo/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';

interface ErrResponse {
  code: number;
  message: string;
  noError: number;
}

@Component({
  selector: 'app-administrador-usuario-add',
  templateUrl: './administrador-usuario-add.component.html',
  styleUrls: ['./administrador-usuario-add.component.css']
})
export class AdministradorUsuarioAddComponent implements OnInit {
 
  private usuarioFrm: FormGroup = new FormGroup({   
                                                    usuario: new FormControl('', Validators.required),
                                                    password: new FormControl('', Validators.required),
                                                    alias: new FormControl('', Validators.required),
                                                    nombre: new FormControl('', Validators.required),
                                                    primerApellido: new FormControl('', Validators.required),
                                                    segundoApellido: new FormControl(''),
                                                    telefonoMovil: new FormControl(''),
                                                    fk_rol: new FormControl('2', Validators.required),
                                                    fk_nivel: new FormControl('', Validators.required),
                                                });
  get usuarioCtrl(){
    return this.usuarioFrm.get('usuario');
  }
  get passwordCtrl(){
    return this.usuarioFrm.get('password');
  }                                              
  get aliasCtrl(){
    return this.usuarioFrm.get('alias');
  }
  get nombreCtrl(){
    return this.usuarioFrm.get('nombre');
  }
  get primerApellidoCtrl(){
    return this.usuarioFrm.get('primerApellido');
  }
  get segundoApellidoCtrl(){
    return this.usuarioFrm.get('segundoApellido');
  }
  get telefonoMovilCtrl(){
    return this.usuarioFrm.get('telefonoMovil');
  }
  get rolCtrl(){
    return this.usuarioFrm.get('fk_rol');
  }  
  get nivelCtrl(){
    return this.usuarioFrm.get('fk_nivel');
  }                                              
  constructor(private location: Location, private activated: ActivatedRoute, private usuariosSrv: UsuariosService) { }

  ngOnInit() {
  }
  onSubmit(){
    let usuario: Usuario;
    usuario = <Usuario> this.usuarioFrm.value;
    this.usuariosSrv.setUsuario(usuario).subscribe(data => { if(data){ alert("Usuario registrado exitosamente"); this.goBack(); } }, err => { 
      if(err){
        alert(err.error.message);
      }
      console.log(err); 
    });
  }
  goBack(){
    this.location.back();
  }
}
