import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Venta } from 'src/app/moddelo/venta';
import { ProductosService } from 'src/app/servicios/productos.service';
import tipos from 'src/app/datos/tipos-venta'; 

@Component({
  selector: 'app-administrador-ventas',
  templateUrl: './administrador-ventas.component.html',
  styleUrls: ['./administrador-ventas.component.css']
})
export class AdministradorVentasComponent implements OnInit {
  
  public ventas: Venta[] = [];
  public edo: String[] = ["Cancelado",  "Activo", "Desactivado"];
  
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private productosServicio: ProductosService, private location: Location) { }

  ngOnInit() {
    this.productosServicio.getVentas()
    .subscribe(data => { 
                        this.ventas = data.sort((a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime());                        
                      }, 
                      err => { console.log(err); }
              );
  };
  
  goBack(){
    this.location.back();
  }
  tipoDocVenta( i:number ): String{
    return tipos[i];
  }
  edoDocVenta(i:number): String {
    return this.edo[i];
  }
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
}
