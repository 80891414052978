import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medico-ficha',
  templateUrl: './medico-ficha.component.html',
  styleUrls: ['./medico-ficha.component.css']
})
export class MedicoFichaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
