import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administrador-usuarios-passw',
  templateUrl: './administrador-usuarios-passw.component.html',
  styleUrls: ['./administrador-usuarios-passw.component.css']
})
export class AdministradorUsuariosPasswComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
