import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
import { MatDialog } from '@angular/material';
import { AuthService } from '../../servicios/auth.service';
import { DialogoCambiarPassw } from '../dialogos/dialogo-cambiar-passw/dialogo-cambiar-passw';


@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.html',
    styleUrls: ['./nav-bar.css']
})
export class NavBarComponnent implements OnInit {

    constructor(public auth: AuthService, 
        private router: Router,
        private dialogo: MatDialog){

    }
    ngOnInit(): void {
        
    }
    cerrar(){
        this.auth.cerrar();
        this.router.navigate(['/']);
      }
      changePass(): void{
        this.dialogo.open(DialogoCambiarPassw, {width:'600px'});
      }
      goPerfil(){
        this.router.navigate(['/usuario/perfil']);
      }
} 