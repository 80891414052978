import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Venta } from 'src/app/moddelo/venta';
import { ProductosService } from 'src/app/servicios/productos.service';
import tipos from 'src/app/datos/tipos-venta'; 

@Component({
  selector: 'app-administrador-venta',
  templateUrl: './administrador-venta.component.html',
  styleUrls: ['./administrador-venta.component.css']
})
export class AdministradorVentaComponent implements OnInit {
  private id: string = "";
  public venta: Venta = null;
        
  public ventaFrm: FormGroup = new FormGroup({ id: new FormControl(), fecha: new FormControl(), noVenta_noReferencia: new FormControl(), noProductos: new FormControl(), tipo: new FormControl()});
  
  get idCtrl(){
    return this.ventaFrm.get('id');
  }

  get fechaCtrl(){
    return this.ventaFrm.get('fecha')
  }

  get noVenta_noReferenciaCtrl(){
    return this.ventaFrm.get('noVenta_noReferencia');
  }

  get noProductosCtrl(){
    return this.ventaFrm.get('noProductos');
  }

  get tipoCtrl(){
    return this.ventaFrm.get('tipo');
  }
  
  get asignados(){
    let cantidad: number = 0;
    if(this.venta){
      cantidad =this.venta.productos.filter( p => p.fk_casoClinico != null).length;
    }
    return cantidad;
  }

  get getVenta(){
    let r: boolean = false;
    if(this.venta){
      r = this.venta.status == 0;
    }
    return r;
  }
  constructor(private activated: ActivatedRoute,private productosServ: ProductosService, private location: Location) { }

  ngOnInit() {
    this.id = this.activated.snapshot.paramMap.get('id');

    this.productosServ.getVenta(this.id)
    .subscribe( data => { 
      this.venta = data;

      this.idCtrl.setValue(this.venta.id);
      this.fechaCtrl.setValue(this.venta.fecha);
      this.noVenta_noReferenciaCtrl.setValue(this.venta.noVenta_noReferencia);
      this.noProductosCtrl.setValue(this.venta.noproductos);
      this.tipoCtrl.setValue(tipos[this.venta.tipo]);
      
    }, err => { 
      console.log(err); 
    });
    
  }

  goBack(){
    this.location.back();
  }

  cancelar(){

      if(confirm("Estas seguro de CANCELAR")){

        this.productosServ.setVentaCancelar(this.id).subscribe(data => {
              alert(data.noVenta_noReferencia);
              this.venta.status = data.status;         
        }, err => {
          console.log(err);
        });
        
      }
    

  }
 /* sin funcionalidad Cancelada
  cambio(){
    
    this.productosServ.setVentaCambio(this.id).subscribe( data => { 
      alert(data.noVenta_noReferencia);
      console.log(data);
    }, err => {
       console.log(err);
    });

  }
  */
}
