import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import localeMX from '@angular/common/locales/es-MX';
import { AppComponent } from './app.component';
import { NavBarComponnent } from './componentes/nav-bar/nav-bar';
import { FooterBarComponent } from './componentes/footer-bar/footer-bar';

import { MaterialModule } from './material.module';

import { AdministradorModule } from './componentes/administrador/administrador.module';
import { AdministradorRoutingModule } from './componentes/administrador/administrador-routing.module';
import { MedicosModule } from './componentes/medicos/medicos.module';
import { MedicosRoutingModule } from './componentes/medicos/medicos-routing.module';
import { EnfermerasModule } from './componentes/enfermeras/enfermeras.module';
import { EnfermerasRoutingModule } from './componentes/enfermeras/enfermeras-routing.module';
import { CasosClinicosModule } from './componentes/casos-clinicos/casos-clinicos.module';
import { CasosClinicosRoutingModule } from './componentes/casos-clinicos/casos-clinicos-routing.module';
import { ProductosModule } from './componentes/productos/productos.module';
import { ProductosRoutingModule } from './componentes/productos/productos-routing.module';
import { UsuariosModule } from './componentes/usuarios/usuarios.module';
import { UsuariosRoutingModule } from './componentes/usuarios/usuarios-routing.module';
import { DialogosModule } from './componentes/dialogos/dialogos.module';
import { HospitalesModule } from './componentes/catalogos/hospitales/hospitales.module';
import { InstitucionesModule } from './componentes/catalogos/instituciones/instituciones.module';
import { DiagnosticosModule } from './componentes/catalogos/diagnosticos/diagnosticos.module';
import { EspecialidadesModule } from './componentes/catalogos/especialidades/especialidades.module';
import { PuntosModule } from './componentes/catalogos/puntos/puntos.module';
import { AntibioticosModule } from './componentes/catalogos/antibioticos/antibioticos.module';
import { PaginacionModule } from './pipes/paginacion.module';
import { SupervisoresModule } from './componentes/supervisores/supervisores.module';
import { SupervisoresRoutingModule } from './componentes/supervisores/supervisores-routing.module';

registerLocaleData(localeMX);


@NgModule({
  declarations: [
    AppComponent, NavBarComponnent, FooterBarComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    DialogosModule,
    AdministradorModule,
    AdministradorRoutingModule,
    MedicosModule,
    MedicosRoutingModule,
    SupervisoresModule,
    SupervisoresRoutingModule,
    EnfermerasModule,
    EnfermerasRoutingModule,
    CasosClinicosModule,
    CasosClinicosRoutingModule,
    ProductosModule,
    ProductosRoutingModule,
    UsuariosModule,
    UsuariosRoutingModule,
    HospitalesModule,
    InstitucionesModule,
    DiagnosticosModule,
    EspecialidadesModule,
    AntibioticosModule,
    PuntosModule,
    PaginacionModule
  ],
  providers: [ 
              { provide: LOCALE_ID, useValue: 'es-MX'}
            ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule { }
