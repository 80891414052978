import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { AuthService } from 'src/app/servicios/auth.service';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';


@Component({
  selector: 'app-medico-canje-add',
  templateUrl: './medico-canje-add.component.html',
  styleUrls: ['./medico-canje-add.component.css']
})
export class MedicoCanjeAddComponent implements OnInit {
  
  private redimirFrm: FormGroup = new FormGroup({
                                                  redimir: new FormControl(0),
                                                  fechaInicio: new FormControl('', Validators.required),
                                                  concepto: new FormControl('', Validators.required),
                                                  descripcion: new FormControl('')
                                                });

  get fechaCtrl() { return this.redimirFrm.get('fechaInicio'); }
  get redimirCtrl() { return this.redimirFrm.get('redimir'); }
  get conceptoCtrl() { return this.redimirFrm.get('concepto'); }

  get medico() {
    return this.medicosSrv.medico;
  }
  get saldo() {
    let canjesaplicados = this.medico.redimirmedico.filter(canje => canje.status==3 || canje.status==1 ).reduce((valor, canje) => canje.redimir + valor, 0);
    let puntos = this.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
    return puntos - canjesaplicados;
  }
  constructor(
              private auth: AuthService, 
              private medicosSrv: MedicosService, 
              private location: Location,
              private snackBar: MatSnackBar
              ) { }
  
  ngOnInit() {   
      this.validaciones();
  }
  
  onSubmit(frm: NgForm) {
     let { redimir, fechaInicio, concepto, descripcion} = this.redimirFrm.value;
     let canje: RedimirMedico = <RedimirMedico> { redimir, fechaInicio, concepto, descripcion };
     canje.status = 1;
     canje.fk_medico = this.medico.id;

     this.medicosSrv
     .setRedimirCreate(canje)
     .subscribe(data => 
      { 

        this.medico.redimirmedico.push(data); 
        
        frm.reset();

        this.redimirCtrl.setValue(0);
        this.validaciones();

          this.snackBar.open(
          "Solicitud registrada",
           "Canje", 
           {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });

      }, err => { console.log( err ); }  );

  }
  
  validaciones(): void {
    this.redimirFrm.clearValidators();
    if(this.saldo > 0 ) //Validar proceso de saldo evitar que tenga numeros negativos
    {
      this.redimirCtrl.setValidators([Validators.min(100),Validators.required, Validators.max(this.saldo)]  );      
    }
    this.fechaCtrl.setValidators(Validators.required)
  }

  imprimir() {
   
    if(this.redimirCtrl.value <= this.saldo)
          console.log("soliitud",this.redimirCtrl.value ,"proceso aproblanble","saldo", this.saldo);
     else 
          console.log("soliitud", this.redimirCtrl.value, "proceso NO APROBABLE","saldo", this.saldo);     
  }

  goBack(): void {
    this.location.back();
  }

}
