import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from 'src/app/moddelo/usuario';
import { Singup } from 'src/app/moddelo/singup';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {   
    private usuario: Usuario;
    private isLoggedInStatus: boolean;
   

  constructor(private http: HttpClient) {
       this.isLoggedInStatus = false;
   }

  get isLoggedIn(){
    return this.isLoggedInStatus;
  }

  setLoggedIn(valor: boolean){
    this.isLoggedInStatus = valor;
  } 

  get getLoggedIn(){
    return this.isLoggedInStatus;
  } 

  setUsuario(usuario: Usuario){
    this.usuario = usuario;
  }

  get getUsuario() {
    return this.usuario;
  }

  getAutorizacion(usuario: string, password: string) {
        return this.http.post<Singup>('/apibioc/public/api/signup', {usuario, password}, httpOptions);  
  }
  setRecover(usuario: String){
    return this.http.post<Singup>('/apibioc/public/api/recover', { usuario }, httpOptions);
  }
  refresh(id: number) {
    return this.http.get<Singup>(`/apibioc/public/api/login/${id}`, httpOptions);
  }
  
  cerrar() {
    this.usuario = null;
    this.isLoggedInStatus = false;
  }
}
