import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { PuntosPorProducto } from 'src/app/moddelo/puntos-por-producto';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-puntos-producto',
  templateUrl: './puntos-producto.component.html',
  styleUrls: ['./puntos-producto.component.css']
})
export class PuntosProductoComponent implements OnInit {
  public id: number = 0;
  public puntosPorProducto: PuntosPorProducto = new PuntosPorProducto();
  public puntosporproductoFrm: FormGroup = new FormGroup({
    puntos: new FormControl('', Validators.required)
  });
  get puntosCtrl(){ return this.puntosporproductoFrm.get('puntos'); }

  constructor(
    private location: Location,
    private catalogosSrv: CatalogosService, 
    private snackBar: MatSnackBar,
    private activated: ActivatedRoute ) { }

  ngOnInit() {
    this.id = Number(this.activated.snapshot.paramMap.get('id'));
    if(this.id > 0){
      this.catalogosSrv
      .getPuntosPorProducto(this.id)
      .subscribe(data => { 
        this.puntosPorProducto = data;
        this.puntosCtrl.setValue(this.puntosPorProducto.puntos);
      }, err => { console.log(err); });
    }
  }

  
  onSubmit(){

    if(this.id > 0){
      this.actualizar();
    }else{
      this.guardar();
    }
    
  }

  
  guardar(): void{
    this.puntosPorProducto.puntos = this.puntosCtrl.value;
    //this.puntosPorProducto.id = this.id;
   
    this.catalogosSrv
    .setPuntosPorProducto(this.puntosPorProducto)
    .subscribe(data => { 
                          this.id = data.id;
                          this.puntosPorProducto.puntos = data.puntos;
                          this.puntosPorProducto.id = data.id;
                          this.snackBar.open('Puntos registrado', 'Puntos por Producto', {
                                                                                              duration: 2000,
                                                                                              verticalPosition: 'bottom',
                                                                                              horizontalPosition: 'center'
                                                                                            });
                        
                        }, err => { console.log(err); });
  }
  actualizar():void {
    this.puntosPorProducto.puntos = this.puntosCtrl.value;      
    this.catalogosSrv
    .setPuntosPorProductoUpdate(this.id, this.puntosPorProducto)
    .subscribe(data => { 
                         
                          this.puntosPorProducto = data;
                          this.snackBar.open('Puntos actualizado', 'Puntos por Producto', {
                                                                                              duration: 2000,
                                                                                              verticalPosition: 'bottom',
                                                                                              horizontalPosition: 'center'
                                                                                            });
                        
                        }, err => { console.log(err); });
  }
  goBack():void {
    return this.location.back();
  }

}
