import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { InstitucionesRoutingModule } from './instituciones-routing.module';
import { InstitucionesComponent } from './instituciones/instituciones.component';
import { InstitucionComponent } from './institucion/institucion.component';
import { InstitucionesOtrosComponent } from './instituciones-otros/instituciones-otros.component';
import { InstitucionOtroComponent } from './institucion-otro/institucion-otro.component';
import { InstitucionHospitalesComponent } from './institucion-hospitales/institucion-hospitales.component';
import { PaginacionModule } from 'src/app/pipes/paginacion.module';

@NgModule({
  declarations: [InstitucionesComponent, InstitucionComponent, InstitucionesOtrosComponent, InstitucionOtroComponent, InstitucionHospitalesComponent],
  imports: [
    CommonModule,
    InstitucionesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PaginacionModule
  ]
})
export class InstitucionesModule { }
