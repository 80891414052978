import {NgModule} from '@angular/core';
import { MatSliderModule,
         MatSidenavModule,
         MatButtonModule,
         MatRadioModule,
         MatMenuModule,
         MatToolbarModule, 
         MatDatepickerModule,
         MatFormFieldModule,
         MatTableModule, 
         MatGridListModule,
         MatSelectModule,
         MatStepperModule,
         MatDialogModule, 
         MatSlideToggleModule,
         MatExpansionModule, 
         MatSnackBarModule, 
         MatBadgeModule, 
         MatTooltipModule,
         MatListModule,
         MatTabsModule,
         MatInputModule, 
         MatNativeDateModule, 
         MatDividerModule, 
         MatIconModule, 
         MatCardModule, 
         MatPaginatorModule,  
         MatAutocompleteModule,
         MatSortModule        
} from '@angular/material';

@NgModule({
        imports:[
                MatTabsModule,
                MatTooltipModule,
                MatAutocompleteModule,
                MatSliderModule,
                MatSidenavModule,
                MatButtonModule,
                MatRadioModule,
                MatMenuModule,
                MatToolbarModule,
                MatDatepickerModule,
                MatFormFieldModule,
                MatInputModule,
                MatNativeDateModule,
                MatIconModule,
                MatCardModule,
                MatDividerModule,
                MatTableModule,
                MatSelectModule,
                MatStepperModule,
                MatDialogModule, 
                MatSlideToggleModule,               
                MatPaginatorModule,
                MatExpansionModule,
                MatGridListModule,
                MatSnackBarModule,
                MatBadgeModule,
                MatListModule,
                MatSortModule
                
        ], 
        exports:[
                MatTabsModule,
                MatTooltipModule,
                MatAutocompleteModule,
                MatSliderModule,
                MatSidenavModule,
                MatButtonModule,
                MatRadioModule,
                MatMenuModule,
                MatToolbarModule,
                MatDatepickerModule,
                MatFormFieldModule,
                MatInputModule,
                MatNativeDateModule,
                MatIconModule,
                MatCardModule,
                MatDividerModule,
                MatTableModule,
                MatSelectModule,
                MatStepperModule,
                MatDialogModule,
                MatSlideToggleModule,                
                MatPaginatorModule,
                MatExpansionModule,
                MatGridListModule,
                MatSnackBarModule,
                MatBadgeModule,
                MatListModule,
                MatSortModule               
        ]
})
export class MaterialModule{}