import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Institucion } from 'src/app/moddelo/institucion';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-instituciones',
  templateUrl: './instituciones.component.html',
  styleUrls: ['./instituciones.component.css']
})
export class InstitucionesComponent implements OnInit {
  public instituciones: Institucion[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
        institucion: new FormControl('')
  })

  get institucionCtrl() {
    return this.filtroFrm.get('institucion');
  }

  get usuario() {
    return this.auth.getUsuario;
  }

  get offLink() {
    return this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }

  constructor(
    private auth: AuthService, 
    private location: Location, 
    private catalogosSrv: CatalogosService, 
    private adminSrv: AdministradorService) { }

  ngOnInit() {
    this.instituciones = this.adminSrv.instituciones;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;   
    this.instituciones = this.adminSrv.instituciones.filter(inst => this.filterInstitucion(inst, filterValue)); 
    if(this.page_number != 1)
        this.page_number = 1;   
  }
  onSubmit() {  
  }

  filterInstitucion(institucion: Institucion, filtro: string): boolean {
    let condicion: boolean = true;
    let txtInstitucion: string = "";
    let nomInstitucion: string = "";
    let cveInstitucion: string = "";

        
    if(filtro != ""){
      txtInstitucion = filtro.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nomInstitucion = institucion.nomInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      cveInstitucion = institucion.cvInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
     
      condicion = nomInstitucion.concat(cveInstitucion).replace(/ +/g,'').includes(txtInstitucion.replace(/ +/g,''));    
    }       
    return condicion;
  }

  handlePage( e: PageEvent): void{
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

  goBack(): void{
    this.location.back();
  }

}
