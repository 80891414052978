import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { Diagnostico } from 'src/app/moddelo/diagnostico';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-diagnostico',
  templateUrl: './diagnostico.component.html',
  styleUrls: ['./diagnostico.component.css']
})
export class DiagnosticoComponent implements OnInit {
  public id: number = 0;
  public diagnostico: Diagnostico = new Diagnostico();

  public diagnosticoFrm: FormGroup = new FormGroup({
                          nomDiagnostico: new FormControl('', Validators.required)
                        });
  get nomDiagnosticoCtrl(){ return this.diagnosticoFrm.get('nomDiagnostico');}

  constructor(
              private snackBar: MatSnackBar,
              private activedR: ActivatedRoute,
              private location: Location, 
              private catalogosSrv: CatalogosService) { }

  ngOnInit() {
      this.id = Number(this.activedR.snapshot.paramMap.get('id'));
      if(this.id > 0){
            this.catalogosSrv
            .getDiagnostico(this.id)
            .subscribe(data => {
              this.diagnostico = data;
              this.nomDiagnosticoCtrl.setValue(this.diagnostico.nomDiagnostico);
            }, err => { console.log(err)} );
      }
    
  }
  onSubmit(){

    if(this.id > 0){
      this.actualizar();
    }else{
      this.guardar();
    }

  }
  actualizar(): void{
      this.diagnostico.nomDiagnostico = this.nomDiagnosticoCtrl.value;
      this.diagnostico.id = this.id;
      this.catalogosSrv
      .setDiagnosticoUpdate(this.id, this.diagnostico)
      .subscribe(data => { 

                          this.snackBar.open('Diagnostico actualizado', 'diagnostico', {
                                duration: 2000,
                                verticalPosition: 'bottom',
                                horizontalPosition: 'center'
                            });

      }, err => { console.log(err) });
  }
  guardar(): void{
      this.diagnostico.nomDiagnostico = this.nomDiagnosticoCtrl.value;
      this.diagnostico.id = this.id;

      this.catalogosSrv
      .setDiagnostico(this.diagnostico)
      .subscribe(data => {
        this.id = data.id;
        this.diagnostico.nomDiagnostico = data.nomDiagnostico;
        this.diagnostico.id = data.id;

        this.snackBar.open('Diagnostico registrado', 'diagnostico', {
          duration: 2000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
          });
      }, err => { console.log(err)});
  }

  goBack(): void{
    this.location.back();
  }

}
