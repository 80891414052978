import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { ActivatedRoute } from '@angular/router';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Medico } from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Producto } from 'src/app/moddelo/producto';

@Component({
  selector: 'app-administrador-medico-r-caso-clinico-enfermera',
  templateUrl: './administrador-medico-r-caso-clinico-enfermera.component.html',
  styleUrls: ['./administrador-medico-r-caso-clinico-enfermera.component.css']
})
export class AdministradorMedicoRCasoClinicoEnfermeraComponent implements OnInit {
  public casoClinico: CasoClinico = null;
  public medico: Medico = null;
  public enfermeras: Enfermera[] = [];
  public productos: Producto[] = [];
  public casoClinicoFrm = new FormGroup({ 
                            id: new FormControl('', Validators.required),
                            folio: new FormControl('', Validators.required),
                            hospital: new FormControl(''),
                            fk_enfermera: new FormControl('', Validators.required)                                  
                            });
  get idCtrl(){
    return this.casoClinicoFrm.get('id');
  }
  get folioCtrl(){
    return this.casoClinicoFrm.get('folio');
  }
  get hospitalCtrl(){
    return this.casoClinicoFrm.get('hospital');
  }
  get fk_enfermeraCtrl(){
    return this.casoClinicoFrm.get('fk_enfermera');
  } 

  constructor(
              private location: Location, 
              private adminSrv: AdministradorService, 
              private routerActive: ActivatedRoute,
              private snackBar: MatSnackBar,
              ) { }

  ngOnInit() {
    let idm = this.routerActive.snapshot.paramMap.get("idm");
    let idc = this.routerActive.snapshot.paramMap.get("idc");

    this.medico = this.adminSrv.medicos.find( medico => medico.id == Number(idm) );

    this.enfermeras = this.medico.enfermeras;
    this.casoClinico =this.medico.casosclinicos.find( cc => cc.id == Number(idc));
    this.productos = this.medico.productos.filter(p => p.fk_casoClinico == Number(idc));

    this.idCtrl.setValue(this.casoClinico.id);
    this.folioCtrl.setValue(this.casoClinico.folio);
    this.hospitalCtrl.setValue(this.casoClinico.hospital);

   
  }
  onSubmit(){
    let {id, folio, fk_enfermera } = this.casoClinicoFrm.value;
    let casoClinico: CasoClinico = <CasoClinico> {id, folio, fk_enfermera};
    let enfermera = this.enfermeras.find(e => e.id == this.fk_enfermeraCtrl.value);
    
    this.adminSrv
    .setUpCasoClinicoEnfermeraProPuntos(casoClinico.id, enfermera)
    .subscribe(data => { 
                    if(data){
                      
                        this.casoClinico.fk_enfermera = data.fk_enfermera;
                     
                        this.adminSrv.enfermeras.find(e => e.id == data.fk_enfermera).casosclinicos.push(data);                     
                        this.adminSrv.enfermeras.find(e => e.id == data.fk_enfermera).productos.push(...data.productos);
                        
                        this.snackBar.open('Actualizada', "Caso Clinico", {
                        duration: 3000,
                        verticalPosition:'bottom',
                        horizontalPosition:'center'
                        });

                    }

    }, err => { console.log(err); })
  }
  goBack(): void {
    this.location.back();
  }
}
