import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';

@Component({
  selector: 'app-enfermera-canje-add',
  templateUrl: './enfermera-canje-add.component.html',
  styleUrls: ['./enfermera-canje-add.component.css']
})
export class EnfermeraCanjeAddComponent implements OnInit {
  
  private redimirEFrm: FormGroup = new FormGroup({
                                                  redimir: new FormControl(0),
                                                  fechaInicio: new FormControl('')
                                                });
  
  get fechaCtrl(){ return this.redimirEFrm.get('fechaInicio');}
  get redimirCtrl(){ return this.redimirEFrm.get('redimir');}
  
  get enfermera(){
    return this.enfermerasSrv.enfermera;
  }

  get saldo(){    
    let canje = this.enfermera.redimirenfermera.filter(canje => canje.status ==3 || canje.status ==1 ).reduce((valor, canje) => canje.redimir + valor, 0);
    let puntos = this.enfermera.productos.reduce((valor, producto) => producto.e_puntos + valor, 0);    
    return puntos - canje;
  }
  constructor(
              private location: Location, 
              private auth: AuthService, 
              private enfermerasSrv: EnfermerasService, 
              private snackBar: MatSnackBar ) {   }

  ngOnInit() {       
    this.validaciones();
  }
  
  onSubmit(frm: NgForm){
    let { redimir, fechaInicio} = this.redimirEFrm.value;
    let canje: RedimirEnfermera = <RedimirEnfermera>{ redimir, fechaInicio };
    canje.status=1;
    canje.fk_enfermera = this.enfermera.id;
    
    this.enfermerasSrv
    .setRedimirCreate(canje)
    .subscribe(data => { 

          this.enfermera.redimirenfermera.push(data); 
          
          frm.reset();          
          this.redimirCtrl.setValue(0);
          this.validaciones();

          this.snackBar.open("Solicitud registrada", "Canje", {
            duration: 3000,
            verticalPosition:'bottom',
            horizontalPosition:'center'
          });

    }, err => { console.log( err ); } );
  }
  
  validaciones(): void{
   
    this.redimirEFrm.clearValidators();
    if(this.saldo > 0 ) //Validar proceso de saldo evitar que tenga numeros negativos
    {
      this.redimirCtrl.setValidators( [Validators.min(100),Validators.required, Validators.max(this.saldo)]  );      
    }    
    this.fechaCtrl.setValidators(Validators.required)
  }

  goBack(): void {
    this.location.back();
  }

}
