import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, PageEvent, Sort } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { img } from 'src/app/datos/content-img';
import moment  from 'moment/moment';
 
import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

import { Producto } from 'src/app/moddelo/producto';
import { Medico } from 'src/app/moddelo/medico';
import { Hospital } from 'src/app/moddelo/hospital';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { finalize } from 'rxjs/operators';

//import 'moment/locale/pt-br';
interface CHeaders{
      id: string,
      name: string,
      prompt: string,
      width: string,
      align: string,
      padding: string
}
interface ItbProductos {
  Batch: number;
  Codigo: string;
  Producto: string;
  Lote: string;
  Caducidad: string;
  Status: string;
  Hospital: string;
  Institucion: string;
}
interface IpdfProductos{
        No: string; 
        Fecha: string; 
        Batch: string;
        Factura: string; 
        Codigo: string;
        Producto: string;
        Lote: string;
        Caducidad: string;        
        Medico: string;
        Status: string;
}
@Component({
  selector: 'app-administrador-productos',
  templateUrl: './administrador-productos.component.html',
  styleUrls: ['./administrador-productos.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class AdministradorProductosComponent implements OnInit {
  public medicos: Medico[] = [];
  public productos: Producto[] = [];
  public hospitales: Hospital[] = [];
  public estatus: String[] = ['','Utilizado', 'Utilizado en la Institucion asignada', ' Utilizado fuera de la Institucion asignada','No utilizado', 'No utilizado vigencia activa ', ' No utilizado caducado', 'No utilizado ingresado', 'No utilizado ingresado vigencia activa', 'No utilizado ingresado caducado' ]
  
  public cargar: boolean = true;
  
  @ViewChild('tbProductos', {static:true})  tbProductos: HTMLTableElement;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl(''),
                                                codigo: new FormControl(''),
                                                producto: new FormControl(''),
                                                factura: new FormControl(''),
                                                st: new FormControl(''),
                                                medico: new FormControl(''),
                                                hospital: new FormControl(''),
                                                institucion: new FormControl(''),
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }
  get codigoCtrl(){
    return this.filtroFrm.get('codigo');
  }
  get productoCtrl(){
    return this.filtroFrm.get('producto');
  }
  get facturaCtrl(){
    return this.filtroFrm.get('factura');
  }
  get statusCtrl(){
    return this.filtroFrm.get('st');
  }
  get medicoCtrl(){
    return this.filtroFrm.get('medico');
  }
  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }
  get institucionCtrl(){
    return this.filtroFrm.get('institucion');
  }
  get usuario(){
    return this.aut.getUsuario;
  }
  get offProductos(){
    return (this.aut.getUsuario.fk_nivel==2 || this.aut.getUsuario.fk_nivel==0);
  }
  constructor(
              private aut: AuthService, 
              private adminSrv: AdministradorService,
              private catalogosSrv: CatalogosService,
              private medicosSrv: MedicosService
              ) { }

  ngOnInit() {
    this.adminSrv.card = 1;

    this.adminSrv
    .getProductos().pipe(finalize( () => this.cargar = false))
    .subscribe(data => {
      
      this.adminSrv.productos = data;
      
      this.productos = this.adminSrv.productos.filter(producto => producto.status).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    }, err => {console.log(err);});
  
    moment.locale('es');
 
     this.medicosSrv.getMedicos().subscribe(data => {
                                                      this.medicos = data.sort((a, b) => a.primerApellido.localeCompare(b.primerApellido));
                                                      let medico: Medico = new Medico();
                                                      medico.id = 0;
                                                      this.medicos.unshift(medico);
                                                    }, err => { console.log(err); });
  
                                                  
    this.catalogosSrv.getHospitales().subscribe( data => {
                                                          this.hospitales = data.sort((a, b) => a.nomHospital.localeCompare(b.nomHospital));          
                                                          let hospital: Hospital = new Hospital();
                                                          hospital.id = 0;
                                                          this.hospitales.unshift(hospital);
                                                          }, err => { console.log(err); });     
    

  }

  descripcionHospital( producto: Producto ): string{
    let msj: string = "";
    if(producto.casoclinico !== null){ 
      
        msj=producto.casoclinico.hospital;
      
    }
    return msj;
  }

  descripcionInstitucion( producto: Producto ): string{
    let msj: string = "";
    if(producto.casoclinico !== null){  
        msj = producto.casoclinico.cveInstitucion;
     
    }
    return msj;
  }

  estatu(producto: Producto): number{
    let edo: number = 0;
    if(producto.fk_casoClinico)
    {       
          edo = 1; // 'Utilizado ';
          if(producto.venta.fk_institucion == producto.casoclinico.fk_institucion)
            edo = 2; // 'En la Institucion asignada ';
          if(producto.venta.fk_institucion !== producto.casoclinico.fk_institucion) 
            edo = 3; // 'Fuera de la Institucion asignada ';  
    }
    else if(producto.fk_casoClinico == null){
                edo = 4; //  'No utilizado ';
                if(new Date(producto.caducidad).getTime() >= new Date(this.adminSrv.fechaActual.hoy).getTime())
                  edo = 5; // 'Vigencia activa ';
                else  
                  edo = 6; // 'Caducado ';
    }              
    return edo;
  }
 
  handlePage( e: PageEvent): void {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

  applyFilter(filtro: string): void {
    this.productos = this.adminSrv.productos.filter( producto =>  producto.codigo.toLowerCase().includes(filtro.trim().toLowerCase()) || producto.nomProducto.toLowerCase().includes(filtro.trim().toLowerCase()) ).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }

  onSubmit(): void  {
    if(this.page_number > 1)
    {
      this.page_number = 1;
    }
    this.productos = this.adminSrv.productos.filter(producto =>
          this.filterHospital(producto.casoclinico)  && 
          this.filterMedico(producto.medico) && 
          this.filterStatus(producto) && 
          this.filterCaducidad(producto.caducidad.toString()) && 
          this.filterCodigo(producto.codigo) && 
          this.filterProducto(producto.nomProducto) &&
          this.filterInstitucion(producto.casoclinico)
       ).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());    
  
  }

  filterCaducidad(caducidad: string): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(caducidad + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(caducidad + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(caducidad + " 00:00:00").getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(caducidad + " 00:00:00").getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  filterCodigo(codigo: string): boolean{
      let condicion: boolean = true;
      if(this.codigoCtrl.value != ""){
       condicion = !(codigo.indexOf(this.codigoCtrl.value) == -1);
      }
      
    return condicion;
  }
  filterProducto(producto: string): boolean{
    let condicion: boolean = true;
    if(this.productoCtrl.value != ""){
      condicion = !(producto.indexOf(this.productoCtrl.value)==-1);
    }    
    return condicion;
  }
  filterFactura(factura: string): boolean{
    let condicion: boolean = true;
    if(this.facturaCtrl.value != ""){
      condicion = factura.includes(this.facturaCtrl.value);
    }    
    return condicion;
  }
  filterStatus(producto: Producto): boolean{
    let condicion: boolean = true;
    if(this.statusCtrl.value != 0){
      if(this.statusCtrl.value == 1){
        condicion = this.estatu(producto) == 2 || this.estatu(producto) == 3;
      }else if(this.statusCtrl.value == 4) {
        condicion = this.estatu(producto) == 5 || this.estatu(producto) == 6;
      } else {
        condicion = this.estatu(producto) == this.statusCtrl.value;
      }
      
    }    
    return condicion;
  }
  filterMedico(medico: Medico): boolean{
    let condicion: boolean = true;
    let nom: string = "";
    let nomMedico: string = "";
 
    if(this.medicoCtrl.value != ""){
        nom = this._normalizeValue(this.medicoCtrl.value);
        if(medico){
          nomMedico = medico ? (medico.nombre + ( medico.primerApellido ? ' ' + medico.primerApellido : '' ) + (medico.segundoApellido ? ' ' + medico.segundoApellido : '') ): '';
          condicion = ( this._normalizeValue(nomMedico).includes(nom) );          
        }
    }    
    return condicion;
  }
  filterHospital(casoclinico: CasoClinico): boolean{
    
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.hospital ? casoclinico.hospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } 
      else {
        condicion = false;
      }      
    }    
    
    return condicion;   
  }
  filterInstitucion(casoclinico: CasoClinico): boolean {    
    let condicion: boolean = true;
    let institucion: string;
    let i: string;
    let nombre: string;
    if( this.institucionCtrl.value != 0 ) {
      i = this.institucionCtrl.value;
      institucion = i.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');     
      if(casoclinico){
        nombre = casoclinico.cveInstitucion ? casoclinico.cveInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n') : '';
        condicion = nombre.replace(/ +/g,'').includes(institucion.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }        
    return condicion;   
  }

  sortProductos(sort: Sort): void {
    const datos: Producto[] =  this.productos.slice();
    if(!sort.active || sort.direction === '') {
      this.productos = datos;
      return;
    }
    this.productos =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'caducidad':
          let caducidadA: number = new Date(a.caducidad).getTime();
          let caducidadB: number = new Date(b.caducidad).getTime();
          return this.compare(caducidadA, caducidadB, isAsc);
        case 'nomproducto':
          return this.compare(a.nomProducto, b.nomProducto, isAsc);
        case 'lote':
          return this.compare(a.lote, b.lote, isAsc);
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'batch':
          return this.compare(a.noBatch, b.noBatch, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'hospital':
          let hospitalA: string = a.fk_casoClinico ? this.descripcionHospital(a) : '';//a.fk_enfermera ? a.enfermera.nombre + ' ' +( a.enfermera.primerApellido ? a.enfermera.primerApellido: '') : '';
          let hospitalB: string = b.fk_casoClinico ? this.descripcionHospital(b) : '';//b.fk_enfermera ? b.enfermera.nombre + ' ' +( b.enfermera.primerApellido ? b.enfermera.primerApellido: '') : '';
          return this.compare(hospitalA, hospitalB, isAsc);
        case 'institucion':
          let institucionA: string = a.fk_casoClinico ? this.descripcionInstitucion(a) : ''; //a.productos ? a.productos.length : 0;
          let institucionB: string = b.fk_casoClinico ? this.descripcionInstitucion(b) : ''; // .productos ? b.productos.length : 0;
          return this.compare(institucionA, institucionB, isAsc);
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  _normalizeValue(nom: String): string {  
    return nom ? nom.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'') : '';
  } 

  generateData(): any[] {
    let result: IpdfProductos[] = [];
    let i: number = 0;

    this.productos.forEach((p, i) => {
        let ix = this.estatu(p);
        result.push( { 
                      No: (i+1).toString(), 
                      Fecha: p.fechaAplicacion ? moment(p.fechaAplicacion).format('L') : 'S-N', 
                      Batch: p.noBatch.toString(),
                      Factura: p.fk_venta ? p.venta.noVenta_noReferencia: 'S-N', 
                      Codigo: p.codigo,
                      Producto: p.nomProducto,
                      Lote: p.lote.toString(),
                      Caducidad: moment(p.caducidad).format('L'),                      
                      Medico: p.fk_medico ? p.medico.nombre + ' ' + p.medico.primerApellido : '',
                      Status: this.estatus[ix].toString(), 
                    });
    });

  return result;
  }
  
  exportTablaPDF(): void {
    
    const doc = new jsPDF('landscape');  
   // let image = `data:image/png;base64,${img}`;
    
    let PdfWidth = doc.internal.pageSize.width;
    let PdfHeight = doc.internal.pageSize.height;
    
    //doc.addImage(image, 'PNG', 1, 1, 45, 45);
    //doc.setFontSize(24);    
    //doc.text('PRODUCTOS', 145, 20, {align: "center"});
    
    doc.text("PRODUCTOS",  145, 10, { align: "center"});
    //let headers = this.createHeaders();
    let rows = this.generateData();
    //doc.setFontSize(12); 
    //doc.table(0, 25, rows, headers, { autoSize: true});
    var totalPagesExp = '{total_pages_count_string}';
    autoTable(doc, ({
      startY: 10 + 10,
      tableWidth: 'wrap',
      columnStyles: { Batch: { halign: 'center' } },
      styles: { cellPadding: 0.5, fontSize: 8 },
      body: rows,
      columns: [
        { header: 'No.', dataKey: 'No' },
        { header: 'Fecha', dataKey: 'Fecha' },
        { header: 'No. Batch', dataKey: 'Batch' },
        { header: 'Factura', dataKey: 'Factura' },
        { header: 'Codigo', dataKey: 'Codigo' },
        { header: 'Producto', dataKey: 'Producto' },
        { header: 'Lote', dataKey: 'Lote' },
        { header: 'Caducidad', dataKey: 'Caducidad' },       
        { header: 'Medico', dataKey: 'Medico' },
        { header: 'Status', dataKey: 'Status' },
      ],
      didDrawPage: function (data) {
        // Footer
        var str = 'Pagina ' + doc.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' de ' + totalPagesExp
        }
        doc.setFontSize(10)
  
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      }
    }))
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    /*
    styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
    // Override the default above for the text column
    
    let pages = doc.getNumberOfPages();
    */
    
    doc.save(`${new Date().toISOString()}_sdo.pdf`);
  }

  exportTablaExcel(): void{
    let tProductos: ItbProductos[] = this.productos.map(p => {
      return <ItbProductos> { Batch: p.noBatch, 
                              Codigo: p.codigo,
                              Producto: p.nomProducto,
                              Caducidad: moment(p.caducidad).format('L'),
                              Lote: p.lote,
                              Status: this.estatus[this.estatu( p )], 
                              Hospital: p.fk_casoClinico ? p.casoclinico.hospital: '', 
                              Institucion: p.fk_casoClinico ? p.casoclinico.cveInstitucion: ''};
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tProductos);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Productos1');
 
    /* save to file */  
    XLSX.writeFile(wb, `${new Date().toISOString()}_productos.xlsx`);
  }
}
