import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Producto } from 'src/app/moddelo/producto';
import { Medico} from 'src/app/moddelo/medico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { FechaActual } from 'src/app/moddelo/fecha-actual';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Hospital } from 'src/app/moddelo/hospital';
import { Institucion } from 'src/app/moddelo/institucion';
import { Supervisor } from '../moddelo/supervisor';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdministradorService {
  public card: number = 0;

  public productos: Producto[] = [];
  public medicos: Medico[] = [];
  public enfermeras: Enfermera[] = [];
  public supervisores: Supervisor[] = [];
  public fechaActual: FechaActual = null;

  public instituciones: Institucion[] = [];  
  public hospitales: Hospital[] = [];

  constructor(private http: HttpClient) { }
  
  get active(){
    return this.card;
  }

  getProductosFecha(){
    return this.http.get<FechaActual>('/apibioc/public/api/productos/fecha', httpOptions);
  }

  getProductos(){
    return this.http.get<Producto[]>( `/apibioc/public/api/productos`, httpOptions);
  }

  getMedicos(){
    return this.http.get<Medico[]>( `/apibioc/public/api/medicos`, httpOptions);
  }

  getMedico(id: string){
    return this.http.get<Medico>( `/apibioc/public/api/medico/${id}`, httpOptions);
  }

  getEnfermeras(){
    return this.http.get<Enfermera[]>( `/apibioc/public/api/enfermeras`, httpOptions);
  }

  getInstituciones(){
    return this.http.get<Institucion[]>(`/apibioc/public/api/instituciones`, httpOptions);
  }

  getSupervisores(){
    return this.http.get<Supervisor[]>(`/apibioc/public/api/supervisores`, httpOptions);
  }
  
  getHospitales(){
    return this.http.get<Hospital[]>(`/apibioc/public/api/hospitales`, httpOptions);
  }

/* *********************************************************************************************** */
 setMedicoUpdate(id: number, medico: Medico){
  return  this.http.put<Medico>(`/apibioc/public/api/medico/${id}`, medico);
 }
 setEnfermeraUpdate(id: number, enfermera: Enfermera){
  return  this.http.put<Enfermera>(`/apibioc/public/api/enfermera/${id}`, enfermera);
 }
 setUpCasoClinicoEnfermeraProPuntos(id: number, enfermera: Enfermera){
  return this.http.put<CasoClinico>(`/apibioc/public/api/casoclinico/enfermera/productos/${id}`, enfermera); 
 }
 setCasosClinicoUpdate(id: number, casoClinico: CasoClinico){
      return this.http.put<CasoClinico>( `/apibioc/public/api/casoclinico/${id}`, casoClinico);
 }
 setActualizarPuntosMedico(id: number, puntos: number){
   let producto = new Producto();
   producto.id = id;
   producto.m_puntos = puntos;
  return this.http.put(`/apibioc/public/api/producto/caso/medico/${id}`, producto);
 }
 setActualizarPuntosEnfermera(id: number, puntos: number){
  let producto = new Producto();
  producto.id = id;
  producto.e_puntos = puntos;
  return this.http.put(`/apibioc/public/api/producto/caso/enfermera/${id}`, producto);
 }

} 
