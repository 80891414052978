import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { 
        MAT_MOMENT_DATE_FORMATS, 
        MomentDateAdapter, 
        MAT_MOMENT_DATE_ADAPTER_OPTIONS 
        } from '@angular/material-moment-adapter';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Medico } from 'src/app/moddelo/medico';
import { MedicosService } from 'src/app/servicios/medicos.service';
import { RedimirMedico } from 'src/app/moddelo/redimir-medico';

interface ActualizarCanje{
  code: number;
  message: string;
  data: RedimirMedico;
}
@Component({
  selector: 'app-administrador-medico-r-canje-update',
  templateUrl: './administrador-medico-r-canje-update.component.html',
  styleUrls: ['./administrador-medico-r-canje-update.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class AdministradorMedicoRCanjeUpdateComponent implements OnInit {
  
  @Input('medico') medico: Medico;
  @Input('canje') canje: RedimirMedico;
  @Output() cancelarCanje: EventEmitter<any> = new EventEmitter<any>();

  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;
  public fileData: File;
  public canjeFrm = new FormGroup({ 
                                  redimir: new FormControl(''),
                                  fechaFinal: new FormControl('', Validators.required),
                                  observacion: new FormControl(''),
                                  archivo: new FormControl('', Validators.required)                                  
                                  });
  get redimirCtrl(){
    return this.canjeFrm.get('redimir');
  } 
  get fechaFinalCtrl(){
    return this.canjeFrm.get('fechaFinal');
  }
  get observacionCtrl(){
    return this.canjeFrm.get('observacion');
  }

 
  get saldo(){
    return this.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0) - this.medico.redimirmedico.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  get puntos(){
    return this.medico.productos.reduce((valor, producto) => producto.m_puntos + valor, 0);
  } 
  get redimir(){
    return this.medico.redimirmedico.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }
  get entransito(){
    let transito: number = this.medico.redimirmedico.filter(canje => canje.status == 1).reduce((valor, canje ) => canje.redimir + valor, 0);
    return transito; //( transito - this.redimirCtrl.value) >= 0 ? (transito - this.redimirCtrl.value) : 0;
  }
  get pendientes(){ 
      return this.redimirCtrl.value;
  }  
  get usuario(){
    return this.auth.getUsuario;
  } 
  get offBoton(){
    return this.canjeFrm.valid && ( this.usuario.fk_nivel==3 ||  this.usuario.fk_nivel==0)
  }
  get onBoton(){
   return (this.fechaFinalCtrl.errors || !this.observacionCtrl.value) && !( this.usuario.fk_nivel==3 || this.usuario.fk_nivel==0)
  }       
  
  
  constructor(
              private auth: AuthService,
              private medicosSrv: MedicosService,
              private adminSrv: AdministradorService, 
              private activateRoute: ActivatedRoute,
              private location: Location,
              private matSnackBar: MatSnackBar
  ) { }
  
  ngOnInit() {
    //let id_medico = this.activateRoute.snapshot.paramMap.get('id1'); 
    //let id_canje =this.activateRoute.snapshot.paramMap.get('id2');
    
    //this.medico = this.adminSrv.medicos.find(medico => medico.id == Number(id_medico));
    //this.canje = this.medico.redimirmedico.find(canje => canje.id == Number(id_canje));
    
    if(this.saldo>0){
      this.redimirCtrl.setValidators( [Validators.max(this.saldo), Validators.min(500), Validators.required]);
    }
    this.redimirCtrl.setValue(this.canje.redimir);
    
  }
  onSubmit() {
   
    const formData = new FormData();
    
    formData.append('id', this.canje.id.toString());
    formData.append('redimir', this.redimirCtrl.value);
    formData.append('fk_medico', this.canje.fk_medico.toString());
    formData.append('fechaFinal', formatDate(this.fechaFinalCtrl.value, "yyyy-MM-dd", "es-MX") );
    formData.append('observacion', this.observacionCtrl.value); 
    formData.append('status', '3');  
    formData.append('file', this.fileData); 
   
    if(this.saldo - this.pendientes >= 0 ){
        this.medicosSrv
        .setRedimirUpdate(formData)
        .subscribe( events => {
                                  let r: ActualizarCanje;
                                  if(events.type === HttpEventType.UploadProgress){
  
                                    this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                    this.progress = Math.round( events.loaded / events.total * 100);                                   
                                    
                                  } else if( events.type === HttpEventType.Response){
                                            console.log(events.body);
                                            let updateCanje: RedimirMedico = <RedimirMedico> events.body;

                                            this.canje.fechaFinal = updateCanje.fechaFinal;
                                            this.canje.status = updateCanje.status;
                                            this.canje.observacion = updateCanje.observacion;
                                            this.canje.referencias.push(updateCanje.referencias[0]);  

                                            this.matSnackBar.open("Solicitud Procesada", "Canje", {
                                                                                          duration: 3000,
                                                                                          verticalPosition:'bottom',
                                                                                          horizontalPosition:'center'
                                                                                        }); 
                                            this.cancelar(); 

                                  } else if(events.type === HttpEventType.ResponseHeader){
                                      console.log( events.ok ); 
                                  }
                                }, err => { console.log(err); } ); 
                          }else{      
                            this.matSnackBar.open("Puntos insuficientes", "Canje", {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                          }

    
   
  }
  fileSelect(inputfile: any){
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;
  }
  rechazado(){
    let canje: RedimirMedico = new RedimirMedico();
    canje.id =  this.canje.id;
    canje.observacion = this.observacionCtrl.value;
    canje.fechaFinal = this.fechaFinalCtrl.value;
    canje.status = 2;

    this.medicosSrv
    .setRedimirUpdateRechazado(canje)
    .subscribe(data => {
                          if(data){
                            this.matSnackBar.open("Rechazada con exito", "Canje", {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                            this.canje.status = data.status;
                            this.canje.fechaFinal = data.fechaFinal;
                            this.canje.observacion = data.observacion;
                          }else{
                            this.matSnackBar.open("No Rechazada", "Canje", {
                              duration: 3000,
                              verticalPosition:'bottom',
                              horizontalPosition:'center'
                            });
                          }
              }, err => {
                this.matSnackBar.open("No se puede rechazar", "Canje", {
                  duration: 3000,
                  verticalPosition:'bottom',
                  horizontalPosition:'center'
                });
              });    

  }
  cancelar(): void {
    this.cancelarCanje.emit();
  }

}
