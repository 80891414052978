import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Hospital } from 'src/app/moddelo/hospital';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { CatalogosService } from 'src/app/servicios/catalogos.service';

@Component({
  selector: 'app-hospitales',
  templateUrl: './hospitales.component.html',
  styleUrls: ['./hospitales.component.css']
})
export class HospitalesComponent implements OnInit {
  public hospitales: Hospital[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  public filtroFrm: FormGroup = new FormGroup({
    hospital: new FormControl('')
  });

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }
  get usuario(){
    return this.auth.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 1 || this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3;
  }
  
  constructor(
              private auth: AuthService,
              private location: Location, 
              private catalogosSrv: CatalogosService, 
              private adminSrv: AdministradorService) { }

  ngOnInit() {
  this.hospitales = this.adminSrv.hospitales; 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;   
    this.hospitales = this.adminSrv.hospitales.filter(txthospital => this.filterCompuesto(txthospital, filterValue));
    if(this.page_number != 1)
        this.page_number = 1;   
  }

  onSubmit() {   
  }

  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let h: string="";
    let hospital: string="";
    let nombre: string="";

    if(this.hospitalCtrl.value != ""){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));     
    }    
    
    return condicion;
  }

  filterCompuesto(hospital: Hospital, filtro: string): boolean{
    let condicion: boolean = true;
    
    let txtHospital: string = "";
    let nombreHospital: string = "";
    let abrInstitucion: string = "";    
    let nombreEstado: string = "";

      if(filtro != "") {

        txtHospital = filtro.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
       
        nombreHospital = hospital.nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
        abrInstitucion = hospital.institucion ? hospital.institucion.cvInstitucion.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : "";
        nombreEstado =  hospital.estado ? hospital.estado.nomEstado.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u') : "";

        condicion = nombreHospital.concat(abrInstitucion).concat(nombreEstado).replace(/ +/g,'').includes(txtHospital.replace(/ +/g,'')); 
           
      }
    
    return condicion;
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  /*
  pagina(x: number, pageEvent: PageEvent): boolean{
    let sizePage: number = 10;
    let pageIndex: number = 0;
    
    if(pageEvent !== undefined){
      sizePage = pageEvent.pageSize;
      pageIndex = pageEvent.pageIndex;
    }

    return Math.ceil(( (x+1) /sizePage)) !== (pageIndex + 1);
  }
  */
  goBack(): void{
    this.location.back();
  }

}
