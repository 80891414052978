import { Component, OnInit } from '@angular/core';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';


@Component({
  selector: 'app-casos-clinicos-postoperatorios',
  templateUrl: './casos-clinicos-postoperatorios.component.html',
  styleUrls: ['./casos-clinicos-postoperatorios.component.css']
})
export class CasosClinicosPostoperatoriosComponent implements OnInit {
  public panelOpenState = false;
  public valoracion: String[] = ["", "Malo", "Regular", "Bueno", "Excelente"];

  get seguimientos(){
    return this.casosClinicosSrv.casoClinico.seguimientos; //seguimientosPostoperatorios;
  }
  constructor(private casosClinicosSrv: CasosClinicosService) { }
  
  ngOnInit() {
    
  }
  valorar(i: number){
    return this.valoracion[i];
  }
}
