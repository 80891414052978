import { Component, OnInit } from '@angular/core';
import { AuthService} from '../../../servicios/auth.service';
import { AdministradorService } from '../../../servicios/administrador.service';
@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css']
})
export class AdministradorComponent implements OnInit {

  get hospitales(){
    return this.adminSrv.hospitales;
  }
  get instituciones(){
    return this.adminSrv.instituciones;
  }
  get usuario(){
    return this.aut.getUsuario;
  }
  constructor(
    private aut: AuthService, 
    private adminSrv: AdministradorService) { }

  ngOnInit() {
    this.adminSrv.card = 0; 
    this.adminSrv.getInstituciones().subscribe(data => {this.adminSrv.instituciones = data;}, err => { console.log(err); });
    this.adminSrv.getHospitales().subscribe(data => {this.adminSrv.hospitales = data;}, err => { console.log(err); });  
  }

}
