import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { AuthService } from 'src/app/servicios/auth.service';
import { SupervisoresService } from 'src/app/servicios/supervisores.service';

interface RemoveEnfermera{
  code: number;
  message: string;
  data: Enfermera;
}

@Component({
  selector: 'app-administrador-supervisor-r-enfermera-remove',
  templateUrl: './administrador-supervisor-r-enfermera-remove.component.html',
  styleUrls: ['./administrador-supervisor-r-enfermera-remove.component.css']
})
export class AdministradorSupervisorREnfermeraRemoveComponent implements OnInit {
  public enfermera: Enfermera;
  public supervisor: Supervisor;
  public respuesta: RemoveEnfermera;

  constructor(
    private location: Location,
    private adminSrv: AdministradorService,   
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private supervisoresSrv: SupervisoresService) { }

  ngOnInit() {
    let id_supervisor: number = Number(this.activeRoute.snapshot.paramMap.get('id1'));
    let id_enfermera: number = Number(this.activeRoute.snapshot.paramMap.get('id2'));

    this.supervisor = this.adminSrv.supervisores.find(supervisor => supervisor.id == id_supervisor);
    this.enfermera = this.supervisor.enfermeras.find(enfermera => enfermera.id == id_enfermera);
  }
  remove(): void{
    if(confirm("¿Estas seguro?")){

     this.supervisoresSrv
     .setDesasociarEnfermera(this.supervisor.id, this.enfermera)
     .subscribe(data => {
                          let i: number = 0;
                          let e: Enfermera[] = null;
                          this.respuesta = <RemoveEnfermera> data;
                          if(this.respuesta.code){
                            i = this.supervisor.enfermeras.findIndex(e => e.id == this.enfermera.id); 
                            e = this.supervisor.enfermeras.splice(i, 1);
                            this.snackBar.open(this.respuesta.message, 
                              'Enfermera', 
                              {
                                duration: 3000,
                                verticalPosition:'bottom',
                                horizontalPosition:'center'
                              });
                              console.log("Removido: ", e[0].nombre, ' ' , e[0].primerApellido );
                              this.goBack(); 
                          }else{
                                  this.snackBar.open(this.respuesta.message, 
                                    'Medico', 
                                    {
                                      duration: 3000,
                                      verticalPosition:'bottom',
                                      horizontalPosition:'center'
                                    });
                          }
     }, err => { console.log(err); });
     
    }
  }
  goBack(): void 
  {
    this.location.back();
  }

}
