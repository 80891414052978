import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent, Sort } from '@angular/material'; 
import { Batch } from 'src/app/moddelo/batch';
import { Institucion } from 'src/app/moddelo/institucion';
import { ProductosService } from 'src/app/servicios/productos.service';

@Component({
  selector: 'app-administrador-productos-only-batch',
  templateUrl: './administrador-productos-only-batch.component.html',
  styleUrls: ['./administrador-productos-only-batch.component.css']
})
export class AdministradorProductosOnlyBatchComponent implements OnInit {
  
  public instituciones: Institucion[] = [];
  public batchs: Batch[]=[];

  public batchsFilter: Batch[] =[];
  
  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(  
                private location: Location, 
                private productoSrv: ProductosService,
              ) { }

  ngOnInit() {
    
    this.productoSrv.getBatchs().subscribe( data => {
              this.batchs = data.sort((a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime());
              this.batchsFilter = this.batchs;
              } , err => { console.log( err )});
  
  }

  goBack() {
    this.location.back();
  }
  sortBatchs(sort: Sort): void {
    const datos: Batch[] = this.batchs.slice();
    if(!sort.active || sort.direction === ''){
      this.batchsFilter = datos;
      return;
    }
    this.batchsFilter =  datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fecha':
          let a1: number = new Date(a.fecha).getTime();
          let b1: number = new Date(b.fecha).getTime();
          return this.compare(a1, b1, isAsc);
        case 'batch':
          return this.compare(a.noBatch, b.noBatch, isAsc);
        case 'archivo':
          return this.compare(a.archivo_fuente, b.archivo_fuente, isAsc);
        case 'productos':
          return this.compare(a.noproductos, b.noproductos, isAsc); 
        default:
          return 0;
      }
    });
  } 
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }

}
