import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AuthService } from 'src/app/servicios/auth.service';
import { EnfermerasService } from 'src/app/servicios/enfermeras.service';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { Medico } from 'src/app/moddelo/medico';

@Component({
  selector: 'app-enfermera-casos-clinicos',
  templateUrl: './enfermera-casos-clinicos.component.html',
  styleUrls: ['./enfermera-casos-clinicos.component.css']
})
export class EnfermeraCasosClinicosComponent implements OnInit {
  public casosClinicos: CasoClinico[] = [];
  public cc: CasoClinico[] = [];
  public enfermera: Enfermera;
  public filtroFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  constructor(private aut: AuthService, private enfermerasSrv: EnfermerasService, private formBuilder: FormBuilder) {
    this.enfermera = this.aut.getUsuario.enfermera;
  }

  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }

  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }

  get folioCtrl(){
    return this.filtroFrm.get('folio');
  }

  get pacienteCtrl(){
    return this.filtroFrm.get('paciente');
  }

  get hospitalCtrl(){
    return this.filtroFrm.get('hospital');
  }

  ngOnInit() {

    this.enfermerasSrv.card = 1;
    
    this.filtroFrm = this.formBuilder.group({
                                            fechaInicial:[''],
                                            fechaFinal:[''],
                                            folio:[''],
                                            paciente:[''],
                                            hospital: ['']
    });

    this.enfermerasSrv
    .getCasosClinicos(this.enfermera.id)
    .subscribe(data => {
      this.cc = data; 
      this.casosClinicos = data;
    }, err => { console.log(err); } );

    
  }
  onSubmit(){         
    this.casosClinicos = this.cc.filter( cc => 
      this.filterPaciente(cc.paciente) && 
      this.filterHospital(cc.hospital) && 
      this.filterFolio(cc.serie) && 
      this.filterFecha(cc.fecha));
    this.page_number=1;
  }

  filterFecha(fechaCirugia: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(fechaCirugia).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(fechaCirugia).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }
  
  filterPaciente(nomPaciente: string): boolean{
    let condicion: boolean = true;
    let paciente: string;
    let m: string;
    let nombre: string; 
    
    if(this.pacienteCtrl.value != ""){
      m = this.pacienteCtrl.value;
      paciente = m.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomPaciente.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
            
      condicion = nombre.replace(/ +/g,'').includes(paciente.replace(/ +/g,''));
    }

    return condicion;
  }
 
  filterHospital(nomHospital: string): boolean{
    let condicion: boolean = true;
    let hospital: string;
    let h: string;
    let nombre: string;
    if( this.hospitalCtrl.value != 0 ){
      h = this.hospitalCtrl.value;
      hospital = h.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      nombre = nomHospital.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u').replace('ñ', 'n');
      if(nombre){
        condicion = nombre.replace(/ +/g,'').includes(hospital.replace(/ +/g,''));        
      } else {
        condicion = false;
      }      
    }    
    
    return condicion;
  }

  filterFolio(folio: string): boolean{
    let condicion: boolean = true;

      if(this.folioCtrl.value != ""){
       condicion = !( folio.indexOf(this.folioCtrl.value) == -1 );
      } 

    return condicion; 
  }

  medico(fk_medico): Medico{
    return this.enfermerasSrv.enfermera.medicos.find(m => m.id == fk_medico);
  }
  

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
}
