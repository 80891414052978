import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, PageEvent, Sort } from '@angular/material';

import { Usuario } from 'src/app/moddelo/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { DialogoCambiarPasswUsuario } from '../../dialogos/dialogo-cambiar-passw-usuario/dialogo-cambiar-passw-usuario';

@Component({
  selector: 'app-administrador-usuarios',
  templateUrl: './administrador-usuarios.component.html',
  styleUrls: ['./administrador-usuarios.component.css']
})
export class AdministradorUsuariosComponent implements OnInit {
  public usuarios: Usuario[] = [];
  public usuariosFiltrados: Usuario[] =[];
  public consultaUsuarioFrm: FormGroup;

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  constructor(
    private formBuilder: FormBuilder, 
    private usuariosSrv: UsuariosService, 
    private dialogo: MatDialog) { 

    }

  ngOnInit() {

      this.usuariosSrv
      .getUsuarios()
      .subscribe(data => { 
                            this.usuarios = data;     
                            this.usuariosFiltrados = data;        
                        }, 
                        err => { 
                          console.log(err); 
                        } 
                );

  }
  handlePage( e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  } 
  changePassw(usuario: Usuario) {
    this.dialogo.open(DialogoCambiarPasswUsuario, {width:'600px', data: usuario});
  }
  applyFilter(event: Event){
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.usuariosFiltrados = this.usuarios.filter(u => this.filterUsuario(u, filterValue))
        this.page_number = 1;
    
  }
  normalize(caracteres: string): string{
    let datos: string = caracteres.toLowerCase().replace('á','a').replace('é','e').replace('í','i').replace('ó','o').replace('ú','u').replace(/ +/g,'');
    return datos;
  }

  filterUsuario(usuario: Usuario, cadena: string): boolean{
    let condicion: boolean = true;
    
    let caracteres: string = this.normalize(cadena);
    let nomCmpUsuario: string = usuario ? (usuario.nombre + ( usuario.primerApellido ? ' ' + usuario.primerApellido : '' ) + (usuario.segundoApellido ? ' ' + usuario.segundoApellido : '') ): '';
    let nomRol: string = usuario.rol ? usuario.rol.desc_rol : '';

    if(cadena){
      condicion = (nomCmpUsuario ? this.normalize(nomCmpUsuario).includes(caracteres): true ) || 
                  (usuario.usuario ? this.normalize(usuario.usuario).includes(caracteres): true )  ||
                  (usuario.alias ? this.normalize(usuario.alias).includes(caracteres): true )  ||
                  (nomRol ? this.normalize(nomRol).includes(caracteres): true );
    }                
    return condicion;
  }
  sortUsuarios(sort: Sort): void {
    const datos: Usuario[] = this.usuariosFiltrados.slice();
    if(!sort.active || sort.direction === '') {
      this.usuariosFiltrados = datos;
      return;
    }
    this.usuariosFiltrados = datos.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'usuario':
          return this.compare(a.usuario, b.usuario, isAsc);
        case 'alias':
          return this.compare(a.alias, b.alias, isAsc);
        case 'nombre':
          return this.compare(this.concatenarNombre(a), this.concatenarNombre(b), isAsc);
        case 'rol':
          return this.compare(a.rol.desc_rol, b.rol.desc_rol, isAsc);
        case 'nivel':
          return this.compare(a.fk_nivel, b.fk_nivel, isAsc);       
        default:
          return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  concatenarNombre(usuario: Usuario): string{
    return this.normalize((usuario.nombre + ( usuario.primerApellido ? ' ' + usuario.primerApellido : '' ) + (usuario.segundoApellido ? ' ' + usuario.segundoApellido : '') ));
  }
}
