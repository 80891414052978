import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material';

import { CatalogosService } from 'src/app/servicios/catalogos.service';
import { TipoDocumento } from 'src/app/moddelo/tipo-documento';
import { CasosClinicosService } from 'src/app/servicios/casos-clinicos.service';
import { SeguimientoPostoperatorio } from 'src/app/moddelo/seguimiento-postoperatorio';
import { Documento } from 'src/app/moddelo/documento';
import { CasoClinico } from 'src/app/moddelo/caso-clinico';

interface IUploadFile{
  code: number;
  message: number;
  data: Documento;
}

@Component({
  selector: 'app-caso-clinico-documento-upload-adm',
  templateUrl: './caso-clinico-documento-upload-adm.component.html',
  styleUrls: ['./caso-clinico-documento-upload-adm.component.css'],
  providers: [
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'es-MX'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { 
      provide: MAT_DATE_FORMATS, 
      useValue: MAT_MOMENT_DATE_FORMATS
    }]
})
export class CasoClinicoDocumentoUploadAdmComponent implements OnInit {
  public labelText: string = "Seleccione un archivo";
  public fileUploadProgress: string = "0%";
  public progress: number = 0;
  
  public fileData: File;
  
  public tiposDocumentos: TipoDocumento[] = [];

  public documentoCasoClinicoFrm: FormGroup = new FormGroup({
                                      fk_tipoDocumento: new FormControl('', Validators.required),
                                      descDocumento: new FormControl('', Validators.required),  
                                      archivo: new FormControl('', Validators.required),
  });

  get fk_tipoDocumentoCtrl(){
    return this.documentoCasoClinicoFrm.get('fk_tipoDocumento');
  }
  get descDocumentoCtrl(){
    return this.documentoCasoClinicoFrm.get('descDocumento');
  }
  get archivoCtrl(){
    return this.documentoCasoClinicoFrm.get('archivo');
  }
  
  get documentos(){
    return this.casoclinico.documentos;
  }  

  @Input('casoclinico') casoclinico: CasoClinico;   
  @Input('seccion') seccion: number;
  @Input('noSeguimiento') noSeguimiento: number;
  @Input('seguimientos') seguimientos: SeguimientoPostoperatorio[];

  constructor(private catalogosSrv: CatalogosService,
              private casosClinicosSrv: CasosClinicosService,
              private matSnackBar: MatSnackBar) { }

  ngOnInit() {
    this.catalogosSrv
    .getTiposDocumentos()
    .subscribe(data => {
                          this.tiposDocumentos = data;
                        }, err => { console.log(err) });
  }
  onSubmit() {
   
      const formData = new FormData();         

      formData.append('fk_casoClinico', this.casoclinico.id.toString());
            
      formData.append('fk_tipoDocumento', this.fk_tipoDocumentoCtrl.value);

    //  formData.append('fechaDocumento', formatDate(this.fechaDocumentoCtrl.value, "yyyy-MM-dd", "es-MX"));    

      formData.append('descDocumento', this.descDocumentoCtrl.value);

      formData.append('seccion', this.seccion.toString());

      formData.append('noSeguimiento', this.noSeguimiento.toString());

      formData.append('file', this.fileData);

      this.casosClinicosSrv
      .setSubir(formData)
      .subscribe( events => {
                                  let r: IUploadFile;
                                  if(events.type === HttpEventType.UploadProgress){
  
                                    this.fileUploadProgress = Math.round( events.loaded / events.total * 100) + "%";
                                    this.progress = Math.round( events.loaded / events.total * 100);                                   
                                    
                                  } else if( events.type === HttpEventType.Response){
                                      r = <IUploadFile> events.body;
                                        
                                        if(r.data){                                    
                                              
                                              this.matSnackBar.open("Solicitud Procesada", "Documentos", {
                                                duration: 3000,
                                                verticalPosition:'bottom',
                                                horizontalPosition:'center'
                                              });

                                              let documento: Documento = <Documento> r.data;                                               
                                              this.documentos.push(documento);
                                              


                                        }else {
                                              this.matSnackBar.open("Verifique la informacion", "Documentos", {
                                                                                                                  duration: 3000,
                                                                                                                  verticalPosition:'bottom',
                                                                                                                  horizontalPosition:'center'
                                                                                                                });
                                        }
                                              
                                    
                                  } else if(events.type === HttpEventType.ResponseHeader){
                                      console.log( events.ok ); 
                                  }
                                }, err => { console.log(err); }); 
   
  }

  fileSelect(inputfile: any){
    this.fileData = <File> inputfile.target.files[0];
    this.labelText = this.fileData.name;

    this.fileUploadProgress ="0%";
    this.progress = 0;
  }

}
