import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import moment  from 'moment/moment';
import jsPDF from 'jspdf';

import { EnfermerasService } from 'src/app/servicios/enfermeras.service';

import { Producto } from 'src/app/moddelo/producto';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { RedimirEnfermera } from 'src/app/moddelo/redimir-enfermera';

import { img } from 'src/app/datos/content-img';

@Component({
  selector: 'app-administrador-enfermeras-informes',
  templateUrl: './administrador-enfermeras-informes.component.html',
  styleUrls: ['./administrador-enfermeras-informes.component.css']
})
export class AdministradorEnfermerasInformesComponent implements OnInit {
  public enfermeras: Enfermera[] = [];
  public enfermerasFilter: Enfermera[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  public filtroFrm: FormGroup = new FormGroup({
                                                fechaInicial: new FormControl(''),
                                                fechaFinal: new FormControl('')
                                              });
  get fechaInicialCtrl(){
    return this.filtroFrm.get('fechaInicial');
  }
  get fechaFinalCtrl(){
    return this.filtroFrm.get('fechaFinal');
  }  
  constructor(  
              private location: Location, 
              private enfermerasSrv: EnfermerasService
              ) { }

  ngOnInit() {

    this.enfermerasSrv
    .getEnfermeras()
    .subscribe(data => {
         this.enfermeras = data;
         this.enfermerasFilter = this.enfermeras.sort((a, b) => a.primerApellido.localeCompare(b.primerApellido));
         
    }, err => { console.log(err); });
  }

  filterIngreso(ingreso: Date): boolean{
    let condicion: boolean = true;   
    
    if(this.fechaInicialCtrl.value && this.fechaFinalCtrl.value){
      condicion = new Date(ingreso).getTime() >= new Date(this.fechaInicialCtrl.value).getTime() && new Date(ingreso).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    } else if(this.fechaInicialCtrl.value){
          condicion = new Date(ingreso).getTime() >= new Date(this.fechaInicialCtrl.value).getTime();
    } else if(this.fechaFinalCtrl.value){
          condicion = new Date(ingreso).getTime() <= new Date(this.fechaFinalCtrl.value).getTime();
    }
    return condicion;
  }

  puntos(acumulados: Producto[]){
    return acumulados.reduce((valor, acumulado)=> acumulado.e_puntos + valor, 0);
  }
 
  saldos(acumulados: Producto[], canjes: RedimirEnfermera[]){
    return acumulados.reduce((valor, acumulado) => acumulado.e_puntos + valor, 0) - canjes.filter(canje => canje.status == 3).reduce((valor, canje ) => canje.redimir + valor, 0);
  }

  onSubmit(){
    this.enfermerasFilter = this.enfermeras.filter(enfermera => this.filterIngreso(enfermera.created_at)).sort((a, b) => a.primerApellido.localeCompare(b.primerApellido));    
  }
  
  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  generateData() {
    let result = [];
    let i: number = 0;

    this.enfermerasFilter.forEach((m, i) => {
        
        result.push(Object.assign( {}, 
        { 
          No: (i+1).toString(), 
          Fecha: moment(m.created_at).format('L'), 
          Enfermera: m.primerApellido + ' ' + m.segundoApellido + ' ' +m.nombre,
          NoCasoClinico: m.casosclinicos.length.toString(),
          NoProducto: m.productos.length.toString()
        }));

    });

  return result;
  }

  createHeaders() {
   let keys: string[] = [
                          "No",
                          "Fecha",
                          "Enfermera",
                          "NoCasoClinico",
                          "NoProducto"
                        ]
    let result: any[]= []; 
    
    result = <any[]> keys.map(key => (<any> {
                                              id: key,
                                              name: key,
                                              prompt: key,
                                              width: "65",
                                              align: "center",
                                              padding: "0"
                                            }));

    
    return result;
  }

  imprimirPDF(): void {
    
    const doc = new jsPDF('landscape');  
    let image = `data:image/png;base64,${img}`;
    
    let PdfWidth = doc.internal.pageSize.width;
    let PdfHeight = doc.internal.pageSize.height;
    
    doc.addImage(image, 'PNG', 1, 1, 45, 45);
    doc.setFontSize(32);    
    doc.text('Enfermera', 145, 22, {align: "center"});

    let headers = this.createHeaders();
    let rows = this.generateData();
    doc.table(1, 50, rows, headers, { autoSize: true});
    
    /*
    let pages = doc.getNumberOfPages();
    */
    
    doc.save(`${new Date().toISOString()}_sdo.pdf`);
  }
  
  goBack(){
    this.location.back();
  }

}
