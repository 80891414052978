import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { Usuario } from 'src/app/moddelo/usuario';
import { Supervisor } from 'src/app/moddelo/supervisor';

@Component({
  selector: 'app-usuario-supervisor-create',
  templateUrl: './usuario-supervisor-create.component.html',
  styleUrls: ['./usuario-supervisor-create.component.css']
})
export class UsuarioSupervisorCreateComponent implements OnInit {
  public usuario: Usuario;
  public supervisor: Supervisor;
  public usuarioSupervisorFrm: FormGroup = new FormGroup({
                                                            usuario: new FormControl('', Validators.required),
                                                            password: new FormControl('', Validators.required),
                                                            alias: new FormControl('', Validators.required),
                                                            nombre: new FormControl('', Validators.required),
                                                            primerApellido: new FormControl('', Validators.required),
                                                            segundoApellido: new FormControl(''),
                                                            telefonoMovil: new FormControl('', Validators.required),
                                                            fk_rol: new FormControl(6)
                                                            });
  get usuarioCtrl(){ return this.usuarioSupervisorFrm.get('usuario');}
  get passwordCtrl(){ return this.usuarioSupervisorFrm.get('password');}
  get aliasCtrl(){ return this.usuarioSupervisorFrm.get('alias');}
  get nombreCtrl(){ return this.usuarioSupervisorFrm.get('nombre');}
  get primerApellidoCtrl(){ return this.usuarioSupervisorFrm.get('primerApellido');}
  get segundoApellidoCtrl(){ return this.usuarioSupervisorFrm.get('segundoApellido');}
  get telefonoCtrl(){ return this.usuarioSupervisorFrm.get('telefonoMovil');}
  get fk_rolCtrl(){ return this.usuarioSupervisorFrm.get('fk_rol');}

                                                       
  constructor(private location: Location, 
              private usuariosSrv: UsuariosService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
  }
  onSubmit(){
    let { usuario, password, alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_rol  } = this.usuarioSupervisorFrm.value;
    let usuarioN: Usuario = <Usuario> {usuario, password, alias, nombre, primerApellido, segundoApellido, telefonoMovil, fk_rol };
    let supervisorN: Supervisor = <Supervisor> { nombre, primerApellido, segundoApellido, telefonoMovil};
    supervisorN.correo = usuario;
    this.usuariosSrv.setUsuarioSupervisorNuevo(usuarioN, supervisorN).subscribe(data => { if(data){alert("Registrado Supervisor exitosamente!!!"); this.goBack(); } }, 
    err => { 
      if(err){
        alert(err.error.message);
      }
      console.log(err); 
    });
  }
  goBack(): void {
    this.location.back();
  }
}
