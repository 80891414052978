import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, PageEvent } from '@angular/material';
import { Location } from '@angular/common';

import { AuthService} from 'src/app/servicios/auth.service';
import { AdministradorService } from 'src/app/servicios/administrador.service';
import { Supervisor } from 'src/app/moddelo/supervisor';
import { Enfermera } from 'src/app/moddelo/enfermera';
import { DialogoSupervisorEnfermeraAdd } from '../../dialogos/dialogo-supervisor-enfermera-add/dialogo-supervisor-enfermera-add';
import { DialogoSupervisorEnfermeraRemove } from '../../dialogos/dialogo-supervisor-enfermera-remove/dialogo-supervisor-enfermera-remove';

@Component({
  selector: 'app-administrador-supervisor-r-enfermeras',
  templateUrl: './administrador-supervisor-r-enfermeras.component.html',
  styleUrls: ['./administrador-supervisor-r-enfermeras.component.css']
})
export class AdministradorSupervisorREnfermerasComponent implements OnInit {
  public enfermeras: Enfermera[] = [];

  public page_size: number = 10;
  public page_number: number = 1;
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  public filtroFrm: FormGroup = new FormGroup({
      nomEnfermera: new FormControl('')
  });

  @Input('supervisor') supervisor: Supervisor;

  get enfermeraCtrl(){
    return this.filtroFrm.get('nomEnfermera');
  }

  get usuario(){
    return this.aut.getUsuario;
  }

  get offLink(){
    return this.usuario.fk_nivel == 0 || this.usuario.fk_nivel == 3; 
  }

  constructor(
    private aut: AuthService, 
    private adminSrv: AdministradorService,
    private location: Location,
    private dialogo: MatDialog
  ) { }

  ngOnInit() {      
    this.enfermeras = this.supervisor.enfermeras;
  }
  onSubmit()
  {
    this.enfermeras = this.supervisor.enfermeras.filter(m => this.filterEnfermera(m.nombre.concat(m.primerApellido).concat(m.segundoApellido?m.segundoApellido:''))); 
    this.page_number = 1;
  }
  filterEnfermera(nomEnfermera: string): boolean{
    let condicion: boolean = true;
    let enfermera: string;
    let e: string;
    let nombre: string; 

    if(this.enfermeraCtrl.value != ""){
      e = this.enfermeraCtrl.value;      
      enfermera = e.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      nombre = nomEnfermera.toLowerCase().replace('í','i').replace('á','a').replace('é','e').replace('ó','o').replace('ú','u');
      condicion = nombre.replace(/ +/g,'').includes(enfermera.replace(/ +/g,''));
    }  
   
    return condicion;
  }

  handlePage( e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number  = e.pageIndex + 1;
  }
  
  goBack(): void {
    this.location.back();
  }
  addEnfermera(): void { 
    let dialogRef = this.dialogo.open( DialogoSupervisorEnfermeraAdd, { width: '800px', data: this.supervisor});
 
    dialogRef.afterClosed().subscribe(r => {
    this.enfermeras = this.supervisor.enfermeras;
     
    });  
    
   }
 
   removeEnfermera(enfermera: Enfermera): void {
 
    let dialogRef = this.dialogo.open(DialogoSupervisorEnfermeraRemove, {width: '800px', data: {supervisor: this.supervisor, enfermera: enfermera }});
    dialogRef.afterClosed().subscribe(r => {
      this.enfermeras = this.supervisor.enfermeras;  
    }); 
   }

}
