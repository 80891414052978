import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../../auth.guard';
import { CasosClinicosFichaVistaComponent } from './casos-clinicos-ficha-vista/casos-clinicos-ficha-vista.component';


const routes: Routes = [
  { path:'casoclinico/:id', component: CasosClinicosFichaVistaComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CasosClinicosRoutingModule { }
